import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;
  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 960 1080">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="">
<path fill="#FEFEFE" opacity="1.000000" stroke="none" 
	d="
M410.000000,1081.000000 
	C273.333344,1081.000000 137.166672,1081.000000 1.000000,1081.000000 
	C1.000000,721.000000 1.000000,361.000000 1.000000,1.000000 
	C321.000000,1.000000 641.000000,1.000000 961.000000,1.000000 
	C961.000000,361.000000 961.000000,721.000000 961.000000,1081.000000 
	C777.500000,1081.000000 594.000000,1081.000000 410.000000,1081.000000 
M190.960648,991.690369 
	C191.031372,992.094055 191.102097,992.497742 191.078583,993.723877 
	C191.721283,995.481934 192.363983,997.240051 193.123840,999.473694 
	C193.123840,999.473694 193.251114,999.946716 193.065552,1000.456299 
	C193.076309,1000.636475 193.087082,1000.816650 192.604797,1001.458557 
	C193.813477,1007.593811 194.829330,1013.774780 196.271225,1019.854736 
	C199.212143,1032.255615 199.872040,1030.984253 210.053955,1030.896729 
	C210.719055,1030.890991 211.568466,1031.155518 212.016586,1030.842529 
	C213.720795,1029.652100 216.220230,1028.576416 216.758896,1026.917725 
	C219.141891,1019.579407 220.955414,1012.055847 222.957367,1004.594543 
	C223.665863,1001.954041 224.323853,999.300049 225.249084,995.702820 
	C225.963501,997.498901 226.342148,998.233582 226.552811,999.013550 
	C229.085754,1008.389526 231.515213,1017.794739 234.194107,1027.128540 
	C234.589539,1028.506348 236.051590,1030.489868 237.162979,1030.588623 
	C241.438492,1030.969116 245.798538,1030.951294 250.066940,1030.505493 
	C251.258804,1030.381104 252.952194,1028.409058 253.205917,1027.057617 
	C254.590057,1019.684509 255.596695,1012.240601 256.867126,1004.810364 
	C256.867126,1004.810364 256.901978,1004.940125 257.604950,1004.892883 
	C258.936157,1003.463135 260.255127,1002.021790 261.601959,1000.606934 
	C263.081512,999.052734 264.547455,997.481812 266.097534,996.000000 
	C266.860962,995.270142 268.587585,994.585144 268.505676,994.123169 
	C268.301300,992.970764 267.547913,991.494324 266.579651,990.999451 
	C265.197662,990.293030 263.427155,990.346741 261.837738,989.555298 
	C261.836975,989.381714 261.836243,989.208130 262.340790,988.771240 
	C262.221130,988.179443 262.101471,987.587585 261.953888,986.215576 
	C261.843903,985.707520 261.733948,985.199463 261.702637,984.130554 
	C261.742126,983.755005 261.781616,983.379395 262.401001,982.750061 
	C262.653931,982.183716 262.906830,981.617310 263.871338,980.923279 
	C267.237274,979.584229 267.614227,976.751099 266.702972,973.943298 
	C265.828918,971.250061 263.820282,970.079956 260.545715,970.568665 
	C257.602142,971.007874 254.444656,969.888428 251.457230,970.194824 
	C248.546814,970.493347 245.732391,971.727966 242.259399,972.729309 
	C248.141647,977.732727 247.880127,978.222595 244.925201,985.405273 
	C244.128204,987.342468 246.093994,990.416260 246.857422,993.736023 
	C246.723511,994.747009 246.589600,995.758057 245.865097,997.077209 
	C245.012772,999.045593 244.160461,1001.014038 243.308151,1002.982422 
	C242.778320,1002.978333 242.248474,1002.974182 241.718643,1002.970093 
	C239.445129,994.639709 237.399048,986.235901 234.749039,978.027039 
	C234.024673,975.783142 231.812714,973.007874 229.722809,972.398132 
	C222.227112,970.211365 219.073593,972.292480 216.634979,979.767395 
	C216.430283,980.394897 215.974030,980.957031 215.850220,981.591553 
	C214.978378,986.059753 214.163101,990.539001 213.205048,995.766357 
	C212.832214,997.200562 212.459381,998.634827 211.385788,1000.121887 
	C210.575241,1000.987671 209.764694,1001.853394 208.988510,1002.682495 
	C207.588791,1000.366516 206.278671,998.198853 204.851730,996.075500 
	C204.851730,996.075500 204.740570,996.132507 204.975281,995.368164 
	C204.923615,994.579468 204.871964,993.790771 205.381882,992.552551 
	C206.263916,990.009949 206.399857,987.772034 202.983490,986.296143 
	C202.906219,985.867981 202.828949,985.439819 203.319321,984.688354 
	C202.896790,983.780884 202.474274,982.873474 201.930618,981.341125 
	C201.850052,980.924500 201.769485,980.507812 202.326950,979.676941 
	C203.372726,977.051331 203.438263,974.814575 199.818192,973.227356 
	C197.048523,971.770508 194.313080,970.708740 191.504944,974.023376 
	C191.504944,974.023376 191.070801,974.163818 190.338196,973.940369 
	C189.583389,974.004944 188.828583,974.069519 187.247009,974.070862 
	C185.661438,973.667603 184.028717,973.394348 182.498123,972.839478 
	C174.084137,969.789307 169.630112,972.208984 167.937271,980.878906 
	C166.892105,986.231628 165.968002,991.608582 164.874619,996.951111 
	C164.510468,998.730408 163.789124,1000.436707 162.857208,1003.341675 
	C161.644501,1001.311035 160.981903,1000.581665 160.737259,999.731934 
	C158.733093,992.771301 156.896515,985.761353 154.823776,978.821777 
	C153.541199,974.527588 151.241241,971.860168 145.944931,971.921753 
	C141.051086,971.978577 137.510330,972.674255 136.231995,978.008850 
	C134.571823,984.936829 132.774887,991.832886 130.960571,998.722595 
	C130.577744,1000.176331 129.865036,1001.543091 129.304352,1002.949951 
	C126.464935,995.828308 124.297600,988.955200 123.098495,981.917114 
	C121.422340,972.079102 120.951225,971.396790 111.059364,972.099670 
	C107.391937,972.360229 105.704178,973.540405 106.737656,977.819641 
	C110.154617,991.967896 113.202301,1006.205505 116.369019,1020.413940 
	C118.718048,1030.953369 118.712646,1030.660522 130.006561,1031.053467 
	C134.864853,1031.222412 136.672363,1028.737915 137.684937,1025.013672 
	C139.029831,1020.066833 139.867447,1014.983887 141.142242,1010.015625 
	C142.021820,1006.587585 143.272720,1003.254822 144.357651,999.879517 
	C144.866104,999.951782 145.374557,1000.023987 145.883011,1000.096252 
	C146.454941,1001.408691 147.218796,1002.672485 147.565903,1004.041931 
	C149.148590,1010.285950 150.343872,1016.643066 152.240723,1022.786011 
	C153.132309,1025.673218 154.739166,1029.652100 157.006439,1030.498169 
	C160.518692,1031.808960 164.906265,1031.151489 168.853882,1030.711670 
	C170.219208,1030.559448 172.159973,1028.765137 172.509415,1027.384766 
	C175.174759,1016.854858 177.441422,1006.225098 179.977432,995.661255 
	C181.411148,989.689087 183.119431,983.782715 184.916534,977.050720 
	C186.964279,980.427673 188.385162,982.770813 189.942139,985.824829 
	C190.034302,986.542664 190.126480,987.260437 189.641312,988.291870 
	C190.068481,989.219543 190.495667,990.147278 190.960648,991.690369 
M540.992737,952.368713 
	C540.668823,951.906860 540.344910,951.445007 540.085327,950.247986 
	C540.654663,948.554077 541.223511,946.812500 538.350647,947.025513 
	C534.120117,947.339050 529.884644,947.641785 525.677002,948.158264 
	C524.899902,948.253662 524.078613,949.226135 523.559204,949.986145 
	C519.922424,955.307922 520.146240,956.582336 525.429321,961.652893 
	C525.388000,962.069458 525.346680,962.485962 524.656250,963.261719 
	C524.449707,964.033752 524.046448,964.811462 524.068359,965.576965 
	C524.213745,970.657776 522.743225,971.558533 518.055420,969.201416 
	C517.797974,969.072021 517.418823,969.184753 517.026794,969.184753 
	C516.768677,970.024048 516.507202,970.874390 516.479370,970.965027 
	C513.617126,970.965027 511.296417,971.080017 508.990295,970.945068 
	C499.366669,970.382019 487.425293,979.035278 485.125549,988.457336 
	C484.380127,991.511414 483.560394,994.671936 483.626740,997.765442 
	C483.696747,1001.029419 485.921387,1004.548218 485.210266,1007.470337 
	C483.231812,1015.600708 489.381348,1020.869629 491.321014,1027.551025 
	C491.655701,1028.703857 494.213806,1029.297974 495.816650,1029.982178 
	C508.258209,1035.292358 521.400208,1027.112183 533.933044,1031.887573 
	C535.095581,1032.330566 537.065308,1031.755615 538.167664,1030.965698 
	C539.425415,1030.064453 540.131042,1028.392578 541.809082,1026.871094 
	C542.538574,1023.336487 543.214783,1019.789734 544.016663,1016.271606 
	C544.538879,1013.980591 545.243835,1011.731262 545.947144,1009.171448 
	C545.434143,1007.820374 544.961853,1005.727661 543.883667,1004.016907 
	C543.081604,1002.744446 541.494385,1001.966797 540.051147,1000.056091 
	C540.109680,985.045288 540.168152,970.034546 540.850830,955.010254 
	C541.427307,954.628845 542.003723,954.247375 542.580139,953.865906 
	C542.077271,953.561401 541.574402,953.256897 540.992737,952.368713 
M625.889038,452.395142 
	C624.946960,451.927246 624.004944,451.459320 622.641785,450.599030 
	C622.073425,450.098450 621.505066,449.597839 621.246582,448.476471 
	C622.486816,446.999969 623.726990,445.523468 625.746765,443.118805 
	C623.179260,442.694397 621.289001,441.755371 620.029297,442.293152 
	C616.488770,443.804718 614.251465,442.305603 612.315430,439.780304 
	C608.507019,434.812683 599.945251,435.134979 598.636230,427.429810 
	C593.628113,426.013580 588.619995,424.597351 583.288086,423.216522 
	C583.288086,423.216522 582.984985,423.097260 582.935608,422.379425 
	C579.153198,415.507416 571.838196,414.615479 565.471375,412.153595 
	C565.138489,412.024902 564.450562,412.814423 563.257202,413.142120 
	C562.507385,412.751862 561.757629,412.361633 560.888000,411.271088 
	C560.105164,410.917725 559.322266,410.564362 558.018372,410.273499 
	C557.675049,410.213043 557.331787,410.152588 556.899231,409.372162 
	C553.236084,406.907135 549.572876,404.442108 545.118896,401.444916 
	C543.749939,401.827454 541.338379,402.501312 538.240967,403.098022 
	C537.161316,402.722321 536.081726,402.346619 534.940308,401.387299 
	C534.290466,401.266724 533.640625,401.146149 532.182495,401.023621 
	C531.141846,400.804779 530.101196,400.585938 528.990479,399.745422 
	C524.623657,394.018372 518.244568,393.181702 511.794891,393.082550 
	C506.992310,393.008728 503.518005,392.128662 504.346680,386.462646 
	C501.894562,385.967316 499.812073,385.360992 497.693817,385.184540 
	C496.299835,385.068420 493.802338,385.226959 493.597351,385.852173 
	C491.881439,391.085510 488.118561,388.495850 484.948090,388.529083 
	C480.939087,388.571106 476.933594,388.946167 472.279480,389.189484 
	C471.850433,389.148071 471.421387,389.106659 470.900452,388.267548 
	C469.266449,387.512543 467.626770,386.102936 465.999420,386.117035 
	C459.336975,386.174713 452.676331,386.970673 446.018616,386.928314 
	C435.750458,386.863037 425.484528,386.353607 415.219208,385.988678 
	C411.828827,385.868164 408.273651,385.619415 406.542023,389.281464 
	C404.693695,393.190277 407.396667,395.928802 409.800171,398.447784 
	C410.865601,399.564392 412.444946,400.223877 413.854156,400.971863 
	C416.281281,402.260162 418.642120,403.893402 421.242493,404.580383 
	C424.608337,405.469543 426.439087,410.788727 431.632935,407.977509 
	C432.042023,408.029785 432.451141,408.082062 432.917938,408.605804 
	C432.917938,408.605804 432.970703,409.078735 432.998383,409.839661 
	C432.696350,414.926208 437.562073,413.689240 439.973053,415.314819 
	C445.991943,419.372925 452.374298,422.888428 458.551971,426.716583 
	C460.707306,428.052155 462.503723,430.158905 464.799255,431.027985 
	C467.701202,432.126556 470.945618,432.320587 474.623596,432.967072 
	C475.095154,433.275330 475.566711,433.583588 476.020142,434.649811 
	C476.484497,435.645599 476.677582,437.115448 477.456665,437.561310 
	C483.147736,440.818207 488.850708,444.092957 494.770599,446.895935 
	C497.955811,448.404083 501.576691,448.991974 504.706085,450.603394 
	C501.749481,452.627136 498.792847,454.650909 495.836182,456.674652 
	C494.520782,454.329407 493.208740,451.982269 491.880707,449.644196 
	C491.809265,449.518463 491.603363,449.469086 491.237274,449.252197 
	C486.667938,450.620819 485.936615,454.056427 485.940338,458.458893 
	C486.019043,552.251892 486.081970,646.045166 485.852844,739.837708 
	C485.833374,747.810852 483.722931,755.776794 482.622620,763.751404 
	C482.447754,765.018677 482.265320,766.346375 482.451996,767.591248 
	C483.224060,772.739807 484.136627,777.867249 484.336578,782.713379 
	C480.903320,779.988220 477.408478,777.336121 474.060913,774.509460 
	C472.180847,772.921875 470.185150,771.314575 471.594696,768.150146 
	C471.809784,767.667297 469.735504,765.618469 468.405792,765.099792 
	C464.935547,763.746277 461.235535,762.985962 457.750183,761.664551 
	C456.726044,761.276306 455.510986,759.817261 455.488556,758.823975 
	C455.467957,757.912476 456.763489,756.647095 457.781433,756.097351 
	C460.792328,754.471008 464.440704,750.708923 465.850922,757.674377 
	C465.869812,757.767517 466.147705,757.796143 466.291779,757.877686 
	C469.975281,759.962830 473.583191,762.207947 477.401672,764.005920 
	C478.161591,764.363770 480.810272,762.656311 480.759705,762.502563 
	C479.700378,759.281677 478.377777,756.147339 476.926056,752.340576 
	C476.962494,751.891602 476.998932,751.442688 477.471832,750.780945 
	C477.737183,750.576477 477.922760,750.315369 478.857666,749.998718 
	C483.353180,749.690430 485.551178,747.380371 483.527466,744.793030 
	C482.541168,743.531982 479.912903,743.555176 478.013245,742.122559 
	C478.021149,740.080872 478.029022,738.039246 478.897278,735.998169 
	C483.970337,736.674316 482.988892,733.026672 482.989349,730.183289 
	C483.002472,644.280396 482.993347,558.377502 483.033752,472.474609 
	C483.034912,470.016174 483.403625,467.534576 483.831299,465.104309 
	C484.914581,458.948792 484.225708,457.790985 477.743896,455.378448 
	C476.027374,453.384155 474.632935,450.911774 472.528107,449.493896 
	C467.982727,446.431885 463.010132,444.011993 458.345551,441.114807 
	C456.678741,440.079559 455.396515,438.425140 453.669281,436.799805 
	C452.616241,436.497925 451.025299,436.041840 449.434326,435.585785 
	C450.347595,433.229736 451.260864,430.873718 452.488800,427.705902 
	C448.292542,425.790436 443.612518,423.654144 438.225708,421.195251 
	C438.258240,429.461853 446.000061,424.965912 447.754364,429.396606 
	C445.081909,432.769836 442.233154,433.184448 439.037689,430.155609 
	C437.766815,428.951050 436.751923,427.286835 435.265015,426.533386 
	C429.541809,423.633270 423.598389,421.166595 417.884918,418.248840 
	C413.088898,415.799622 410.148926,417.268188 410.015411,422.629028 
	C409.932556,425.955597 410.000031,429.285889 410.000031,432.614532 
	C409.446320,432.683289 408.892639,432.752014 408.338928,432.820770 
	C407.653503,431.417419 407.104919,429.922485 406.244293,428.636383 
	C405.380920,427.346069 404.317352,426.111542 403.080505,425.211151 
	C402.813171,425.016479 400.691406,426.373932 400.709076,426.978882 
	C400.753113,428.485382 401.082245,430.618958 402.110901,431.340851 
	C405.076813,433.422363 405.033997,436.160919 405.032654,439.218933 
	C404.995148,525.875977 405.014618,612.533020 404.938049,699.190002 
	C404.935944,701.556641 403.876984,703.906311 403.498169,706.293640 
	C403.184662,708.269531 403.148102,710.289307 402.988831,712.289673 
	C404.822144,711.871338 406.655457,711.453003 408.877838,710.945862 
	C409.201202,712.427307 409.749146,714.317871 410.008850,716.247253 
	C410.705963,721.425537 415.102875,725.370972 418.609467,725.173889 
	C424.553284,724.840027 426.748169,729.904541 431.281372,731.313599 
	C433.699585,732.065186 434.952362,736.566589 436.882782,739.614990 
	C438.043335,739.172363 439.516388,738.259216 441.042511,738.159668 
	C441.964752,738.099487 443.586090,739.165344 443.765411,739.975037 
	C443.948486,740.801819 442.978455,742.503479 442.124115,742.843140 
	C434.818878,745.746826 429.044800,744.175415 423.434021,738.030151 
	C424.576172,736.707520 425.718292,735.384888 426.860443,734.062317 
	C425.247559,733.545349 424.294495,733.728149 423.389313,734.047058 
	C422.294189,734.432739 421.275299,735.072754 420.160797,735.358337 
	C416.899963,736.193909 416.448700,734.244507 418.800140,728.679993 
	C415.131256,728.937561 411.322174,730.134155 408.103790,729.229492 
	C402.167236,727.560669 407.157593,722.580078 406.011353,717.862854 
	C403.702179,720.684265 402.063782,722.686035 400.713745,724.335510 
	C398.816376,723.438232 397.094086,722.845947 395.640411,721.858398 
	C394.666962,721.197021 393.444122,719.995361 393.411194,718.999756 
	C393.133057,710.594299 393.001007,702.179321 393.096771,693.770325 
	C393.113434,692.310364 394.408478,690.864868 395.113007,689.412720 
	C395.742340,690.939941 396.777374,692.431396 396.915985,694.001953 
	C397.199402,697.213257 397.000977,700.467102 397.000977,704.765015 
	C398.330200,703.556580 399.678864,702.928772 399.846527,702.070190 
	C400.446869,698.996643 400.952087,695.845215 400.954132,692.722900 
	C401.009521,608.398926 400.999420,524.074951 400.987488,439.751007 
	C400.987244,437.977081 400.836487,436.203125 400.761200,434.544312 
	C393.729034,433.394409 392.822906,432.597382 393.090759,425.685699 
	C393.262268,421.258942 394.497650,416.884338 394.961243,412.455505 
	C395.753082,404.890289 396.094818,397.273407 397.042023,389.730499 
	C397.763550,383.984436 397.519012,378.886444 392.028778,375.446259 
	C391.632019,375.197601 391.434540,374.649536 391.098175,374.283112 
	C385.827240,368.541443 379.544312,364.620636 371.418671,363.304169 
	C371.148987,362.183960 370.812408,360.785950 370.759491,360.566193 
	C365.851074,358.421722 361.350861,357.297729 357.918671,354.740387 
	C353.739319,351.626373 348.361969,349.765106 345.811951,344.550873 
	C345.433899,343.777924 343.597168,343.576904 342.392181,343.391815 
	C341.309631,343.225525 339.369476,343.838867 339.170868,343.436096 
	C335.739868,336.477783 330.123322,336.365967 323.763855,338.178070 
	C322.431366,338.557800 321.036530,338.718872 319.670441,338.980682 
	C320.257355,340.314758 320.832123,341.654327 321.434021,342.981628 
	C322.339508,344.978424 323.263916,346.966614 324.180298,348.958466 
	C323.727875,349.102905 323.275452,349.247345 322.822998,349.391754 
	C322.113464,348.266571 321.403900,347.141388 320.694366,346.016174 
	C320.185944,346.101532 319.677490,346.186890 319.169067,346.272247 
	C319.334717,348.321899 318.990234,351.918854 319.749176,352.169067 
	C324.527618,353.744476 322.947571,357.475525 322.949768,360.436890 
	C323.014618,448.593719 322.956696,536.750671 323.047211,624.907410 
	C323.062378,639.696289 323.681824,654.484131 323.991882,669.273132 
	C324.095154,674.200195 327.187073,676.526733 331.349274,677.997803 
	C332.936829,678.558838 334.511536,679.156250 336.293732,679.811218 
	C335.129852,682.521790 330.011444,684.837036 335.148132,686.599976 
	C337.085999,684.803284 339.141968,682.072449 339.837860,682.383545 
	C345.003662,684.693237 349.899261,687.607361 354.778656,690.276367 
	C353.034454,692.045654 350.646240,693.346252 350.609009,694.710938 
	C350.484497,699.276550 347.967834,698.519409 345.796570,697.031860 
	C344.127167,695.888062 343.203766,693.655396 341.816864,691.723511 
	C341.074707,692.254822 339.960205,693.052734 338.891571,693.817810 
	C335.210205,690.268127 328.677032,690.685730 327.555939,684.472534 
	C327.516632,684.254761 326.634064,684.006531 326.189087,684.072571 
	C321.171387,684.817932 318.051880,682.241638 316.003265,677.811951 
	C309.329407,681.276123 305.872345,675.062134 301.030487,673.326660 
	C295.862579,671.474426 290.791077,668.892883 286.304657,665.737976 
	C282.365570,662.967957 276.196777,661.961609 276.037079,656.368591 
	C273.676453,655.563232 271.454926,655.222717 269.713318,654.122437 
	C266.322388,651.980347 263.501465,648.619690 259.881897,647.245178 
	C256.301392,645.885437 256.630280,645.030518 258.433350,642.518372 
	C260.862091,639.134521 260.616180,638.534546 257.215363,636.172485 
	C257.001770,637.611572 256.788208,639.050659 256.492584,641.042603 
	C253.710739,640.892395 250.603180,641.597412 248.619232,640.415833 
	C245.538071,638.580750 243.176941,635.503845 240.641815,632.828918 
	C240.345581,632.516418 240.645203,631.101990 241.092422,630.770874 
	C241.553375,630.429688 242.752777,630.504822 243.247803,630.889099 
	C245.688522,632.783691 247.927063,634.942383 250.398102,636.792664 
	C251.408951,637.549622 252.826004,637.764038 254.058273,638.225342 
	C253.711456,636.991394 253.747864,635.358704 252.951035,634.593079 
	C249.607361,631.380066 245.975327,628.468994 242.556351,625.330383 
	C242.068619,624.882629 242.170441,623.792725 242.000000,623.000000 
	C242.000000,623.000000 242.000610,623.000000 242.692429,623.068787 
	C245.373367,624.219543 248.229950,625.086731 250.682831,626.605774 
	C252.863770,627.956543 254.763550,629.880920 256.494385,631.814392 
	C260.376007,636.150452 267.196960,638.137268 267.479919,645.436951 
	C267.499023,645.929810 268.936493,646.924438 269.259918,646.764893 
	C273.863586,644.492920 276.191498,649.407043 279.875916,650.153381 
	C281.829712,650.549072 283.380463,652.934570 285.866608,655.068604 
	C289.930847,654.754700 294.097900,656.701050 297.135468,661.247437 
	C299.799042,665.234009 302.683655,669.072815 305.470673,672.976929 
	C305.965851,672.769409 306.461029,672.561890 306.956207,672.354370 
	C306.956207,668.695068 306.765228,665.019836 307.068359,661.385803 
	C307.163788,660.241699 308.584564,659.208069 309.398743,658.123901 
	C310.425018,659.079590 311.540131,659.957275 312.460114,661.006165 
	C314.820648,663.697388 317.094574,666.464600 319.403107,669.201416 
	C319.696808,668.936340 319.990479,668.671204 320.284180,668.406067 
	C320.284180,660.178650 320.284180,651.951233 320.284180,643.723816 
	C319.856934,643.731934 319.429688,643.740112 319.002441,643.748291 
	C319.002441,646.703796 319.123810,649.665710 318.968079,652.612976 
	C318.814209,655.524719 320.178680,659.109924 314.978882,661.051086 
	C314.331421,654.253967 313.722565,647.862244 313.073212,641.045593 
	C310.999084,641.803101 309.696564,642.278870 307.647095,643.027405 
	C307.647095,545.767334 307.647095,449.060913 307.647095,352.077423 
	C309.568909,352.236542 311.131500,352.365936 313.708557,352.579346 
	C313.397125,348.459839 313.391937,344.744324 312.671661,341.173096 
	C312.466125,340.154022 309.970245,339.765045 308.921082,338.697510 
	C307.996063,337.756317 307.112854,336.280975 307.090088,335.025543 
	C306.932983,326.364380 306.988342,317.698975 307.031097,309.035187 
	C307.035828,308.071655 307.196838,307.014160 307.615845,306.162964 
	C310.047089,301.224121 308.602356,293.975464 304.177582,291.165283 
	C296.051239,286.004181 287.779510,281.071411 279.547791,276.077484 
	C278.849609,275.653961 278.018219,275.376526 277.211945,275.216431 
	C270.809814,273.945374 266.114105,270.143280 261.820709,265.094055 
	C263.227234,263.749908 264.491943,262.541260 265.883179,261.211700 
	C264.087952,257.348083 261.218170,257.512360 257.959595,258.662262 
	C256.799988,259.071503 254.854782,259.882080 254.468506,259.446045 
	C250.874557,255.389328 246.134384,257.519745 242.031403,257.159912 
	C235.156830,256.557007 230.283081,265.093323 232.695221,271.632782 
	C234.422119,276.314545 235.854660,281.439178 235.865891,286.369720 
	C236.055588,369.843536 235.982880,453.317871 236.038788,536.792114 
	C236.043106,543.249146 236.631561,549.704346 236.792160,556.164307 
	C236.815369,557.097595 236.166031,558.262085 235.468765,558.961670 
	C232.423691,562.017029 229.368439,565.087341 226.058884,567.842224 
	C224.658310,569.008057 222.489059,569.206116 220.920029,570.228882 
	C219.817749,570.947388 218.587021,572.224060 218.388702,573.414001 
	C217.998734,575.753906 211.783066,584.505005 209.550079,584.872131 
	C208.624054,585.024292 207.272964,585.041443 206.703400,584.500549 
	C203.731339,581.678406 202.348358,583.879517 200.974503,586.130859 
	C199.954727,587.802002 199.059937,589.549438 198.109451,591.262878 
	C198.663834,591.310730 199.218216,591.358643 199.772598,591.406555 
	C199.843658,594.599487 199.914719,597.792419 199.211319,600.998657 
	C197.273499,602.431335 195.166672,603.703064 193.529510,605.422058 
	C193.168777,605.800781 194.474060,607.766113 194.987656,609.812988 
	C194.037354,618.165833 193.061951,626.516052 192.161591,634.874268 
	C192.012589,636.257385 192.191772,637.675842 192.219315,639.078003 
	C190.737793,638.932068 189.256287,638.786133 187.664902,638.629395 
	C184.952515,642.945740 181.988647,647.375732 179.389786,652.010437 
	C178.781479,653.095276 179.349106,654.878723 179.481049,656.331177 
	C179.752197,659.316345 180.240753,662.288086 180.380569,665.277527 
	C180.828339,674.849915 179.552231,684.868286 182.021759,693.869507 
	C184.141159,701.594482 187.174072,709.085144 188.101608,717.176025 
	C188.199249,718.027832 189.111816,719.530823 189.522644,719.489929 
	C195.149475,718.929626 193.879913,722.210388 193.150497,725.482056 
	C192.866440,726.756104 193.292587,728.188538 193.474060,730.593445 
	C196.355896,727.468201 198.616501,725.557373 201.455994,728.908752 
	C203.467453,731.282837 205.551880,733.642700 207.885223,735.687683 
	C215.168015,742.070557 222.614822,748.266052 229.927338,754.615601 
	C231.696854,756.152100 234.388290,757.545227 231.894104,760.677612 
	C231.202225,761.546570 231.093231,762.879578 230.716812,763.999695 
	C231.994843,763.746338 233.273087,763.284424 234.550842,763.285828 
	C236.780457,763.288147 239.255142,763.015442 241.187637,763.861572 
	C245.094208,765.572205 249.004517,767.567322 252.368515,770.153748 
	C257.141083,773.822998 264.929901,774.391479 265.648987,782.449585 
	C267.107544,782.379150 269.159576,781.650024 269.929474,782.343689 
	C275.430847,787.301086 284.396332,786.838745 288.329437,794.180725 
	C288.667114,794.811096 289.622955,795.552002 290.249298,795.515503 
	C294.788971,795.250854 298.311249,796.920227 301.403900,800.244141 
	C303.339905,802.324768 305.672211,804.283447 308.207733,805.507996 
	C313.410461,808.020752 319.006348,809.726868 324.179840,812.290771 
	C328.345520,814.355225 332.174774,817.123718 336.044708,819.740417 
	C336.912231,820.327026 337.768829,821.539307 337.868927,822.539673 
	C338.196075,825.810425 340.411865,825.590393 342.800079,826.127686 
	C345.692291,826.778381 348.928650,827.847778 350.989044,829.812805 
	C354.509674,833.170593 357.464172,836.947205 362.793579,837.478943 
	C364.043732,837.603638 365.274902,838.363464 366.422485,838.998047 
	C372.541138,842.381470 376.209839,849.137268 383.295258,851.266052 
	C384.009064,851.480530 384.357147,852.805237 384.949249,853.556213 
	C385.912415,854.777771 386.794128,856.118469 387.961731,857.113892 
	C391.736847,860.332458 395.661560,863.375671 399.431335,866.600159 
	C399.948608,867.042664 399.695465,868.310364 400.172180,868.888000 
	C403.279602,872.652710 406.159271,876.704041 409.772888,879.920837 
	C412.598389,882.436157 416.905457,891.521973 416.476868,895.373474 
	C416.177734,898.061218 413.538940,901.288452 417.875427,904.211731 
	C418.948792,900.984802 419.880768,898.183044 420.812744,895.381287 
	C421.340088,895.402222 421.867462,895.423157 422.394806,895.444092 
	C422.625977,898.404175 422.857178,901.364258 423.118805,904.714111 
	C427.932343,905.626038 429.145813,911.388245 428.524170,916.215454 
	C428.270203,918.187561 428.907654,920.324097 429.385712,922.327087 
	C429.881409,924.404114 430.815063,926.376770 431.310486,928.453857 
	C432.492767,933.410950 433.739105,938.369141 434.544922,943.393372 
	C435.009369,946.288940 435.055023,949.394409 434.466858,952.235168 
	C434.273438,953.169373 431.455780,953.484192 429.923218,954.252136 
	C429.243500,954.592590 428.566315,955.256104 428.243134,955.940613 
	C425.348938,962.070984 422.841797,968.411011 417.652740,973.158142 
	C417.062103,973.698486 417.183899,975.017639 416.253174,975.995544 
	C410.946045,976.378357 415.729614,972.907166 414.032532,971.065247 
	C412.940216,970.599792 411.097870,969.106812 409.328735,969.189026 
	C403.131378,969.477112 396.880096,969.894287 390.801331,971.057190 
	C385.426971,972.085327 382.305542,969.701721 383.162933,964.142090 
	C383.263031,963.492981 383.568481,962.877625 383.751953,962.238953 
	C385.762939,955.237793 382.432220,950.172180 377.608795,945.750549 
	C377.090576,945.275513 375.691437,945.313660 374.919769,945.636536 
	C370.421783,947.518799 365.989166,949.557007 361.316742,951.639587 
	C362.455109,952.174744 363.665955,952.743958 365.002716,953.372375 
	C365.002716,976.147888 365.003601,998.962524 364.998413,1021.777100 
	C364.998260,1022.441895 364.992859,1023.115967 364.889984,1023.769958 
	C364.176361,1028.306396 366.307892,1031.291016 370.822601,1031.944092 
	C373.435516,1032.321899 376.055359,1032.732056 378.685577,1032.869385 
	C379.696564,1032.922119 380.739502,1032.239990 381.777710,1031.925049 
	C383.103943,1031.523071 384.439545,1030.827026 385.772095,1030.824707 
	C393.005920,1030.812134 400.267548,1031.366577 407.459045,1030.862549 
	C409.961731,1030.687134 413.127075,1028.595459 414.557648,1026.432129 
	C421.356293,1016.151245 424.025055,1004.904236 421.451782,992.526855 
	C420.834106,989.555664 420.621521,985.314087 422.253113,983.309265 
	C425.604004,979.191956 430.286957,976.141052 434.499207,972.747559 
	C438.306671,969.679993 442.609741,967.126221 446.006683,963.670166 
	C454.949066,954.571838 462.887451,944.368103 472.531494,936.134033 
	C476.634979,932.630432 476.874939,926.508179 482.867737,924.395325 
	C485.374847,923.511414 486.346710,918.273010 488.700562,914.895386 
	C489.132385,913.929871 489.564209,912.964294 490.275055,911.370056 
	C492.184692,908.248291 494.094330,905.126587 496.724670,901.924133 
	C499.109131,901.032043 501.448578,900.119263 499.070618,896.305054 
	C500.047485,894.536682 501.024353,892.768372 502.675537,890.902588 
	C503.444183,889.598816 504.212799,888.294983 504.987885,886.172913 
	C505.656158,884.444580 506.324432,882.716187 507.714020,880.863342 
	C508.471405,878.573181 509.228790,876.283020 509.993500,873.093872 
	C510.338318,865.067139 510.683105,857.040405 511.501953,848.690125 
	C512.001160,848.125916 512.500366,847.561707 513.080017,847.737915 
	C515.717773,855.473267 515.672424,855.430298 520.688538,850.138367 
	C521.274719,849.520142 522.989990,849.225769 523.653015,849.635986 
	C524.320068,850.048584 524.781677,851.667725 524.523804,852.510376 
	C524.051941,854.051880 522.826355,855.345581 522.198669,856.860229 
	C521.060852,859.606018 520.501465,862.234314 524.981262,862.760010 
	C523.856262,865.195496 522.510559,867.557251 521.666077,870.086426 
	C520.760742,872.797974 519.555847,875.930237 524.002380,877.781799 
	C523.050903,880.541870 522.099487,883.302002 520.372681,886.132996 
	C519.986694,887.241638 519.600708,888.350220 519.243408,890.143433 
	C518.830017,891.428955 518.416626,892.714478 517.227173,894.006775 
	C511.779602,894.864319 513.091003,898.941223 513.241760,903.092224 
	C513.125610,903.722961 513.009460,904.353760 512.154663,905.005737 
	C510.877350,905.631226 509.440186,906.057617 508.358307,906.926453 
	C506.478455,908.436157 505.789185,913.732788 507.075287,915.684021 
	C507.003418,916.122070 506.931519,916.560181 506.254578,917.103394 
	C507.920166,919.485596 509.585785,921.867737 511.251373,924.249878 
	C511.712708,924.075989 512.174011,923.902161 512.635376,923.728333 
	C512.725159,921.748901 512.814941,919.769470 513.362366,917.314331 
	C514.220093,917.158813 515.077820,917.003235 516.422241,917.460571 
	C520.043579,917.827209 523.664856,918.193848 526.572021,918.488220 
	C527.134888,916.435425 527.574951,914.830566 528.637817,913.021179 
	C529.069153,912.958435 529.500488,912.895630 530.401184,913.448914 
	C536.846130,912.498352 543.278259,911.442810 549.742432,910.649109 
	C552.313843,910.333374 554.956421,910.597290 558.539490,910.597290 
	C556.636475,908.468201 555.487061,907.182190 554.187073,905.727783 
	C558.889832,902.575500 560.970947,907.576111 565.141174,909.237610 
	C564.465149,906.126404 563.978882,903.888367 563.679504,902.510620 
	C566.226929,901.125061 568.464600,899.822632 570.788635,898.698486 
	C571.445740,898.380615 572.385132,898.709534 573.143616,898.527527 
	C577.559692,897.468201 581.586792,895.877869 582.918762,890.851318 
	C582.918762,890.851318 582.829712,890.826599 583.583740,890.881287 
	C585.720520,889.250732 587.857300,887.620178 590.329590,885.486206 
	C591.091431,885.264648 591.853271,885.043030 593.568542,884.932983 
	C596.812195,885.119141 600.055908,885.305298 603.989746,885.531067 
	C603.639465,882.889893 603.306702,880.380554 603.449219,877.452148 
	C604.825806,876.569641 606.202454,875.687134 608.423645,874.921448 
	C609.814697,874.582764 611.439575,874.591858 612.554504,873.842407 
	C616.417603,871.245361 620.255432,868.559021 623.756836,865.509399 
	C624.713318,864.676270 624.456482,862.450195 624.962219,860.256958 
	C625.625305,859.800659 626.288452,859.344360 627.329285,859.421448 
	C630.291992,857.724854 633.254700,856.028259 637.103271,853.824402 
	C637.755981,855.146973 638.542480,856.740540 639.366943,858.411133 
	C640.426819,857.217346 640.771179,857.005920 640.818176,856.741638 
	C640.933167,856.094849 641.003296,855.413147 640.929260,854.763977 
	C640.166443,848.077759 640.161560,848.049744 646.526306,848.937378 
	C650.184082,849.447449 652.796509,848.031372 653.910461,844.585632 
	C654.869507,841.619202 655.444092,838.511475 655.985046,835.430481 
	C657.098022,829.091309 660.781067,824.890869 667.057007,821.787476 
	C666.981873,820.188293 666.881287,818.045410 666.885559,815.277344 
	C667.539917,814.495850 668.194275,813.714294 669.609497,812.917603 
	C670.750854,812.281433 671.838989,811.277405 673.047058,811.102783 
	C674.532532,810.888123 676.200500,811.125732 677.634460,811.618896 
	C678.807922,812.022461 680.098755,813.939575 680.729980,813.684143 
	C682.107727,813.126648 683.098816,811.613525 684.570801,810.175354 
	C675.594971,807.361816 676.007080,807.655762 681.155396,800.437744 
	C683.700439,796.869629 683.368164,791.640564 688.659912,789.313965 
	C689.963257,788.740845 690.697266,785.024719 693.685974,784.325867 
	C694.315186,784.178711 694.943115,782.393860 694.918518,781.379517 
	C694.833923,777.888550 695.171204,774.787781 698.584534,772.723083 
	C699.319763,772.278320 699.751831,770.728882 699.708679,769.714478 
	C699.579590,766.682678 699.145508,763.663940 698.778931,760.303223 
	C698.778931,760.303223 698.877686,759.977173 699.692627,759.981628 
	C704.991760,758.561523 703.262329,755.208496 701.946045,751.263428 
	C702.339661,750.179443 702.733276,749.095520 703.861389,747.903259 
	C706.419922,746.976807 708.978394,746.050354 711.858765,745.007324 
	C711.858765,737.036499 711.858765,728.874634 711.816711,720.347229 
	C711.816711,720.347229 711.901306,719.989136 712.645447,719.876892 
	C715.384155,713.901428 718.234680,707.972717 720.764832,701.910217 
	C721.256836,700.731445 720.423096,699.036682 720.336853,697.571899 
	C720.128113,694.025391 719.673218,690.452698 719.905823,686.929382 
	C720.406738,679.341675 721.599426,671.791626 721.910522,664.201660 
	C722.247192,655.989197 722.299744,647.718750 721.682068,639.533569 
	C721.386658,635.618591 719.256836,631.842041 717.857849,627.359436 
	C717.861145,626.925842 717.864441,626.492310 718.502136,625.518616 
	C718.735901,621.166443 719.573547,616.729919 718.981873,612.493042 
	C718.614319,609.861084 718.120117,608.014954 720.045654,605.786194 
	C721.052612,604.620789 720.946655,602.493835 721.343262,600.801086 
	C720.837097,600.659912 720.330933,600.518799 719.824768,600.377625 
	C719.221069,601.522034 718.617371,602.666443 717.437744,604.902588 
	C716.298706,602.660156 715.683777,601.337280 714.969360,600.070496 
	C714.004517,598.359619 712.963135,596.691833 711.870117,594.257874 
	C711.832397,593.007019 711.794739,591.756104 711.899963,589.564148 
	C711.642273,586.787231 710.753052,583.892517 711.244629,581.255310 
	C712.755249,573.151001 708.327576,566.803345 705.529846,560.001038 
	C703.599365,555.307129 697.656616,551.851501 700.958923,546.990601 
	C698.596069,544.521912 696.125549,542.911560 695.129517,540.651306 
	C693.918945,537.904358 693.951172,534.609558 693.355103,531.003906 
	C691.538757,531.003906 689.756287,531.003906 687.816162,530.509216 
	C687.802368,530.165161 687.788513,529.821167 687.917847,528.575989 
	C688.125549,525.505676 689.694519,520.971252 688.274902,519.651367 
	C684.638123,516.270081 686.135681,512.160339 684.810120,508.651093 
	C683.622681,505.507294 681.389893,502.282410 678.719421,500.310333 
	C676.114868,498.386871 672.371704,498.005096 670.192017,497.293671 
	C669.673645,493.041534 669.307861,490.041077 668.884521,486.568237 
	C663.578796,489.432312 663.780884,489.259674 660.561462,485.490631 
	C659.205200,483.902832 656.793457,483.216583 654.981079,482.001312 
	C654.981079,482.001312 654.880737,482.136902 654.896912,481.384430 
	C654.780701,479.958527 654.074951,478.201477 654.655945,477.167236 
	C656.601257,473.704285 655.894043,473.141602 652.135437,473.713379 
	C649.169556,474.164581 647.615540,471.049805 649.146851,468.379700 
	C649.659363,467.486084 650.198853,466.607910 651.243469,464.853638 
	C647.088318,465.745239 643.943909,466.419952 640.311646,466.844147 
	C639.843201,466.294861 639.374756,465.745544 638.964294,464.423981 
	C638.457825,460.228271 635.184814,459.145935 631.993896,458.016266 
	C631.993896,458.016266 631.884033,458.136200 631.904053,457.406921 
	C629.908569,455.962341 627.913086,454.517761 626.012207,453.044952 
	C626.012207,453.044952 625.929565,453.099731 625.889038,452.395142 
M464.159271,988.434814 
	C465.512726,991.623169 467.517395,994.776428 471.281860,993.762024 
	C474.320679,992.943176 477.894165,991.402771 478.327942,986.692871 
	C478.545013,986.452271 478.773010,986.222473 479.793335,985.841309 
	C482.374084,980.313416 481.162842,977.524597 476.727692,974.412537 
	C472.908325,971.732605 469.309052,970.584290 464.637787,970.932739 
	C459.638733,971.305664 454.006500,969.053284 449.628296,970.641602 
	C441.029114,973.761353 433.261353,979.180054 429.916229,988.346802 
	C428.636047,991.854858 427.505676,996.135071 430.053894,1000.830872 
	C430.046722,1002.216125 430.039520,1003.601440 429.449432,1005.488770 
	C428.768066,1007.810059 427.203705,1010.306274 427.620697,1012.409851 
	C428.188263,1015.273193 425.238831,1020.700317 432.002808,1020.451355 
	C432.640076,1020.427856 433.433197,1021.682800 433.973938,1022.468140 
	C437.668304,1027.833252 442.868683,1030.021606 449.219727,1031.131470 
	C459.837036,1032.986816 469.370300,1031.558350 478.090393,1024.979248 
	C482.416107,1021.715637 483.052826,1019.477356 479.806183,1015.326294 
	C479.539276,1014.542542 479.272369,1013.758789 479.014557,1012.182800 
	C478.937317,1011.528320 479.097839,1010.678223 478.745483,1010.250244 
	C475.778900,1006.646484 469.516418,1006.663513 466.208832,1010.174377 
	C465.651703,1010.765686 464.953583,1011.650879 464.307526,1011.662292 
	C460.254639,1011.734253 459.776703,1014.818665 458.200928,1017.928040 
	C457.146332,1017.837280 456.091705,1017.746460 454.912384,1017.020569 
	C453.312958,1015.399292 451.541809,1013.909973 450.164368,1012.118042 
	C448.893311,1010.464417 447.355499,1008.560547 447.147003,1006.635681 
	C446.639648,1001.952515 446.835724,997.193115 446.673492,991.960205 
	C446.777588,991.640137 446.881683,991.320007 447.713776,990.936707 
	C451.460510,989.783264 455.167053,988.461365 458.972473,987.554077 
	C460.639526,987.156616 462.508392,987.605408 464.217316,987.903992 
	C464.217316,987.903992 464.333984,987.689819 464.159271,988.434814 
M726.879700,1027.852051 
	C726.879700,1027.852051 726.851135,1027.828369 727.564453,1027.873657 
	C730.419189,1026.462402 733.273865,1025.051270 736.086060,1023.661011 
	C731.813232,1022.963074 731.575378,1019.395752 732.152771,1015.621155 
	C732.282593,1014.772156 733.512512,1014.091492 734.236023,1013.333374 
	C734.659058,1014.384155 735.270386,1015.408020 735.425781,1016.497009 
	C735.503540,1017.042480 734.749695,1017.706604 734.332703,1018.380615 
	C739.693054,1021.631836 746.064575,1023.127441 747.867432,1029.992310 
	C748.005310,1030.517334 749.568176,1030.760010 750.505493,1030.966919 
	C754.146667,1031.770508 757.786133,1032.606689 761.462036,1033.214355 
	C762.789062,1033.433716 764.215088,1032.980957 765.598938,1032.943726 
	C768.797791,1032.857300 772.340149,1031.947754 775.113159,1033.012695 
	C778.687378,1034.385254 780.354492,1033.203979 782.135864,1030.678345 
	C784.110291,1027.878784 786.483215,1025.296875 788.044983,1022.289124 
	C789.401367,1019.676880 790.790405,1016.503967 790.496521,1013.757324 
	C790.121216,1010.249451 783.479126,1006.535889 779.000183,1007.387817 
	C775.429321,1008.066895 771.048889,1009.171814 771.777283,1016.159241 
	C768.103516,1014.369873 765.642822,1012.683228 762.926086,1011.964600 
	C759.026489,1010.933167 755.588806,1010.052979 755.975586,1004.909607 
	C756.359375,999.805420 753.970276,993.554138 761.794006,991.765259 
	C762.259827,991.658813 762.706970,991.471191 763.136414,991.330017 
	C763.986511,992.560669 764.786011,993.718140 766.213196,995.784302 
	C767.887268,993.275940 769.150208,991.383667 770.480530,989.390381 
	C771.244263,989.631958 772.801697,989.632629 773.375732,990.384338 
	C777.910034,996.321594 782.004578,993.552307 786.384216,990.151001 
	C791.021851,986.549194 791.291809,982.176758 787.212952,977.975891 
	C785.306702,976.012634 784.045959,973.422607 782.194153,970.673401 
	C780.761047,970.673401 777.871094,971.451782 775.716675,970.523438 
	C769.906860,968.019836 764.283630,968.746155 758.476379,969.997314 
	C755.551147,970.627502 752.485168,971.501770 749.591064,971.206116 
	C741.769287,970.407043 739.180481,973.008911 741.249817,980.295044 
	C741.409485,980.857483 741.507080,981.661438 741.228882,982.091064 
	C736.583130,989.266479 736.370178,997.199646 736.927917,1005.352051 
	C737.099304,1007.857117 736.041504,1010.312561 732.839783,1009.348877 
	C729.063477,1008.212280 726.546448,1010.044434 723.102356,1012.105713 
	C721.068237,1012.069092 719.034119,1012.032471 716.663208,1011.533997 
	C716.107483,1012.019165 715.551758,1012.504333 714.671021,1013.487915 
	C713.834229,1014.014771 712.997437,1014.541565 711.318359,1015.036560 
	C710.664917,1015.143860 710.000244,1015.205750 709.359863,1015.365356 
	C701.972595,1017.206848 700.131165,1015.688293 699.965942,1008.117432 
	C699.760925,998.721741 699.403687,989.327393 698.957581,979.939819 
	C698.772095,976.037109 697.190002,973.223816 692.998352,971.757507 
	C687.436218,969.811646 681.668579,969.127197 676.015076,969.234924 
	C671.786621,969.315613 667.593262,971.492065 663.393372,972.765381 
	C652.415466,976.093445 647.460327,983.210815 657.590576,993.766907 
	C660.268311,996.557190 658.755371,998.277954 656.154907,998.686401 
	C651.784546,999.372681 650.978455,1001.795349 650.907410,1005.640503 
	C650.838684,1009.361755 650.021240,1013.089905 646.123840,1014.842957 
	C644.597656,1012.794983 643.346741,1011.116333 641.827820,1009.078186 
	C640.046387,1011.624634 638.584717,1013.714050 636.093323,1017.275269 
	C634.780396,1013.439270 633.312134,1010.876770 633.117737,1008.221008 
	C632.717407,1002.752563 633.209229,997.223267 632.924744,991.740295 
	C632.730225,987.992004 633.975098,986.707825 637.730957,986.908386 
	C640.421021,987.052124 643.891541,987.232788 645.730103,985.776367 
	C650.274048,982.176697 650.162476,974.383850 646.286987,969.433533 
	C639.525513,973.086975 637.567078,972.855835 633.418579,967.301575 
	C632.389709,965.924011 632.388611,963.818359 631.518555,962.270264 
	C630.812439,961.013794 629.415710,959.096069 628.491394,959.185608 
	C623.353394,959.683533 618.266113,960.704651 612.634705,961.633606 
	C614.708008,967.074707 613.790344,969.649170 608.596069,969.048218 
	C607.990112,968.978088 607.205566,969.152344 606.723389,969.509644 
	C601.339966,973.498108 602.578308,984.723572 608.536926,988.114136 
	C610.022034,988.959167 611.610107,990.810181 611.874451,992.409729 
	C613.074768,999.674316 614.268616,1006.974243 612.215637,1014.370544 
	C611.801697,1015.862061 612.584290,1017.864990 613.263184,1019.438904 
	C614.311890,1021.870361 615.689514,1024.169189 617.043884,1026.455322 
	C617.643188,1027.466797 618.339905,1028.676758 619.310913,1029.171509 
	C627.800720,1033.498291 636.568787,1032.880493 645.358032,1030.233887 
	C646.408508,1029.917603 647.438965,1029.101685 648.175354,1028.253906 
	C650.683594,1025.365845 652.299255,1026.104614 655.049622,1028.524292 
	C657.730469,1030.882690 661.597290,1032.291260 665.173462,1033.102539 
	C667.841980,1033.708008 670.910706,1032.295410 673.738403,1032.531860 
	C676.129089,1032.731812 677.889221,1033.423462 679.660339,1030.754395 
	C680.465759,1029.540771 683.455872,1029.776855 685.455017,1029.355347 
	C685.540649,1030.780029 685.471375,1032.236328 685.754333,1033.620728 
	C686.051819,1035.076172 686.686340,1036.462646 687.173584,1037.879272 
	C687.627258,1037.674072 688.080994,1037.468872 688.534668,1037.263672 
	C689.486511,1031.234741 689.483582,1031.780640 695.803284,1031.814209 
	C703.433167,1031.854858 711.068665,1030.852417 718.700378,1030.283813 
	C718.813843,1030.275391 718.910400,1030.039429 719.744324,1029.869141 
	C720.475037,1029.855835 721.205750,1029.842407 722.317444,1030.388916 
	C723.845032,1029.548950 725.372620,1028.709106 726.879700,1027.852051 
M798.325745,984.073425 
	C792.058289,989.103088 791.858154,989.901306 795.122375,997.924988 
	C795.106750,1000.611816 795.091125,1003.298706 794.380981,1006.083679 
	C793.384521,1007.317383 792.388062,1008.551086 791.214172,1010.004517 
	C795.726624,1016.129700 799.961365,1022.345215 804.744263,1028.105591 
	C806.323730,1030.007812 809.216003,1031.504761 811.687439,1031.831909 
	C816.178467,1032.426514 820.854675,1031.573486 825.362244,1032.102661 
	C833.987305,1033.115112 840.568970,1030.285767 845.346802,1023.070251 
	C846.064575,1021.986145 846.933716,1020.968384 847.879639,1020.075500 
	C850.886292,1017.237488 852.005798,1009.891602 849.909058,1005.083984 
	C849.936768,1002.389282 849.964478,999.694641 850.720825,996.991455 
	C851.421631,996.367554 852.122437,995.743652 852.823303,995.119812 
	C851.582336,994.075439 850.341431,993.031006 848.957947,991.348816 
	C848.925842,990.914856 848.893677,990.480957 849.563904,989.693726 
	C852.448303,984.470764 850.015503,980.845215 842.889648,979.666199 
	C842.889648,979.666199 842.857971,979.305054 842.905151,978.549194 
	C839.300171,972.189514 839.300171,972.189514 841.464355,967.711609 
	C838.980774,968.161072 836.938965,968.690247 834.866455,968.874512 
	C829.493591,969.352417 823.872009,970.840698 818.777832,969.809998 
	C809.689392,967.971252 799.584961,974.579895 799.005127,983.995789 
	C799.005127,983.995789 799.042114,984.025635 798.325745,984.073425 
M278.882111,1011.290039 
	C276.154083,1010.591919 273.426086,1009.893860 270.254730,1009.082336 
	C270.360626,1010.887207 270.440338,1012.245361 270.495270,1013.181396 
	C268.669556,1014.324707 267.250092,1015.213562 266.509003,1015.677612 
	C267.645233,1018.362122 268.282440,1020.184937 269.163849,1021.880859 
	C270.757629,1024.947510 271.848846,1029.749268 274.296021,1030.625610 
	C282.012573,1033.388550 288.183350,1030.497559 291.008026,1022.507996 
	C292.085083,1019.461487 289.574738,1014.840759 287.848175,1011.359680 
	C287.490204,1010.637878 283.362915,1011.785706 280.314331,1012.145874 
	C279.876617,1012.098083 279.438934,1012.050293 278.882111,1011.290039 
M862.104309,995.930115 
	C862.049744,1005.618591 861.995178,1015.307129 861.244507,1025.120850 
	C859.690186,1028.581421 861.983704,1032.557129 865.390381,1032.348389 
	C867.870361,1032.196167 870.488586,1032.557007 872.797546,1031.864014 
	C874.951172,1031.217773 878.087769,1029.797852 878.481873,1028.162109 
	C879.363220,1024.504028 878.855530,1020.498901 878.813171,1016.636292 
	C878.793823,1014.869934 878.287842,1013.085022 878.435242,1011.345215 
	C878.857483,1006.362000 879.243103,1001.349548 880.157471,996.444458 
	C880.680054,993.640869 879.928894,989.594666 883.860840,988.720703 
	C886.486938,988.136963 889.790955,987.979858 891.066711,992.246948 
	C886.046753,992.952637 886.816223,996.458862 887.136719,999.737793 
	C887.746582,1005.976990 888.225708,1012.234863 889.067078,1018.443237 
	C889.489380,1021.559204 889.734680,1025.319214 891.588928,1027.469849 
	C894.114441,1030.399292 897.081116,1034.367310 902.419373,1032.155518 
	C903.222595,1031.822754 904.837952,1033.311157 906.013245,1034.058472 
	C906.986633,1034.677246 907.865234,1035.445190 909.066101,1036.360718 
	C910.053284,1032.274414 911.439758,1028.982666 911.529358,1025.655884 
	C911.716492,1018.708618 911.729797,1011.678040 910.857056,1004.803223 
	C910.003723,998.080505 913.584290,993.469666 916.400574,988.333557 
	C918.466248,989.271423 920.288879,989.990112 922.008911,990.902222 
	C925.726379,992.873718 928.461548,996.285461 926.736450,1000.126282 
	C923.526611,1007.272827 925.253723,1014.510193 925.270630,1021.629944 
	C925.278625,1024.973267 927.289917,1030.093262 929.813660,1031.216064 
	C933.279724,1032.758301 938.544983,1032.350708 942.221069,1030.817261 
	C947.251648,1028.718750 947.365723,1014.209229 942.860352,1009.071411 
	C942.873169,1003.394592 942.885986,997.717773 943.523682,991.496338 
	C945.405090,984.747375 942.901978,979.061707 938.849854,973.987549 
	C937.689453,972.534546 935.426575,971.185913 933.641785,971.149963 
	C927.794556,971.032104 921.854614,972.227783 916.109009,971.557617 
	C912.502869,971.136902 909.695984,970.419678 907.687622,974.299988 
	C905.607849,972.875854 903.922485,971.560669 902.080505,970.523376 
	C901.042175,969.938660 899.747314,969.544861 898.565613,969.538940 
	C896.609741,969.529236 894.652222,969.904175 892.695007,970.113953 
	C888.401184,970.574402 883.627014,966.317383 880.916138,970.405518 
	C872.896118,968.160217 865.772766,970.348816 858.979431,969.920898 
	C859.375427,973.095459 859.873779,975.602600 859.961182,978.124023 
	C860.110901,982.442993 859.853333,986.779114 860.093384,991.089844 
	C860.167725,992.424744 861.301758,993.700623 862.104309,995.930115 
M344.500000,873.135559 
	C328.674713,873.123535 312.848633,873.023010 297.024719,873.163696 
	C293.227844,873.197510 291.399658,872.166504 291.764709,868.082825 
	C292.015656,865.276001 291.847137,862.422058 291.735596,859.594238 
	C291.398315,851.044800 287.833801,843.918518 279.633057,841.270813 
	C273.512299,839.294678 266.624207,839.213013 260.071777,839.168091 
	C249.921265,839.098450 241.218552,847.828064 241.097427,857.965759 
	C240.910385,873.620850 240.921753,889.282654 241.154968,904.937012 
	C241.308319,915.231812 249.525406,923.340332 259.866852,923.892700 
	C263.340302,924.078186 266.828949,924.007507 270.310638,924.007751 
	C312.788940,924.011230 355.267273,924.005981 397.745575,924.003113 
	C399.411316,924.002991 401.252838,924.444885 402.689789,923.871765 
	C403.946289,923.370544 405.428375,921.813538 405.583771,920.569641 
	C405.794128,918.885620 404.938660,917.019348 404.357117,915.293030 
	C399.077881,899.622131 389.073944,887.080078 377.628571,875.581604 
	C376.197601,874.143982 373.581543,873.279297 371.483490,873.218323 
	C362.828827,872.966553 354.162231,873.126953 344.500000,873.135559 
M98.975494,973.401245 
	C90.573738,973.221191 90.498314,973.204468 88.647827,981.545227 
	C87.352303,987.384521 86.383675,993.296204 85.100700,999.138611 
	C84.435471,1002.168030 83.404510,1005.117065 82.538795,1008.102417 
	C78.513535,997.810242 75.413757,987.834595 72.568695,977.786743 
	C71.649277,974.539673 70.073288,973.263306 66.615158,973.263062 
	C58.891079,973.262512 58.836899,973.065125 56.721668,980.509338 
	C54.052544,989.902954 51.482990,999.324951 48.511173,1010.028870 
	C47.630722,1007.600647 47.066662,1006.454468 46.799908,1005.242859 
	C45.050888,997.298828 43.300900,989.353882 41.678371,981.383423 
	C40.001183,973.144409 40.060421,973.399292 31.678953,973.279358 
	C26.748108,973.208801 26.208426,974.922791 27.287514,979.239624 
	C30.586746,992.437927 33.573982,1005.715576 36.572758,1018.987183 
	C39.098957,1030.167358 39.149193,1030.272949 50.612324,1029.697632 
	C50.778549,1029.689209 50.999901,1029.761108 51.103638,1029.679077 
	C52.629395,1028.473755 54.976379,1027.531860 55.502453,1025.977173 
	C57.361633,1020.482666 58.578754,1014.772217 60.072941,1009.152588 
	C61.404285,1004.145386 62.780197,999.150146 64.136429,994.149597 
	C65.996460,995.939575 66.694954,997.718628 67.208405,999.549500 
	C69.138000,1006.430542 70.867188,1013.370789 72.931854,1020.210266 
	C76.027039,1030.463501 76.136810,1030.590820 86.969116,1029.448975 
	C88.688705,1029.267700 91.043365,1027.264160 91.617889,1025.592407 
	C93.119965,1021.221924 93.756386,1016.558472 94.813889,1012.029602 
	C97.419228,1000.871765 100.070602,989.724670 102.695404,978.571411 
	C103.305923,975.977295 103.722504,973.467285 98.975494,973.401245 
M549.023438,1012.933044 
	C548.257629,1016.143738 547.491821,1019.354431 546.686829,1022.729431 
	C548.350891,1022.230408 549.862732,1021.777039 551.578308,1021.262573 
	C552.776550,1027.454956 556.388000,1031.685181 562.093140,1032.606445 
	C567.086121,1033.412598 572.372253,1032.589111 577.510437,1032.225708 
	C579.805542,1032.063232 582.055603,1031.263916 584.326111,1030.753662 
	C587.524597,1030.727661 590.872253,1032.041382 594.338745,1032.558594 
	C596.801331,1032.926147 600.210999,1033.143433 601.802856,1031.788574 
	C604.632629,1029.379761 608.125244,1025.751343 608.168091,1022.590149 
	C608.217773,1018.925964 605.531372,1014.516418 602.723206,1011.768921 
	C600.828613,1009.915222 600.035278,1008.604492 599.959045,1006.199158 
	C599.717224,998.571899 599.578979,990.923584 598.824341,983.340759 
	C598.583008,980.915100 597.024719,978.154846 595.212830,976.466614 
	C592.837585,974.253540 589.236267,973.360474 586.847900,971.156616 
	C583.591980,968.152100 580.118896,968.884766 576.561096,969.410400 
	C574.003418,969.788269 571.511230,970.593872 568.976990,971.150391 
	C566.248047,971.749756 563.416138,972.024170 560.794189,972.922302 
	C558.642822,973.659180 556.857605,975.561829 554.693604,976.146484 
	C548.273132,977.880981 546.777527,982.864014 551.417847,987.907837 
	C554.054993,990.774170 556.700989,993.632324 559.780029,996.967529 
	C557.477173,996.967529 555.671509,996.917175 553.869934,996.980713 
	C551.733337,997.056030 549.600281,997.229675 547.465698,997.360779 
	C548.451355,999.276672 549.444336,1001.188965 550.414673,1003.112549 
	C550.558228,1003.397095 550.594604,1003.735535 550.680603,1004.049072 
	C550.365356,1004.053284 550.050171,1004.057495 549.734985,1004.061646 
	C549.525696,1006.759583 549.316406,1009.457458 549.023438,1012.933044 
M299.992676,1001.374268 
	C300.134918,1006.616516 300.769409,1012.079590 294.420837,1013.417664 
	C295.781708,1018.268127 296.547333,1022.508423 298.227448,1026.348633 
	C299.068451,1028.270874 301.460876,1029.638550 303.379547,1030.914307 
	C304.384338,1031.582275 306.345947,1031.072998 307.074219,1031.848145 
	C309.879181,1034.833740 312.253937,1033.872681 314.839722,1031.678345 
	C317.040375,1029.810791 318.889130,1028.820312 321.454742,1031.916870 
	C322.856232,1033.608154 326.260803,1034.783081 328.486633,1034.434082 
	C335.206726,1033.380615 341.792114,1031.467651 347.378815,1030.139648 
	C348.909912,1027.459595 350.109863,1025.232544 351.438629,1023.085327 
	C352.127899,1021.971436 353.405609,1021.120300 353.802765,1019.943115 
	C357.089935,1010.199341 358.048096,1000.274231 356.229523,990.058655 
	C354.787048,981.955383 351.303864,975.206299 343.075989,972.894836 
	C335.499847,970.766541 327.622223,965.776245 318.840729,973.700500 
	C318.840729,968.211853 319.019379,964.042847 318.776733,959.898499 
	C318.620087,957.223022 318.695984,953.823914 317.155579,952.100769 
	C314.659180,949.308228 311.000519,947.486694 307.653229,945.567993 
	C306.762726,945.057495 304.911987,945.292786 304.085815,945.935730 
	C303.560944,946.344177 304.112701,948.136108 304.185059,949.364746 
	C298.995026,951.389160 297.774353,953.972168 299.265106,959.554016 
	C299.728241,961.288208 299.952698,963.136108 299.961792,964.933594 
	C300.021606,976.757385 299.992676,988.581543 299.992676,1001.374268 
M496.968628,915.623779 
	C497.307495,917.888123 498.444427,919.002380 499.788116,916.534485 
	C500.599548,915.044128 500.904907,912.012573 500.018951,911.176147 
	C498.843628,910.066528 496.283691,910.423401 494.318695,910.150146 
	C494.217743,910.629639 494.116821,911.109070 494.015869,911.588562 
	C494.948120,912.685303 495.880402,913.782043 496.968628,915.623779 
M848.895447,972.278870 
	C847.137695,974.812744 846.206848,977.180786 850.235901,978.796204 
	C852.111267,976.224670 855.099243,973.791016 848.895447,972.278870 
M884.367432,1023.342712 
	C884.596313,1019.472229 884.825195,1015.601807 885.054077,1011.731323 
	C884.357483,1011.722656 883.660889,1011.714050 882.964355,1011.705383 
	C883.170044,1015.577698 883.375732,1019.450073 884.367432,1023.342712 
M477.404907,443.099548 
	C475.834381,442.392517 474.263855,441.685516 472.693329,440.978485 
	C472.313568,441.515900 471.933807,442.053314 471.554077,442.590729 
	C472.879944,443.946136 474.205841,445.301544 475.531708,446.656921 
	C476.273895,445.683167 477.016052,444.709412 477.404907,443.099548 
M526.534485,391.704163 
	C525.855652,390.179260 525.176819,388.654358 524.498047,387.129456 
	C523.713196,387.849274 522.928345,388.569122 522.143494,389.288910 
	C523.448303,390.279968 524.753113,391.271027 526.534485,391.704163 
M287.401581,659.046936 
	C285.924713,658.285706 284.447845,657.524475 282.970978,656.763184 
	C285.008636,662.031555 286.111694,662.421509 287.401581,659.046936 
M485.434265,446.169678 
	C484.088287,446.282501 482.742279,446.395325 481.396301,446.508118 
	C481.709534,447.286316 482.022797,448.064514 482.336029,448.842712 
	C483.479858,448.177124 484.623718,447.511505 485.434265,446.169678 
M517.853271,877.570496 
	C516.929199,878.183716 516.005066,878.796936 515.080933,879.410156 
	C515.797974,880.153137 516.515015,880.896179 517.231995,881.639160 
	C517.613403,880.450073 517.994751,879.260986 517.853271,877.570496 
M853.791687,1028.380859 
	C853.836426,1029.471436 853.881165,1030.562134 853.925903,1031.652710 
	C854.487427,1031.498291 855.048950,1031.343750 855.610474,1031.189331 
	C855.241028,1030.193848 854.871521,1029.198486 853.791687,1028.380859 
M555.170410,402.626923 
	C556.165771,403.055054 557.161194,403.483215 558.156555,403.911346 
	C558.291870,403.364777 558.427185,402.818176 558.562439,402.271606 
	C557.456848,402.172455 556.351257,402.073273 555.170410,402.626923 
M595.583923,1038.638916 
	C596.768005,1039.093628 597.952087,1039.548218 599.136169,1040.002930 
	C599.253174,1039.470947 599.370117,1038.939087 599.487061,1038.407227 
	C598.365051,1038.336670 597.242981,1038.266113 595.583923,1038.638916 
M670.242493,827.228882 
	C669.656250,827.683411 669.069946,828.138000 668.483643,828.592529 
	C669.275513,828.989990 670.067322,829.387451 670.859192,829.784912 
	C670.853271,829.039551 670.847290,828.294250 670.242493,827.228882 
M259.354889,782.339966 
	C258.721924,782.866821 258.088928,783.393677 257.455933,783.920532 
	C258.228363,784.219177 259.000793,784.517761 259.773193,784.816406 
	C259.825897,784.090210 259.878601,783.364014 259.354889,782.339966 
M322.967804,965.786560 
	C322.740082,964.978760 322.512360,964.171021 322.284576,963.363220 
	C321.675079,963.752747 321.065582,964.142273 320.456116,964.531860 
	C321.075012,964.998352 321.693909,965.464844 322.967804,965.786560 
M501.631012,903.494263 
	C501.647675,904.484985 501.664368,905.475647 501.681030,906.466309 
	C502.236908,906.285278 502.792786,906.104248 503.348694,905.923279 
	C502.993774,905.067139 502.638824,904.211060 501.631012,903.494263 
M220.625900,750.542480 
	C219.719666,750.025024 218.813431,749.507507 217.907196,748.989990 
	C217.704437,749.501770 217.501678,750.013550 217.298920,750.525330 
	C218.407578,750.740417 219.516235,750.955505 220.625900,750.542480 
M424.746246,411.956116 
	C424.008514,412.080933 423.270782,412.205750 422.533051,412.330536 
	C422.609650,412.746307 422.686218,413.162079 422.762817,413.577881 
	C423.457306,413.252502 424.151794,412.927094 424.746246,411.956116 
M320.740417,1037.067505 
	C320.275543,1036.444946 319.810638,1035.822266 319.345764,1035.199707 
	C319.033142,1035.487061 318.720490,1035.774536 318.407806,1036.061890 
	C319.043884,1036.558105 319.679993,1037.054199 320.740417,1037.067505 
M575.524353,905.313477 
	C574.978271,904.975403 574.432129,904.637390 573.886047,904.299316 
	C573.751465,904.573059 573.616821,904.846802 573.482239,905.120544 
	C574.017029,905.325134 574.551758,905.529785 575.524353,905.313477 
M269.476349,264.686554 
	C270.022186,265.024475 270.568054,265.362427 271.113892,265.700348 
	C271.248505,265.426788 271.383087,265.153198 271.517670,264.879608 
	C270.983124,264.675049 270.448608,264.470459 269.476349,264.686554 
M505.143372,896.490295 
	C505.569611,896.792969 505.995850,897.095703 506.422089,897.398376 
	C506.535553,896.950500 506.649048,896.502563 506.762543,896.054626 
	C506.263611,896.001038 505.764709,895.947388 505.143372,896.490295 
M782.904602,1004.270569 
	C782.959412,1004.768188 783.014221,1005.265808 783.069092,1005.763367 
	C783.514832,1005.648926 783.960571,1005.534485 784.406372,1005.420105 
	C784.104614,1004.995239 783.802917,1004.570312 782.904602,1004.270569 
M855.278687,997.882874 
	C855.470032,998.424744 855.661377,998.966675 855.852722,999.508606 
	C856.130310,999.381531 856.407898,999.254456 856.685486,999.127380 
	C856.360962,998.569641 856.036377,998.011902 855.278687,997.882874 
M461.882507,438.722961 
	C462.424988,438.531158 462.967468,438.339355 463.509949,438.147552 
	C463.382843,437.869446 463.255707,437.591339 463.128571,437.313263 
	C462.570190,437.638428 462.011780,437.963593 461.882507,438.722961 
M386.562103,862.292847 
	C386.292603,862.010193 386.023102,861.727539 385.753540,861.444885 
	C385.636658,861.556763 385.411987,861.759521 385.418640,861.767273 
	C385.670013,862.065430 385.942627,862.345642 386.562103,862.292847 
M719.798706,709.393921 
	C719.794556,709.786560 719.790405,710.179260 719.786255,710.571899 
	C719.949158,710.573425 720.253723,710.585938 720.254578,710.574951 
	C720.284729,710.184204 720.286438,709.791199 719.798706,709.393921 
M279.825134,273.245667 
	C279.880371,273.412140 279.935638,273.578613 279.990845,273.745087 
	C280.044739,273.577606 280.098633,273.410126 279.825134,273.245667 
z"/>
<path fill="#FFFFFF" opacity="1.000000" stroke="none" 
	d="
M524.997437,862.000000 
	C520.501465,862.234314 521.060852,859.606018 522.198669,856.860229 
	C522.826355,855.345581 524.051941,854.051880 524.523804,852.510376 
	C524.781677,851.667725 524.320068,850.048584 523.653015,849.635986 
	C522.989990,849.225769 521.274719,849.520142 520.688538,850.138367 
	C515.672424,855.430298 515.717773,855.473267 513.039062,846.958130 
	C512.337097,844.447815 511.676147,842.717224 511.012970,840.550110 
	C511.006744,838.075684 511.002777,836.037842 511.272888,833.771545 
	C512.586792,830.933960 514.202515,828.282410 510.988892,825.658447 
	C510.988220,824.892151 510.988403,824.454285 511.162903,823.677429 
	C511.858551,820.694275 512.516479,818.064636 512.802612,815.395203 
	C512.871887,814.748657 511.819916,813.567871 511.088348,813.375732 
	C510.461517,813.211060 509.534119,814.074585 508.777771,814.544739 
	C507.502228,815.337585 506.256836,816.178955 504.734406,816.782654 
	C504.311859,814.045593 504.153687,811.525818 504.158386,808.693787 
	C504.831238,807.978149 505.413757,807.639038 505.837830,807.159729 
	C507.207581,805.611389 508.519135,804.011597 509.853088,802.431641 
	C508.124451,802.158997 506.299438,802.145752 504.691193,801.548645 
	C502.642944,800.788086 500.135712,800.000061 499.009827,798.394104 
	C497.996460,796.948730 498.158630,794.285461 498.607086,792.345398 
	C498.913666,791.018921 500.602142,790.011902 500.988068,789.597473 
	C497.966522,786.337830 495.228241,783.383850 493.106506,781.095032 
	C490.178619,781.783752 487.588104,782.393066 484.998779,783.001221 
	C485.000000,783.000000 484.997559,783.002441 484.997559,783.002441 
	C484.136627,777.867249 483.224060,772.739807 482.451996,767.591248 
	C482.265320,766.346375 482.447754,765.018677 482.622620,763.751404 
	C483.722931,755.776794 485.833374,747.810852 485.852844,739.837708 
	C486.081970,646.045166 486.019043,552.251892 485.940338,458.458893 
	C485.936615,454.056427 486.667938,450.620819 491.237274,449.252197 
	C491.603363,449.469086 491.809265,449.518463 491.880707,449.644196 
	C493.208740,451.982269 494.520782,454.329407 495.836182,456.674683 
	C498.792847,454.650909 501.749481,452.627136 505.254395,450.299988 
	C508.970306,451.588318 512.137878,453.180023 515.305481,454.771759 
	C517.681458,455.023468 520.095215,455.429047 522.091003,456.567413 
	C524.728943,458.071960 526.851501,460.513611 529.532593,461.901398 
	C534.085449,464.257935 539.442383,465.390564 543.428528,468.403870 
	C546.381165,470.635925 548.475037,471.193329 551.821472,470.300018 
	C555.387268,469.348114 558.355591,473.243988 556.782349,476.705872 
	C555.366455,479.821686 556.140259,481.339020 559.455750,480.907349 
	C561.487793,480.642792 563.362671,479.308929 565.397644,478.873108 
	C566.469421,478.643585 568.668274,479.127350 568.761780,479.602875 
	C569.481384,483.262909 572.159424,483.592896 575.076172,484.066071 
	C581.160034,485.052979 582.431030,486.551086 581.933838,492.759766 
	C581.669800,496.057373 583.253113,497.396271 585.987366,497.788452 
	C589.951843,498.357117 593.956360,498.646759 597.962585,499.436249 
	C597.655884,501.211975 597.330383,502.606873 596.645020,504.040161 
	C594.946655,506.454498 593.608154,508.830414 592.269653,511.206360 
	C594.646179,511.781128 596.995789,512.579407 599.406555,512.869019 
	C601.906921,513.169373 604.466370,512.976929 607.250000,513.000610 
	C607.666687,513.002747 607.833313,513.004211 608.005371,513.382935 
	C608.631165,517.702087 606.945312,523.049194 615.345459,522.601379 
	C609.519775,528.301331 608.373718,534.163635 608.912598,540.669861 
	C609.177612,543.869446 610.318115,545.228088 613.467834,545.012573 
	C614.790894,544.922119 616.125366,544.989624 617.454590,545.001709 
	C623.057373,545.052612 625.046509,546.703552 625.835449,552.012390 
	C626.165405,554.232971 625.643311,557.540161 626.890442,558.441650 
	C628.803162,559.824341 631.905762,559.584961 634.513000,559.968750 
	C635.326904,560.088562 636.175232,559.951477 637.002075,560.011108 
	C640.722778,560.279297 642.510864,563.380798 642.039978,567.097961 
	C641.433777,571.882751 641.572998,576.760254 641.348450,581.595825 
	C641.244873,583.824585 641.040588,586.048767 640.880188,588.274902 
	C640.779236,589.676086 640.095764,591.885315 640.691345,592.312256 
	C641.986572,593.240601 644.018372,593.790771 645.596313,593.538818 
	C648.783936,593.029907 651.869690,591.883484 654.998779,591.333313 
	C655.000854,592.111084 655.001648,592.555542 654.608154,593.089478 
	C653.952087,593.371948 653.457214,593.571228 653.462219,593.756958 
	C653.650330,600.664612 653.860962,607.572083 654.159302,614.475586 
	C654.182312,615.008301 654.756958,615.785461 655.259949,615.986206 
	C658.865906,617.425354 658.060242,619.930115 656.989990,622.555481 
	C655.990295,625.007751 654.527893,627.397095 654.146423,629.945496 
	C653.603027,633.575073 652.474609,638.197571 654.118042,640.808228 
	C656.310608,644.291138 655.826904,647.239624 655.653381,650.593506 
	C655.457458,654.380981 655.001770,658.189575 655.222717,661.955994 
	C655.557861,667.670532 656.539551,673.345276 656.939697,679.058411 
	C657.223145,683.105042 657.104248,687.190735 656.965271,691.252136 
	C656.875000,693.890930 656.385315,696.513733 656.146179,699.150208 
	C655.648560,704.634766 655.244202,710.128296 654.688110,715.606628 
	C654.584351,716.629700 654.228455,718.129333 653.513977,718.504272 
	C649.751770,720.478760 646.528931,724.767334 641.192688,721.073425 
	C641.192688,722.454529 640.922668,723.420227 641.233459,723.673584 
	C645.515015,727.164246 643.479614,730.878723 641.911438,734.738098 
	C641.256287,736.350586 641.284790,738.240906 641.002441,740.406982 
	C641.598267,742.573669 642.194031,744.338257 642.869263,746.338013 
	C642.327759,749.913513 641.708740,754.000977 641.104309,758.060608 
	C641.118896,758.032776 641.120728,758.096436 640.740601,758.067505 
	C639.456787,758.331421 638.466370,758.474731 637.663269,758.940857 
	C631.634033,762.440918 632.451843,768.856873 631.184937,774.543274 
	C631.196411,774.706787 631.095764,775.018799 630.732056,775.044739 
	C628.852905,776.453918 627.389893,777.900818 625.808960,779.204529 
	C623.773926,780.882385 621.801147,782.739624 619.505310,783.964233 
	C617.692139,784.931396 615.431580,785.059814 614.215820,785.355164 
	C613.338745,789.152161 613.295837,792.795349 611.703979,795.540100 
	C607.897278,802.103699 599.206177,804.247742 596.237183,811.821899 
	C596.054626,812.287659 595.043884,812.370850 594.529297,812.773560 
	C590.757263,815.726501 585.641602,817.146790 583.821594,822.329468 
	C582.041260,827.399292 577.866089,827.150452 573.572998,826.981262 
	C568.408997,826.777771 562.852112,825.996765 560.707825,832.800964 
	C560.488525,833.496765 559.081787,833.744019 558.407593,834.389160 
	C557.522583,835.236023 556.167480,836.261963 556.153748,837.227173 
	C556.106750,840.531067 555.279907,842.277344 551.502563,841.424561 
	C550.571594,841.214417 549.383545,842.142944 548.375244,842.524719 
	C548.632996,846.746033 543.596985,851.173950 539.384277,850.659790 
	C531.493530,849.696716 526.328308,853.768127 524.997437,862.000000 
z"/>
<path fill="#FC9352" opacity="1.000000" stroke="none" 
	d="
M477.035400,750.993713 
	C476.998932,751.442688 476.962494,751.891602 476.862091,752.970703 
	C476.575836,755.288391 476.353546,756.976074 476.081940,759.038269 
	C474.860870,758.390564 473.887756,758.066772 473.152832,757.456238 
	C455.851868,743.084351 437.123779,730.852112 417.391632,720.110474 
	C413.624573,718.059875 411.958221,715.249390 411.977600,710.505066 
	C412.356598,617.607605 412.513794,524.709229 412.716339,431.811066 
	C412.722137,429.146973 412.667175,426.480743 412.761627,423.819794 
	C412.804840,422.602600 413.142548,421.395874 413.405884,419.833038 
	C415.279510,420.544800 416.748779,420.891174 418.003296,421.611572 
	C434.138550,430.876892 450.184265,440.300018 466.388184,449.442963 
	C471.925659,452.567413 477.533997,455.266632 476.990173,463.305237 
	C476.959595,464.103058 477.008392,464.561859 476.819458,465.283020 
	C476.736359,467.357971 476.891022,469.170593 476.969604,471.318970 
	C476.943726,472.108215 476.993896,472.561707 476.809540,473.280396 
	C476.731903,475.358124 476.888794,477.170654 476.969604,479.318970 
	C476.943726,480.108215 476.993896,480.561707 476.809570,481.280396 
	C476.731934,483.358124 476.888794,485.170654 476.969604,487.318970 
	C476.943726,488.108215 476.993896,488.561707 476.809570,489.280396 
	C476.731903,491.358124 476.888794,493.170654 476.969604,495.318970 
	C476.943726,496.108215 476.993896,496.561707 476.809540,497.280396 
	C476.731903,499.358124 476.888794,501.170654 476.969604,503.318970 
	C476.943726,504.108215 476.993896,504.561707 476.809570,505.280396 
	C476.731934,507.358124 476.888794,509.170654 476.969635,511.318970 
	C476.943726,512.108154 476.993896,512.561646 476.809570,513.280273 
	C476.731964,515.358032 476.888824,517.170532 476.969604,519.318848 
	C476.943726,520.108093 476.993896,520.561584 476.809570,521.280273 
	C476.731934,523.358032 476.888824,525.170532 476.969604,527.318848 
	C476.943695,528.108093 476.993866,528.561584 476.809570,529.280273 
	C476.731964,531.358032 476.888824,533.170532 476.969604,535.318848 
	C476.943726,536.108093 476.993896,536.561584 476.809570,537.280273 
	C476.731964,539.358032 476.888824,541.170532 476.969604,543.318848 
	C476.943695,544.108093 476.993896,544.561584 476.809570,545.280273 
	C476.731934,547.358032 476.888824,549.170532 476.969604,551.318848 
	C476.943726,552.108093 476.993896,552.561584 476.809570,553.280273 
	C476.731964,555.358032 476.888824,557.170532 476.969604,559.318848 
	C476.943695,560.108093 476.993896,560.561584 476.809570,561.280273 
	C476.731964,563.358032 476.888824,565.170532 476.969604,567.318848 
	C476.943695,568.108093 476.993866,568.561584 476.809570,569.280273 
	C476.731934,571.358032 476.888824,573.170532 476.969604,575.318848 
	C476.943695,576.108093 476.993896,576.561584 476.809570,577.280273 
	C476.731964,579.358032 476.888824,581.170532 476.969604,583.318848 
	C476.943695,584.108093 476.993866,584.561584 476.809570,585.280273 
	C476.731964,587.358032 476.888824,589.170532 476.969604,591.318848 
	C476.943726,592.108093 476.993896,592.561584 476.809570,593.280273 
	C476.731934,595.358032 476.888824,597.170532 476.969604,599.318848 
	C476.943695,600.108093 476.993896,600.561584 476.809570,601.280273 
	C476.731964,603.358032 476.888824,605.170532 476.969604,607.318848 
	C476.943726,608.108093 476.993896,608.561584 476.809570,609.280273 
	C476.731934,611.358032 476.888824,613.170532 476.969604,615.318848 
	C476.943695,616.108093 476.993896,616.561584 476.809570,617.280273 
	C476.731964,619.358032 476.888824,621.170532 476.969604,623.318848 
	C476.943726,624.108093 476.993896,624.561584 476.809601,625.280273 
	C476.731964,627.358032 476.888824,629.170532 476.969604,631.318848 
	C476.943695,632.108093 476.993896,632.561584 476.809570,633.280273 
	C476.731934,635.358032 476.888824,637.170532 476.969604,639.318848 
	C476.943695,640.108093 476.993866,640.561584 476.809570,641.280273 
	C476.731964,643.358032 476.888824,645.170532 476.969604,647.318848 
	C476.943726,648.108093 476.993896,648.561584 476.809570,649.280273 
	C476.731934,651.358032 476.888794,653.170532 476.969604,655.318848 
	C476.943695,656.108093 476.993866,656.561584 476.809570,657.280273 
	C476.731934,659.358032 476.888824,661.170532 476.969604,663.318848 
	C476.943726,664.108093 476.993896,664.561584 476.809570,665.280273 
	C476.731964,667.358032 476.888824,669.170532 476.969604,671.318848 
	C476.943695,672.108093 476.993896,672.561584 476.809570,673.280273 
	C476.731934,675.358032 476.888794,677.170532 476.969604,679.318848 
	C476.943695,680.108093 476.993866,680.561584 476.809570,681.280273 
	C476.731934,683.358032 476.888824,685.170532 476.969604,687.318848 
	C476.943695,688.108093 476.993896,688.561584 476.809570,689.280273 
	C476.731964,691.358032 476.888824,693.170532 476.969604,695.318848 
	C476.943726,696.108093 476.993896,696.561584 476.809570,697.280273 
	C476.731934,699.358032 476.888794,701.170532 476.969604,703.318848 
	C476.943726,704.108093 476.993896,704.561584 476.809570,705.280273 
	C476.731964,707.358032 476.888824,709.170532 476.969604,711.318848 
	C476.943695,712.108093 476.993866,712.561584 476.809570,713.280273 
	C476.731964,715.358032 476.888824,717.170532 476.969604,719.318848 
	C476.943726,720.108093 476.993896,720.561584 476.809570,721.280273 
	C476.731934,723.358032 476.888824,725.170532 476.969604,727.318848 
	C476.943665,728.107971 476.993805,728.561462 476.804596,729.296265 
	C476.724213,734.051453 476.883179,738.525269 476.965210,743.331604 
	C476.938354,744.113464 476.988495,744.562866 476.804657,745.278076 
	C476.725586,747.360474 476.880493,749.177063 477.035400,750.993713 
z"/>
<path fill="#FCF9F2" opacity="1.000000" stroke="none" 
	d="
M477.069580,462.966217 
	C477.533997,455.266632 471.925659,452.567413 466.388184,449.442963 
	C450.184265,440.300018 434.138550,430.876892 418.003296,421.611572 
	C416.748779,420.891174 415.279510,420.544800 413.405884,419.833038 
	C413.142548,421.395874 412.804840,422.602600 412.761627,423.819794 
	C412.667175,426.480743 412.722137,429.146973 412.716339,431.811066 
	C412.513794,524.709229 412.356598,617.607605 411.977600,710.505066 
	C411.958221,715.249390 413.624573,718.059875 417.391632,720.110474 
	C437.123779,730.852112 455.851868,743.084351 473.152832,757.456238 
	C473.887756,758.066772 474.860870,758.390564 476.081940,759.038269 
	C476.353546,756.976074 476.575836,755.288391 476.952545,753.298401 
	C478.377777,756.147339 479.700378,759.281677 480.759705,762.502563 
	C480.810272,762.656311 478.161591,764.363770 477.401672,764.005920 
	C473.583191,762.207947 469.975281,759.962830 466.291779,757.877686 
	C466.147705,757.796143 465.869812,757.767517 465.850922,757.674377 
	C464.440704,750.708923 460.792328,754.471008 457.781433,756.097351 
	C456.763489,756.647095 455.467957,757.912476 455.488556,758.823975 
	C455.510986,759.817261 456.726044,761.276306 457.750183,761.664551 
	C461.235535,762.985962 464.935547,763.746277 468.405792,765.099792 
	C469.735504,765.618469 471.809784,767.667297 471.594696,768.150146 
	C470.185150,771.314575 472.180847,772.921875 474.060913,774.509460 
	C477.408478,777.336121 480.903320,779.988220 484.667053,782.857910 
	C484.997559,783.002441 485.000000,783.000000 484.999481,783.395691 
	C486.466064,786.070007 487.887421,788.379761 489.408936,790.621399 
	C493.813354,797.110657 497.770111,803.960083 503.995483,809.006104 
	C504.153687,811.525818 504.311859,814.045593 504.710541,817.087158 
	C504.612061,818.077881 504.273071,818.546753 503.667297,818.809875 
	C494.209778,801.186829 482.755493,785.534790 466.985413,773.494202 
	C454.564423,764.010681 442.126678,754.487610 429.118103,745.853333 
	C417.178314,737.928467 404.604767,730.916687 392.052734,723.968201 
	C380.230438,717.423584 368.124237,711.381958 356.039398,705.325623 
	C349.879639,702.238708 343.492523,699.605530 337.008820,696.491333 
	C333.070648,694.122070 329.327148,692.037048 325.593750,689.934082 
	C307.932007,679.985168 290.129730,670.275208 272.654541,660.008667 
	C256.098145,650.282043 240.623489,639.094971 229.305695,623.136536 
	C221.870529,612.652771 218.067368,601.292053 221.799500,588.437500 
	C223.372864,583.018372 225.479050,577.753906 227.676727,571.464111 
	C220.277466,577.368347 213.821106,582.862732 209.305450,590.689941 
	C206.364914,594.239075 203.722290,597.635315 200.809860,601.019531 
	C200.360046,601.004944 200.180023,601.002441 199.998734,600.998779 
	C199.997467,600.997498 199.985764,600.985352 199.985779,600.985352 
	C199.914719,597.792419 199.843658,594.599487 199.772598,591.406555 
	C199.218216,591.358643 198.663834,591.310730 198.109451,591.262878 
	C199.059937,589.549438 199.954727,587.802002 200.974503,586.130859 
	C202.348358,583.879517 203.731339,581.678406 206.703400,584.500549 
	C207.272964,585.041443 208.624054,585.024292 209.550079,584.872131 
	C211.783066,584.505005 217.998734,575.753906 218.388702,573.414001 
	C218.587021,572.224060 219.817749,570.947388 220.920029,570.228882 
	C222.489059,569.206116 224.658310,569.008057 226.058884,567.842224 
	C229.368439,565.087341 232.423691,562.017029 235.468765,558.961670 
	C236.166031,558.262085 236.815369,557.097595 236.792160,556.164307 
	C236.631561,549.704346 236.043106,543.249146 236.038788,536.792114 
	C235.982880,453.317871 236.055588,369.843536 235.865891,286.369720 
	C235.854660,281.439178 234.422119,276.314545 232.695221,271.632782 
	C230.283081,265.093323 235.156830,256.557007 242.031403,257.159912 
	C246.134384,257.519745 250.874557,255.389328 254.468506,259.446045 
	C254.854782,259.882080 256.799988,259.071503 257.959595,258.662262 
	C261.218170,257.512360 264.087952,257.348083 265.883179,261.211700 
	C264.491943,262.541260 263.227234,263.749908 261.820709,265.094055 
	C266.114105,270.143280 270.809814,273.945374 277.211945,275.216431 
	C278.018219,275.376526 278.849609,275.653961 279.547791,276.077484 
	C287.779510,281.071411 296.051239,286.004181 304.177582,291.165283 
	C308.602356,293.975464 310.047089,301.224121 307.615845,306.162964 
	C307.196838,307.014160 307.035828,308.071655 307.031097,309.035187 
	C306.988342,317.698975 306.932983,326.364380 307.090088,335.025543 
	C307.112854,336.280975 307.996063,337.756317 308.921082,338.697510 
	C309.970245,339.765045 312.466125,340.154022 312.671661,341.173096 
	C313.391937,344.744324 313.397125,348.459839 313.708557,352.579346 
	C311.131500,352.365936 309.568909,352.236542 307.647095,352.077423 
	C307.647095,449.060913 307.647095,545.767334 307.647095,643.027405 
	C309.696564,642.278870 310.999084,641.803101 313.073212,641.045593 
	C313.722565,647.862244 314.331421,654.253967 314.978882,661.051086 
	C320.178680,659.109924 318.814209,655.524719 318.968079,652.612976 
	C319.123810,649.665710 319.002441,646.703796 319.002441,643.748291 
	C319.429688,643.740112 319.856934,643.731934 320.284180,643.723816 
	C320.284180,651.951233 320.284180,660.178650 320.284180,668.406067 
	C319.990479,668.671204 319.696808,668.936340 319.403107,669.201416 
	C317.094574,666.464600 314.820648,663.697388 312.460114,661.006165 
	C311.540131,659.957275 310.425018,659.079590 309.398743,658.123901 
	C308.584564,659.208069 307.163788,660.241699 307.068359,661.385803 
	C306.765228,665.019836 306.956207,668.695068 306.956207,672.354370 
	C306.461029,672.561890 305.965851,672.769409 305.470673,672.976929 
	C302.683655,669.072815 299.799042,665.234009 297.135468,661.247437 
	C294.097900,656.701050 289.930847,654.754700 285.866608,655.068604 
	C283.380463,652.934570 281.829712,650.549072 279.875916,650.153381 
	C276.191498,649.407043 273.863586,644.492920 269.259918,646.764893 
	C268.936493,646.924438 267.499023,645.929810 267.479919,645.436951 
	C267.196960,638.137268 260.376007,636.150452 256.494385,631.814392 
	C254.763550,629.880920 252.863770,627.956543 250.682831,626.605774 
	C248.229950,625.086731 245.373367,624.219543 242.379456,622.689697 
	C243.972855,618.662720 240.584106,617.401733 238.898346,615.432434 
	C236.927979,613.130615 235.593277,611.607422 236.428299,607.552979 
	C237.566833,602.024902 238.176575,595.171936 231.776871,590.136719 
	C239.273712,588.549500 236.197983,582.292847 238.169556,577.971802 
	C235.568756,576.869080 233.359283,575.932251 231.150208,574.995605 
	C228.691788,579.846924 224.982025,584.262756 224.365295,589.074951 
	C223.471298,596.051025 220.502502,604.298279 228.905685,609.646118 
	C229.712662,610.159729 229.709213,612.022217 229.981033,613.280334 
	C230.257996,614.562256 230.321396,615.892883 230.632217,617.164551 
	C232.169998,623.455872 238.356430,620.761169 241.999390,623.000000 
	C242.170441,623.792725 242.068619,624.882629 242.556351,625.330383 
	C245.975327,628.468994 249.607361,631.380066 252.951035,634.593079 
	C253.747864,635.358704 253.711456,636.991394 254.058273,638.225342 
	C252.826004,637.764038 251.408951,637.549622 250.398102,636.792664 
	C247.927063,634.942383 245.688522,632.783691 243.247803,630.889099 
	C242.752777,630.504822 241.553375,630.429688 241.092422,630.770874 
	C240.645203,631.101990 240.345581,632.516418 240.641815,632.828918 
	C243.176941,635.503845 245.538071,638.580750 248.619232,640.415833 
	C250.603180,641.597412 253.710739,640.892395 256.492584,641.042603 
	C256.788208,639.050659 257.001770,637.611572 257.210419,636.171997 
	C260.616180,638.534546 260.862091,639.134521 258.433350,642.518372 
	C256.630280,645.030518 256.301392,645.885437 259.881897,647.245178 
	C263.501465,648.619690 266.322388,651.980347 269.713318,654.122437 
	C271.454926,655.222717 273.676453,655.563232 276.037079,656.368591 
	C276.196777,661.961609 282.365570,662.967957 286.304657,665.737976 
	C290.791077,668.892883 295.862579,671.474426 301.030487,673.326660 
	C305.872345,675.062134 309.329407,681.276123 316.003265,677.811951 
	C318.051880,682.241638 321.171387,684.817932 326.189087,684.072571 
	C326.634064,684.006531 327.516632,684.254761 327.555939,684.472534 
	C328.677032,690.685730 335.210205,690.268127 338.891571,693.817810 
	C339.960205,693.052734 341.074707,692.254822 341.816864,691.723511 
	C343.203766,693.655396 344.127167,695.888062 345.796570,697.031860 
	C347.967834,698.519409 350.484497,699.276550 350.609009,694.710938 
	C350.646240,693.346252 353.034454,692.045654 354.778656,690.276367 
	C349.899261,687.607361 345.003662,684.693237 339.837860,682.383545 
	C339.141968,682.072449 337.085999,684.803284 335.148132,686.599976 
	C330.011444,684.837036 335.129852,682.521790 336.293732,679.811218 
	C334.511536,679.156250 332.936829,678.558838 331.349274,677.997803 
	C327.187073,676.526733 324.095154,674.200195 323.991882,669.273132 
	C323.681824,654.484131 323.062378,639.696289 323.047211,624.907410 
	C322.956696,536.750671 323.014618,448.593719 322.949768,360.436890 
	C322.947571,357.475525 324.527618,353.744476 319.749176,352.169067 
	C318.990234,351.918854 319.334717,348.321899 319.169067,346.272247 
	C319.677490,346.186890 320.185944,346.101532 320.694366,346.016174 
	C321.403900,347.141388 322.113464,348.266571 322.822998,349.391754 
	C323.275452,349.247345 323.727875,349.102905 324.180298,348.958466 
	C323.263916,346.966614 322.339508,344.978424 321.434021,342.981628 
	C320.832123,341.654327 320.257355,340.314758 319.670441,338.980682 
	C321.036530,338.718872 322.431366,338.557800 323.763855,338.178070 
	C330.123322,336.365967 335.739868,336.477783 339.170868,343.436096 
	C339.369476,343.838867 341.309631,343.225525 342.392181,343.391815 
	C343.597168,343.576904 345.433899,343.777924 345.811951,344.550873 
	C348.361969,349.765106 353.739319,351.626373 357.918671,354.740387 
	C361.350861,357.297729 365.851074,358.421722 370.759491,360.566193 
	C370.812408,360.785950 371.148987,362.183960 371.418671,363.304169 
	C379.544312,364.620636 385.827240,368.541443 391.098175,374.283112 
	C391.434540,374.649536 391.632019,375.197601 392.028778,375.446259 
	C397.519012,378.886444 397.763550,383.984436 397.042023,389.730499 
	C396.094818,397.273407 395.753082,404.890289 394.961243,412.455505 
	C394.497650,416.884338 393.262268,421.258942 393.090759,425.685699 
	C392.822906,432.597382 393.729034,433.394409 400.761200,434.544312 
	C400.836487,436.203125 400.987244,437.977081 400.987488,439.751007 
	C400.999420,524.074951 401.009521,608.398926 400.954132,692.722900 
	C400.952087,695.845215 400.446869,698.996643 399.846527,702.070190 
	C399.678864,702.928772 398.330200,703.556580 397.000977,704.765015 
	C397.000977,700.467102 397.199402,697.213257 396.915985,694.001953 
	C396.777374,692.431396 395.742340,690.939941 395.113037,689.412720 
	C394.408478,690.864868 393.113434,692.310364 393.096771,693.770325 
	C393.001007,702.179321 393.133057,710.594299 393.411194,718.999756 
	C393.444122,719.995361 394.666962,721.197021 395.640411,721.858398 
	C397.094086,722.845947 398.816376,723.438232 400.713745,724.335510 
	C402.063782,722.686035 403.702179,720.684265 406.011353,717.862854 
	C407.157593,722.580078 402.167236,727.560669 408.103790,729.229492 
	C411.322174,730.134155 415.131256,728.937561 418.800140,728.679993 
	C416.448700,734.244507 416.899963,736.193909 420.160797,735.358337 
	C421.275299,735.072754 422.294189,734.432739 423.389313,734.047058 
	C424.294495,733.728149 425.247559,733.545349 426.860443,734.062317 
	C425.718292,735.384888 424.576172,736.707520 423.433868,738.030029 
	C429.044800,744.175415 434.818878,745.746826 442.124115,742.843140 
	C442.978455,742.503479 443.948486,740.801819 443.765411,739.975037 
	C443.586090,739.165344 441.964752,738.099487 441.042511,738.159668 
	C439.516388,738.259216 438.043335,739.172363 436.882782,739.614990 
	C434.952362,736.566589 433.699585,732.065186 431.281372,731.313599 
	C426.748169,729.904541 424.553284,724.840027 418.609467,725.173889 
	C415.102875,725.370972 410.705963,721.425537 410.008850,716.247253 
	C409.749146,714.317871 409.201202,712.427307 408.877838,710.945862 
	C406.655457,711.453003 404.822144,711.871338 402.988831,712.289673 
	C403.148102,710.289307 403.184662,708.269531 403.498169,706.293640 
	C403.876984,703.906311 404.935944,701.556641 404.938049,699.190002 
	C405.014618,612.533020 404.995148,525.875977 405.032654,439.218933 
	C405.033997,436.160919 405.076813,433.422363 402.110901,431.340851 
	C401.082245,430.618958 400.753113,428.485382 400.709076,426.978882 
	C400.691406,426.373932 402.813171,425.016479 403.080505,425.211151 
	C404.317352,426.111542 405.380920,427.346069 406.244293,428.636383 
	C407.104919,429.922485 407.653503,431.417419 408.338928,432.820770 
	C408.892639,432.752014 409.446320,432.683289 410.000031,432.614532 
	C410.000031,429.285889 409.932556,425.955597 410.015411,422.629028 
	C410.148926,417.268188 413.088898,415.799622 417.884918,418.248840 
	C423.598389,421.166595 429.541809,423.633270 435.265015,426.533386 
	C436.751923,427.286835 437.766815,428.951050 439.037689,430.155609 
	C442.233154,433.184448 445.081909,432.769836 447.754364,429.396606 
	C446.000061,424.965912 438.258240,429.461853 438.225708,421.195251 
	C443.612518,423.654144 448.292542,425.790436 452.488800,427.705902 
	C451.260864,430.873718 450.347595,433.229736 449.434326,435.585785 
	C451.025299,436.041840 452.616241,436.497925 453.669281,436.799805 
	C455.396515,438.425140 456.678741,440.079559 458.345551,441.114807 
	C463.010132,444.011993 467.982727,446.431885 472.528107,449.493896 
	C474.632935,450.911774 476.027374,453.384155 477.878357,456.106476 
	C477.698395,458.878418 477.383972,460.922302 477.069580,462.966217 
M241.048355,399.500000 
	C241.045853,467.651031 241.173340,535.802673 240.852188,603.952209 
	C240.817749,611.261780 243.553864,616.180298 248.186249,621.063110 
	C260.108154,633.629578 274.531219,642.787537 289.154846,651.694946 
	C294.034637,654.667297 299.038971,657.435303 305.056488,660.915588 
	C305.056488,656.328979 305.056458,653.215088 305.056488,650.101257 
	C305.058441,534.627686 305.003937,419.153961 305.224396,303.680786 
	C305.235748,297.740845 303.090027,294.659424 298.315186,291.975525 
	C283.104858,283.426025 268.057770,274.586365 252.924225,265.899597 
	C249.277740,263.806488 245.540848,261.870819 241.048431,259.429596 
	C241.048431,306.575104 241.048431,352.537567 241.048355,399.500000 
M390.898895,443.500000 
	C390.871155,422.566498 390.934143,401.631775 390.660645,380.701477 
	C390.636139,378.825623 388.869965,376.248444 387.172821,375.230408 
	C370.244537,365.076233 353.185333,355.138824 336.094757,345.258392 
	C333.489716,343.752380 330.536133,342.849243 326.860321,341.293335 
	C326.860321,344.181732 326.860199,345.979248 326.860352,347.776764 
	C326.869904,454.044250 326.925568,560.311768 326.747101,666.578979 
	C326.738586,671.664368 328.767090,673.927185 332.815704,675.941284 
	C350.211029,684.595215 367.510254,693.442200 384.860992,702.186096 
	C386.397736,702.960449 388.053680,703.498291 390.898712,704.649658 
	C390.898712,617.194092 390.898712,530.847046 390.898895,443.500000 
z"/>
<path fill="#FC9352" opacity="1.000000" stroke="none" 
	d="
M345.000000,873.135681 
	C354.162231,873.126953 362.828827,872.966553 371.483490,873.218323 
	C373.581543,873.279297 376.197601,874.143982 377.628571,875.581604 
	C389.073944,887.080078 399.077881,899.622131 404.357117,915.293030 
	C404.938660,917.019348 405.794128,918.885620 405.583771,920.569641 
	C405.428375,921.813538 403.946289,923.370544 402.689789,923.871765 
	C401.252838,924.444885 399.411316,924.002991 397.745575,924.003113 
	C355.267273,924.005981 312.788940,924.011230 270.310638,924.007751 
	C266.828949,924.007507 263.340302,924.078186 259.866852,923.892700 
	C249.525406,923.340332 241.308319,915.231812 241.154968,904.937012 
	C240.921753,889.282654 240.910385,873.620850 241.097427,857.965759 
	C241.218552,847.828064 249.921265,839.098450 260.071777,839.168091 
	C266.624207,839.213013 273.512299,839.294678 279.633057,841.270813 
	C287.833801,843.918518 291.398315,851.044800 291.735596,859.594238 
	C291.847137,862.422058 292.015656,865.276001 291.764709,868.082825 
	C291.399658,872.166504 293.227844,873.197510 297.024719,873.163696 
	C312.848633,873.023010 328.674713,873.123535 345.000000,873.135681 
z"/>
<path fill="#FBF5EA" opacity="1.000000" stroke="none" 
	d="
M416.975342,975.975647 
	C417.183899,975.017639 417.062103,973.698486 417.652740,973.158142 
	C422.841797,968.411011 425.348938,962.070984 428.243134,955.940613 
	C428.566315,955.256104 429.243500,954.592590 429.923218,954.252136 
	C431.455780,953.484192 434.273438,953.169373 434.466858,952.235168 
	C435.055023,949.394409 435.009369,946.288940 434.544922,943.393372 
	C433.739105,938.369141 432.492767,933.410950 431.310486,928.453857 
	C430.815063,926.376770 429.881409,924.404114 429.385712,922.327087 
	C428.907654,920.324097 428.270203,918.187561 428.524170,916.215454 
	C429.145813,911.388245 427.932343,905.626038 423.118805,904.714111 
	C422.857178,901.364258 422.625977,898.404175 422.394806,895.444092 
	C421.867462,895.423157 421.340088,895.402222 420.812744,895.381287 
	C419.880768,898.183044 418.948792,900.984802 417.875427,904.211731 
	C413.538940,901.288452 416.177734,898.061218 416.476868,895.373474 
	C416.905457,891.521973 412.598389,882.436157 409.772888,879.920837 
	C406.159271,876.704041 403.279602,872.652710 400.172180,868.888000 
	C399.695465,868.310364 399.948608,867.042664 399.431335,866.600159 
	C395.661560,863.375671 391.736847,860.332458 387.961731,857.113892 
	C386.794128,856.118469 385.912415,854.777771 384.949249,853.556213 
	C384.357147,852.805237 384.009064,851.480530 383.295258,851.266052 
	C376.209839,849.137268 372.541138,842.381470 366.422485,838.998047 
	C365.274902,838.363464 364.043732,837.603638 362.793579,837.478943 
	C357.464172,836.947205 354.509674,833.170593 350.989044,829.812805 
	C348.928650,827.847778 345.692291,826.778381 342.800079,826.127686 
	C340.411865,825.590393 338.196075,825.810425 337.868927,822.539673 
	C337.768829,821.539307 336.912231,820.327026 336.044708,819.740417 
	C332.174774,817.123718 328.345520,814.355225 324.179840,812.290771 
	C319.006348,809.726868 313.410461,808.020752 308.207733,805.507996 
	C305.672211,804.283447 303.339905,802.324768 301.403900,800.244141 
	C298.311249,796.920227 294.788971,795.250854 290.249298,795.515503 
	C289.622955,795.552002 288.667114,794.811096 288.329437,794.180725 
	C284.396332,786.838745 275.430847,787.301086 269.929474,782.343689 
	C269.159576,781.650024 267.107544,782.379150 265.646240,782.452148 
	C264.929901,774.391479 257.141083,773.822998 252.368515,770.153748 
	C249.004517,767.567322 245.094208,765.572205 241.187637,763.861572 
	C239.255142,763.015442 236.780457,763.288147 234.550842,763.285828 
	C233.273087,763.284424 231.994843,763.746338 230.716797,763.999695 
	C231.093231,762.879578 231.202225,761.546570 231.894104,760.677612 
	C234.388290,757.545227 231.696854,756.152100 229.927338,754.615601 
	C222.614822,748.266052 215.168015,742.070557 207.885223,735.687683 
	C205.551880,733.642700 203.467453,731.282837 201.455994,728.908752 
	C198.616501,725.557373 196.355896,727.468201 193.474060,730.593445 
	C193.292587,728.188538 192.866440,726.756104 193.150497,725.482056 
	C193.879913,722.210388 195.149475,718.929626 189.522644,719.489929 
	C189.111816,719.530823 188.199249,718.027832 188.101608,717.176025 
	C187.174072,709.085144 184.141159,701.594482 182.021759,693.869507 
	C179.552231,684.868286 180.828339,674.849915 180.380569,665.277527 
	C180.240753,662.288086 179.752197,659.316345 179.481049,656.331177 
	C179.349106,654.878723 178.781479,653.095276 179.389786,652.010437 
	C181.988647,647.375732 184.952515,642.945740 187.664902,638.629395 
	C189.256287,638.786133 190.737793,638.932068 192.219315,639.078003 
	C192.191772,637.675842 192.012589,636.257385 192.161591,634.874268 
	C193.061951,626.516052 194.037354,618.165833 195.332947,609.406555 
	C196.122604,609.003113 196.566940,609.006042 196.991379,609.415344 
	C196.686691,611.100830 196.537827,612.429138 196.095352,613.651245 
	C192.932632,622.386414 192.945587,631.518127 195.326218,640.087402 
	C199.521790,655.189575 208.937012,667.498779 221.140274,676.891235 
	C236.394150,688.631775 252.534821,699.244995 268.553650,709.954529 
	C274.949585,714.230591 282.042206,717.464539 288.907257,721.512085 
	C290.328247,722.725891 291.599091,723.714966 293.000732,724.456787 
	C302.283997,729.369385 311.595337,734.228882 321.042908,739.418213 
	C325.890533,742.475769 330.601898,745.205261 335.293213,747.968811 
	C349.963409,756.610962 364.890839,764.853882 379.205017,774.050842 
	C389.991180,780.981018 400.039642,789.070801 410.323334,796.768799 
	C424.057892,807.049927 435.895905,819.265442 445.722961,833.285706 
	C458.181763,851.060730 464.253662,870.828796 461.926788,892.634216 
	C461.197205,899.471252 459.644714,906.324402 457.587372,912.894165 
	C453.261566,926.707825 446.716339,939.573364 438.168823,951.232056 
	C431.744690,959.994507 424.491028,968.148743 417.448730,976.424255 
	C417.290833,976.274780 416.975311,975.975647 416.975342,975.975647 
M333.149658,759.354492 
	C322.499878,753.547241 311.835632,747.766296 301.203094,741.927673 
	C274.392700,727.205200 247.327530,712.898315 223.880432,692.853699 
	C209.151123,680.261719 196.578217,666.132996 190.019104,647.467285 
	C189.628098,646.354553 188.806564,645.393066 187.526306,643.266479 
	C186.192795,648.812683 184.980484,653.085754 184.151810,657.431885 
	C180.141434,678.465271 183.686920,698.002563 195.478821,716.074524 
	C206.493958,732.956116 221.029221,746.359314 237.612442,757.238525 
	C259.236084,771.424438 281.419464,784.799011 303.762299,797.836731 
	C334.753448,815.921143 366.379486,832.972595 393.269379,857.207886 
	C408.012360,870.495422 420.894073,885.353027 427.754303,904.207764 
	C431.668365,914.965149 433.629425,926.433105 436.352844,937.121704 
	C436.187531,937.363464 436.743073,936.767578 437.054352,936.063660 
	C443.234436,922.088745 447.124390,907.463806 447.811920,892.224487 
	C449.173737,862.038269 436.906738,837.255920 415.971619,816.440491 
	C392.018250,792.624023 363.124634,775.818481 333.149658,759.354492 
z"/>
<path fill="#F5985B" opacity="1.000000" stroke="none" 
	d="
M99.381691,973.417236 
	C103.722504,973.467285 103.305923,975.977295 102.695404,978.571411 
	C100.070602,989.724670 97.419228,1000.871765 94.813889,1012.029602 
	C93.756386,1016.558472 93.119965,1021.221924 91.617889,1025.592407 
	C91.043365,1027.264160 88.688705,1029.267700 86.969116,1029.448975 
	C76.136810,1030.590820 76.027039,1030.463501 72.931854,1020.210266 
	C70.867188,1013.370789 69.138000,1006.430542 67.208405,999.549500 
	C66.694954,997.718628 65.996460,995.939575 64.136429,994.149597 
	C62.780197,999.150146 61.404285,1004.145386 60.072941,1009.152588 
	C58.578754,1014.772217 57.361633,1020.482666 55.502453,1025.977173 
	C54.976379,1027.531860 52.629395,1028.473755 51.103638,1029.679077 
	C50.999901,1029.761108 50.778549,1029.689209 50.612324,1029.697632 
	C39.149193,1030.272949 39.098957,1030.167358 36.572758,1018.987183 
	C33.573982,1005.715576 30.586746,992.437927 27.287514,979.239624 
	C26.208426,974.922791 26.748108,973.208801 31.678953,973.279358 
	C40.060421,973.399292 40.001183,973.144409 41.678371,981.383423 
	C43.300900,989.353882 45.050888,997.298828 46.799908,1005.242859 
	C47.066662,1006.454468 47.630722,1007.600647 48.511173,1010.028870 
	C51.482990,999.324951 54.052544,989.902954 56.721668,980.509338 
	C58.836899,973.065125 58.891079,973.262512 66.615158,973.263062 
	C70.073288,973.263306 71.649277,974.539673 72.568695,977.786743 
	C75.413757,987.834595 78.513535,997.810242 82.538795,1008.102417 
	C83.404510,1005.117065 84.435471,1002.168030 85.100700,999.138611 
	C86.383675,993.296204 87.352303,987.384521 88.647827,981.545227 
	C90.498314,973.204468 90.573738,973.221191 99.381691,973.417236 
z"/>
<path fill="#F5985B" opacity="1.000000" stroke="none" 
	d="
M525.305420,962.902527 
	C525.346680,962.485962 525.388000,962.069458 525.452026,960.958313 
	C525.669678,957.565063 525.864563,954.866394 526.512329,952.126953 
	C530.304382,951.901978 533.643616,951.717712 537.309448,951.672974 
	C538.106140,951.831482 538.576233,951.850525 539.126221,952.125549 
	C539.488953,952.585144 539.771729,952.788818 540.001709,953.335205 
	C540.041443,954.126526 540.134033,954.575134 540.226685,955.023743 
	C540.168152,970.034546 540.109680,985.045288 540.030029,1000.971191 
	C540.071655,1009.939087 540.134460,1017.991943 540.061646,1026.419922 
	C537.005066,1031.159790 532.572388,1028.792480 528.971069,1028.706787 
	C527.369873,1028.668701 525.847290,1025.327637 524.167908,1023.342651 
	C524.120056,1023.369751 523.630432,1023.527527 523.301636,1023.849548 
	C513.276001,1033.666748 497.119904,1031.680298 490.848053,1019.168335 
	C485.229340,1007.959229 484.897095,996.099243 490.615936,984.599121 
	C495.874115,974.025330 507.856140,969.859558 518.588013,974.774597 
	C520.854736,975.812683 522.818176,977.513184 526.225037,979.773315 
	C525.870850,973.275757 525.588135,968.089172 525.305420,962.902527 
M501.892822,994.748108 
	C501.647430,998.551575 501.107147,1002.364014 501.222992,1006.156433 
	C501.416199,1012.479736 506.118134,1017.461060 511.905701,1018.004333 
	C518.671265,1018.639465 523.722900,1015.351807 525.016541,1008.554077 
	C525.806396,1004.403381 525.962036,999.918396 525.309265,995.754822 
	C524.217712,988.792847 520.325562,985.266907 514.837830,984.943420 
	C507.364502,984.502930 504.980682,986.173950 501.892822,994.748108 
z"/>
<path fill="#F4995C" opacity="1.000000" stroke="none" 
	d="
M942.898804,992.040955 
	C942.885986,997.717773 942.873169,1003.394592 942.848267,1009.996033 
	C942.822937,1015.251343 942.590454,1019.595825 942.860535,1023.908752 
	C943.109253,1027.878906 941.576233,1029.479492 937.684937,1029.101929 
	C936.364746,1028.973877 935.003662,1028.942383 933.692444,1029.110596 
	C929.951721,1029.590332 928.756531,1028.005249 928.826416,1024.314819 
	C929.015686,1014.323730 928.884766,1004.326599 928.877136,994.331848 
	C928.873840,990.018799 927.712585,986.220947 923.031555,985.097412 
	C917.475952,983.763977 912.665894,986.255920 911.043640,991.375732 
	C910.005310,994.652405 909.407227,998.177063 909.288086,1001.614075 
	C909.028564,1009.101257 909.001160,1016.610840 909.277832,1024.096558 
	C909.427795,1028.155029 907.935120,1029.606445 904.064758,1029.081787 
	C903.082947,1028.948730 902.047729,1028.924683 901.072571,1029.077271 
	C896.769104,1029.750610 895.682922,1027.651001 895.687561,1023.707275 
	C895.699524,1013.557617 895.357910,1003.408020 895.223755,993.257507 
	C895.177246,989.739258 894.781189,986.186707 890.787659,985.294983 
	C886.667175,984.375000 882.194641,984.325562 879.513062,988.454712 
	C878.127747,990.587708 877.081543,993.293152 876.923096,995.805725 
	C876.410339,1003.935181 876.360229,1012.094543 876.168091,1020.243225 
	C875.942017,1029.826904 877.837402,1028.978638 866.874023,1029.089844 
	C863.834106,1029.120605 862.075195,1028.184937 861.940613,1024.995605 
	C861.995178,1015.307129 862.049744,1005.618591 862.121216,995.005615 
	C862.158508,988.935791 861.966797,983.775574 862.324585,978.653870 
	C862.436218,977.055786 863.716858,974.611023 865.003479,974.183899 
	C870.618591,972.319641 874.761047,974.439148 877.038513,979.668213 
	C887.830139,970.061523 898.460510,970.257385 908.827759,981.471008 
	C909.680237,980.471130 910.502747,979.519043 911.311707,978.555603 
	C915.433044,973.647278 924.486572,971.455261 931.496399,973.672668 
	C938.126160,975.769897 941.608765,980.584045 942.137695,988.237915 
	C942.226135,989.517029 942.636963,990.773865 942.898804,992.040955 
M939.864990,1023.734924 
	C940.689331,1011.723450 939.185364,999.704834 939.967896,987.692444 
	C939.999634,987.204224 940.063721,986.532288 939.794067,986.238708 
	C936.617065,982.779480 933.379150,979.376221 930.238403,976.044250 
	C926.594543,979.064697 926.470703,981.717529 928.871338,985.086853 
	C930.129761,986.853149 930.818237,989.427795 930.801758,991.630249 
	C930.731262,1001.065979 930.147644,1010.498657 930.120483,1019.933411 
	C930.114136,1022.133606 931.088989,1025.759521 932.439880,1026.201660 
	C934.388489,1026.839600 937.093262,1025.167969 939.864990,1023.734924 
M921.117432,976.464172 
	C918.672607,977.720581 916.175171,978.888489 913.816589,980.289856 
	C913.150330,980.685791 912.889954,981.764587 912.443359,982.530090 
	C913.256348,982.858826 914.133850,983.568359 914.870056,983.443665 
	C916.953918,983.090637 919.100159,982.647156 920.985107,981.744812 
	C922.173706,981.175903 923.189392,979.834229 923.752075,978.597778 
	C923.943054,978.177917 922.510254,977.019226 921.117432,976.464172 
z"/>
<path fill="#F79454" opacity="1.000000" stroke="none" 
	d="
M212.086517,1000.069031 
	C212.459381,998.634827 212.832214,997.200562 213.483459,995.237183 
	C214.178848,994.144165 214.595825,993.580261 215.347778,992.952820 
	C215.795410,991.587097 215.908081,990.284973 216.045929,988.658569 
	C216.376831,987.557007 216.682571,986.779663 217.307770,985.835205 
	C218.327164,984.214478 219.475250,982.810486 219.643036,981.297729 
	C220.145920,976.764404 222.772339,975.777405 226.819229,975.888977 
	C230.888229,976.001160 231.241898,978.676392 232.135880,981.669189 
	C233.309753,985.599060 235.207947,989.312561 236.854919,993.473999 
	C237.269485,995.213562 237.623779,996.598267 237.712372,998.234436 
	C236.795578,1001.246338 236.551483,1003.848022 240.036957,1005.318237 
	C240.137375,1006.030334 240.192307,1006.437622 240.191498,1007.155151 
	C240.442078,1007.965881 240.748413,1008.466248 241.205566,1009.267578 
	C242.511154,1009.298035 244.578110,1009.212952 244.676636,1008.727905 
	C245.477859,1004.783936 245.910690,1000.765076 246.455673,996.769043 
	C246.589600,995.758057 246.723511,994.747009 247.096481,993.210205 
	C247.465698,992.106384 247.595856,991.528381 248.032288,990.762451 
	C248.398209,989.662231 248.457870,988.750000 248.620026,987.490356 
	C248.769638,986.421143 248.816727,985.699219 249.163788,984.761475 
	C250.218979,981.334290 250.974197,978.122986 251.786896,974.877930 
	C251.844360,974.844177 251.920105,974.734497 252.376648,974.819580 
	C261.216248,974.866516 261.214752,974.866577 261.501312,982.642761 
	C261.505859,982.766846 261.710114,982.883667 261.821106,983.003845 
	C261.781616,983.379395 261.742126,983.755005 261.341949,984.562622 
	C259.823761,989.717346 258.666260,994.439941 257.431641,999.455078 
	C257.321045,1000.149658 257.287567,1000.551697 257.135223,1001.206543 
	C257.026550,1001.645508 257.036743,1001.831543 256.994476,1002.381531 
	C256.928650,1003.477051 256.915314,1004.208557 256.901978,1004.940125 
	C256.901978,1004.940125 256.867126,1004.810364 256.468201,1004.950562 
	C254.744232,1010.173462 253.532776,1015.289124 252.068405,1020.331299 
	C249.695755,1028.500610 249.626923,1028.308716 240.952271,1028.246094 
	C237.593735,1028.221680 236.009918,1026.536499 235.223282,1023.855713 
	C233.923737,1019.426819 233.025223,1014.881836 231.803986,1010.427917 
	C230.127090,1004.312256 228.306793,998.235901 226.547928,992.142700 
	C226.048080,992.132324 225.548233,992.121948 225.048386,992.111511 
	C224.339691,993.644531 223.402618,995.114746 222.961105,996.721191 
	C220.853333,1004.390625 218.914200,1012.106079 216.849823,1019.787720 
	C214.585358,1028.213867 214.549454,1028.014526 205.885391,1028.232788 
	C201.904419,1028.333008 200.415237,1026.882690 199.625061,1023.054443 
	C198.091095,1015.622559 195.931610,1008.319702 193.960800,1000.752380 
	C193.750488,1000.264343 193.537415,1000.066162 193.251114,999.946716 
	C193.251114,999.946716 193.123840,999.473694 193.111908,998.852966 
	C192.802261,996.485107 192.504578,994.737976 192.411026,992.778381 
	C192.188675,991.654358 191.762177,990.742737 191.252228,989.560669 
	C190.852066,988.852905 190.535370,988.415588 190.218658,987.978271 
	C190.126480,987.260437 190.034302,986.542664 189.963318,985.082092 
	C189.676880,982.565002 189.369263,980.790649 189.304688,978.791016 
	C190.055420,977.098389 190.563110,975.631104 191.070801,974.163818 
	C191.070801,974.163818 191.504944,974.023376 192.136749,974.063965 
	C194.439651,974.180603 196.110748,974.256714 197.895111,974.602600 
	C198.640884,975.280273 199.273392,975.688171 199.938538,976.311890 
	C199.971161,976.527649 200.064331,976.954041 200.041656,977.270325 
	C200.343719,978.407349 200.668457,979.228088 201.165070,980.093140 
	C201.336960,980.137512 201.688904,980.091187 201.688904,980.091187 
	C201.769485,980.507812 201.850052,980.924500 201.917267,981.984497 
	C202.186508,983.422485 202.469101,984.217102 202.751678,985.011658 
	C202.828949,985.439819 202.906219,985.867981 202.962097,987.005493 
	C203.225235,989.486755 203.509781,991.258728 203.588776,993.247070 
	C203.835678,994.353210 204.288116,995.242859 204.740570,996.132507 
	C204.740570,996.132507 204.851730,996.075500 204.849640,996.459412 
	C204.915543,998.211121 204.983505,999.578979 205.109772,1001.184326 
	C205.317093,1001.723511 205.537430,1001.958130 205.856140,1002.478271 
	C206.209534,1004.189880 206.535858,1005.549072 206.716309,1007.032104 
	C206.696625,1007.452515 206.822784,1007.749084 206.972839,1008.273315 
	C206.996704,1008.500854 207.056442,1008.954590 207.075638,1009.297607 
	C208.507446,1011.703979 209.448257,1011.258972 210.255478,1008.927979 
	C210.668030,1008.521118 210.820068,1008.232605 211.080811,1007.853638 
	C211.328827,1007.699951 211.480423,1007.542847 211.457474,1007.510681 
	C211.323181,1007.323181 211.148071,1007.164917 211.052277,1006.612793 
	C211.440704,1004.175537 211.763611,1002.122253 212.086517,1000.069031 
z"/>
<path fill="#FCF7EE" opacity="1.000000" stroke="none" 
	d="
M719.014832,1029.909668 
	C718.910400,1030.039429 718.813843,1030.275391 718.700378,1030.283813 
	C711.068665,1030.852417 703.433167,1031.854858 695.803284,1031.814209 
	C689.483582,1031.780640 689.486511,1031.234741 688.534668,1037.263672 
	C688.080994,1037.468872 687.627258,1037.674072 687.173584,1037.879272 
	C686.686340,1036.462646 686.051819,1035.076172 685.754333,1033.620728 
	C685.471375,1032.236328 685.540649,1030.780029 685.455017,1029.355347 
	C683.455872,1029.776855 680.465759,1029.540771 679.660339,1030.754395 
	C677.889221,1033.423462 676.129089,1032.731812 673.738403,1032.531860 
	C670.910706,1032.295410 667.841980,1033.708008 665.173462,1033.102539 
	C661.597290,1032.291260 657.730469,1030.882690 655.049622,1028.524292 
	C652.299255,1026.104614 650.683594,1025.365845 648.175354,1028.253906 
	C647.438965,1029.101685 646.408508,1029.917603 645.358032,1030.233887 
	C636.568787,1032.880493 627.800720,1033.498291 619.310913,1029.171509 
	C618.339905,1028.676758 617.643188,1027.466797 617.043884,1026.455322 
	C615.689514,1024.169189 614.311890,1021.870361 613.263184,1019.438904 
	C612.584290,1017.864990 611.801697,1015.862061 612.215637,1014.370544 
	C614.268616,1006.974243 613.074768,999.674316 611.874451,992.409729 
	C611.610107,990.810181 610.022034,988.959167 608.536926,988.114136 
	C602.578308,984.723572 601.339966,973.498108 606.723389,969.509644 
	C607.205566,969.152344 607.990112,968.978088 608.596069,969.048218 
	C613.790344,969.649170 614.708008,967.074707 612.634705,961.633606 
	C618.266113,960.704651 623.353394,959.683533 628.491394,959.185608 
	C629.415710,959.096069 630.812439,961.013794 631.518555,962.270264 
	C632.388611,963.818359 632.389709,965.924011 633.418579,967.301575 
	C637.567078,972.855835 639.525513,973.086975 646.286987,969.433533 
	C650.162476,974.383850 650.274048,982.176697 645.730103,985.776367 
	C643.891541,987.232788 640.421021,987.052124 637.730957,986.908386 
	C633.975098,986.707825 632.730225,987.992004 632.924744,991.740295 
	C633.209229,997.223267 632.717407,1002.752563 633.117737,1008.221008 
	C633.312134,1010.876770 634.780396,1013.439270 636.093323,1017.275269 
	C638.584717,1013.714050 640.046387,1011.624634 641.827820,1009.078186 
	C643.346741,1011.116333 644.597656,1012.794983 646.123840,1014.842957 
	C650.021240,1013.089905 650.838684,1009.361755 650.907410,1005.640503 
	C650.978455,1001.795349 651.784546,999.372681 656.154907,998.686401 
	C658.755371,998.277954 660.268311,996.557190 657.590576,993.766907 
	C647.460327,983.210815 652.415466,976.093445 663.393372,972.765381 
	C667.593262,971.492065 671.786621,969.315613 676.015076,969.234924 
	C681.668579,969.127197 687.436218,969.811646 692.998352,971.757507 
	C697.190002,973.223816 698.772095,976.037109 698.957581,979.939819 
	C699.403687,989.327393 699.760925,998.721741 699.965942,1008.117432 
	C700.131165,1015.688293 701.972595,1017.206848 709.359863,1015.365356 
	C710.000244,1015.205750 710.664917,1015.143860 711.662354,1015.447388 
	C709.601318,1023.787537 711.021484,1026.634766 719.014832,1029.909668 
M655.966187,1003.474915 
	C650.533020,1010.443909 651.014099,1019.420227 657.155029,1025.657715 
	C663.604614,1032.208618 674.203186,1031.208618 685.141052,1022.734680 
	C688.949463,1028.631226 694.375488,1031.177368 701.213257,1028.821655 
	C702.734009,1028.297729 704.494751,1026.182129 704.688416,1024.618774 
	C704.995300,1022.140747 705.833801,1018.522888 701.462708,1018.120544 
	C698.286682,1017.828186 697.863770,1015.788696 697.872986,1013.213440 
	C697.899658,1005.716614 697.941833,998.218140 697.823547,990.722717 
	C697.680115,981.636780 692.184021,974.527161 683.765686,973.302307 
	C674.246521,971.917175 665.294312,973.637939 657.173035,979.318542 
	C652.339722,982.699402 655.356873,985.387024 657.237122,988.430542 
	C659.461487,992.031189 661.464111,990.752197 664.027649,988.614258 
	C668.742554,984.682190 674.281921,982.881531 680.269836,985.018555 
	C681.846680,985.581238 683.521484,987.919495 683.730164,989.617126 
	C683.897095,990.975220 682.228882,993.171875 680.801514,993.968262 
	C678.697571,995.142151 676.103516,995.489380 673.683533,996.034607 
	C667.567871,997.412354 661.197388,998.113586 655.966187,1003.474915 
M615.796875,988.918823 
	C615.804504,996.244446 615.793518,1003.570129 615.823853,1010.895691 
	C615.879883,1024.449829 622.713257,1031.239990 636.060974,1029.816895 
	C648.424194,1028.498657 648.369751,1026.272461 643.349609,1016.608215 
	C643.286316,1016.486328 643.078918,1016.439331 642.642517,1016.183960 
	C642.099609,1016.353638 641.337585,1016.595459 640.573303,1016.829895 
	C632.894165,1019.184998 629.914062,1017.058289 629.824341,1009.081421 
	C629.768127,1004.087280 629.754150,999.091309 629.816772,994.097351 
	C629.945312,983.843140 628.335999,985.058533 638.717773,984.980774 
	C645.157959,984.932495 644.828552,984.949341 645.167175,978.753113 
	C645.545349,971.833252 640.656860,974.064514 637.373413,973.958130 
	C630.085083,973.722107 630.227783,973.861633 629.761963,966.687805 
	C629.641968,964.839172 628.679565,961.906006 627.412598,961.466980 
	C621.738342,959.500854 615.961670,963.416504 615.354553,969.421814 
	C615.050232,972.431946 614.201843,974.022461 610.897278,973.950439 
	C605.985229,973.843262 607.355713,977.699829 607.094116,980.450684 
	C606.817810,983.356750 607.537170,985.092346 611.095886,985.164856 
	C612.677124,985.197144 614.222046,987.011475 615.796875,988.918823 
z"/>
<path fill="#FFFFFF" opacity="1.000000" stroke="none" 
	d="
M476.990173,463.305237 
	C477.383972,460.922302 477.698395,458.878418 478.019287,456.418610 
	C484.225708,457.790985 484.914581,458.948792 483.831299,465.104309 
	C483.403625,467.534576 483.034912,470.016174 483.033752,472.474609 
	C482.993347,558.377502 483.002472,644.280396 482.989349,730.183289 
	C482.988892,733.026672 483.970337,736.674316 478.456970,735.580994 
	C477.692413,733.114197 477.368164,731.064514 477.043945,729.014893 
	C476.993805,728.561462 476.943665,728.107971 477.239838,727.073853 
	C477.405457,724.667114 477.224762,722.841064 477.044067,721.015076 
	C476.993896,720.561584 476.943726,720.108093 477.239807,719.073853 
	C477.405457,716.667114 477.224762,714.841064 477.044067,713.015076 
	C476.993866,712.561584 476.943695,712.108093 477.239807,711.073853 
	C477.405457,708.667114 477.224762,706.841064 477.044067,705.015076 
	C476.993896,704.561584 476.943726,704.108093 477.239807,703.073853 
	C477.405426,700.667114 477.224762,698.841064 477.044067,697.015076 
	C476.993896,696.561584 476.943726,696.108093 477.239838,695.073853 
	C477.405426,692.667114 477.224762,690.841064 477.044067,689.015076 
	C476.993896,688.561584 476.943695,688.108093 477.239838,687.073853 
	C477.405457,684.667114 477.224762,682.841064 477.044067,681.015076 
	C476.993866,680.561584 476.943695,680.108093 477.239807,679.073853 
	C477.405457,676.667114 477.224762,674.841064 477.044067,673.015076 
	C476.993896,672.561584 476.943695,672.108093 477.239807,671.073853 
	C477.405457,668.667114 477.224762,666.841064 477.044067,665.015076 
	C476.993896,664.561584 476.943726,664.108093 477.239807,663.073853 
	C477.405426,660.667114 477.224762,658.841064 477.044067,657.015076 
	C476.993866,656.561584 476.943695,656.108093 477.239807,655.073853 
	C477.405426,652.667114 477.224762,650.841064 477.044067,649.015076 
	C476.993896,648.561584 476.943726,648.108093 477.239838,647.073853 
	C477.405457,644.667114 477.224762,642.841064 477.044067,641.015076 
	C476.993866,640.561584 476.943695,640.108093 477.239807,639.073853 
	C477.405457,636.667114 477.224762,634.841064 477.044067,633.015076 
	C476.993896,632.561584 476.943695,632.108093 477.239807,631.073853 
	C477.405457,628.667114 477.224762,626.841064 477.044067,625.015076 
	C476.993896,624.561584 476.943726,624.108093 477.239838,623.073853 
	C477.405426,620.667114 477.224762,618.841064 477.044067,617.015076 
	C476.993896,616.561584 476.943695,616.108093 477.239807,615.073853 
	C477.405426,612.667114 477.224762,610.841064 477.044067,609.015076 
	C476.993896,608.561584 476.943726,608.108093 477.239838,607.073853 
	C477.405457,604.667114 477.224762,602.841064 477.044067,601.015076 
	C476.993896,600.561584 476.943695,600.108093 477.239807,599.073853 
	C477.405457,596.667114 477.224762,594.841064 477.044067,593.015076 
	C476.993896,592.561584 476.943726,592.108093 477.239807,591.073853 
	C477.405457,588.667114 477.224762,586.841064 477.044067,585.015076 
	C476.993866,584.561584 476.943695,584.108093 477.239807,583.073853 
	C477.405426,580.667114 477.224762,578.841064 477.044067,577.015076 
	C476.993896,576.561584 476.943695,576.108093 477.239807,575.073853 
	C477.405426,572.667114 477.224762,570.841064 477.044067,569.015076 
	C476.993866,568.561584 476.943695,568.108093 477.239838,567.073853 
	C477.405457,564.667114 477.224762,562.841064 477.044067,561.015076 
	C476.993896,560.561584 476.943695,560.108093 477.239807,559.073853 
	C477.405457,556.667114 477.224762,554.841064 477.044067,553.015076 
	C476.993896,552.561584 476.943726,552.108093 477.239807,551.073853 
	C477.405457,548.667114 477.224762,546.841064 477.044067,545.015076 
	C476.993896,544.561584 476.943695,544.108093 477.239807,543.073853 
	C477.405426,540.667114 477.224762,538.841064 477.044067,537.015076 
	C476.993896,536.561584 476.943726,536.108093 477.239807,535.073853 
	C477.405426,532.667114 477.224762,530.841064 477.044067,529.015076 
	C476.993866,528.561584 476.943695,528.108093 477.239838,527.073853 
	C477.405457,524.667114 477.224762,522.841064 477.044067,521.015076 
	C476.993896,520.561584 476.943726,520.108093 477.239807,519.073853 
	C477.405457,516.667114 477.224762,514.841064 477.044067,513.015076 
	C476.993896,512.561646 476.943726,512.108154 477.239807,511.073914 
	C477.405396,508.667175 477.224731,506.841187 477.044067,505.015198 
	C476.993896,504.561707 476.943726,504.108215 477.239807,503.073944 
	C477.405396,500.667175 477.224731,498.841187 477.044067,497.015198 
	C476.993896,496.561707 476.943726,496.108215 477.239807,495.073944 
	C477.405426,492.667175 477.224762,490.841187 477.044067,489.015198 
	C476.993896,488.561707 476.943726,488.108215 477.239807,487.073944 
	C477.405396,484.667175 477.224731,482.841187 477.044067,481.015198 
	C476.993896,480.561707 476.943726,480.108215 477.239807,479.073944 
	C477.405426,476.667175 477.224762,474.841187 477.044067,473.015198 
	C476.993896,472.561707 476.943726,472.108215 477.239624,471.074341 
	C477.409515,468.669525 477.233337,466.845093 477.057190,465.020660 
	C477.008392,464.561859 476.959595,464.103058 476.990173,463.305237 
z"/>
<path fill="#F5985B" opacity="1.000000" stroke="none" 
	d="
M455.037109,1017.655701 
	C456.091705,1017.746460 457.146332,1017.837280 458.949646,1017.881958 
	C463.446350,1015.246643 467.194305,1012.657532 471.497253,1009.684937 
	C473.297668,1010.473938 476.151550,1011.724487 479.005432,1012.975098 
	C479.272369,1013.758789 479.539276,1014.542542 479.594116,1015.866028 
	C471.572571,1031.804565 449.788727,1034.821655 437.701111,1022.343079 
	C432.931580,1017.419250 431.498413,1011.250366 430.032349,1004.986694 
	C430.039520,1003.601440 430.046722,1002.216125 430.086548,1000.029541 
	C430.734100,996.155029 430.881409,992.891785 432.041321,990.040344 
	C438.577820,973.971741 450.032166,971.223877 464.081726,973.481445 
	C470.838745,974.567261 477.590210,977.615051 479.011963,986.003601 
	C478.773010,986.222473 478.545013,986.452271 477.820160,986.881775 
	C473.153412,990.781189 468.915741,992.841797 464.333984,987.689819 
	C464.333984,987.689819 464.217316,987.903992 464.161438,987.425537 
	C458.061890,982.566528 449.963257,984.483765 446.985748,990.999939 
	C446.881683,991.320007 446.777588,991.640137 446.356781,992.436035 
	C445.952240,998.602783 445.290253,1004.361816 445.954102,1009.963623 
	C446.507782,1014.635986 450.134949,1017.290161 455.037109,1017.655701 
z"/>
<path fill="#EDB289" opacity="1.000000" stroke="none" 
	d="
M535.002075,401.970947 
	C536.081726,402.346619 537.161316,402.722321 538.831238,403.390137 
	C545.277161,405.818878 551.132812,407.955505 556.988464,410.092163 
	C557.331787,410.152588 557.675049,410.213043 558.428467,410.534546 
	C559.561646,411.187531 560.284790,411.579437 561.007874,411.971375 
	C561.757629,412.361633 562.507385,412.751862 563.821655,413.405853 
	C570.585754,416.812134 576.785400,419.954681 582.984985,423.097260 
	C582.984985,423.097260 583.288086,423.216522 583.601257,423.518494 
	C591.396851,428.625244 598.965698,433.302094 606.331665,438.279083 
	C611.346863,441.667725 616.078369,445.476379 620.936707,449.097229 
	C621.505066,449.597839 622.073425,450.098450 622.965210,451.090515 
	C624.169006,452.087921 625.049255,452.593842 625.929565,453.099731 
	C625.929565,453.099731 626.012207,453.044952 626.093628,453.366943 
	C628.078064,455.171356 629.981018,456.653778 631.884033,458.136200 
	C631.884033,458.136200 631.993896,458.016266 631.979126,458.402710 
	C634.278320,460.924835 636.592346,463.060547 638.906311,465.196259 
	C639.374756,465.745544 639.843201,466.294861 640.635132,467.305969 
	C643.279297,470.303986 645.546570,472.891754 647.935974,475.361389 
	C650.183899,477.684723 652.560669,479.883362 654.880737,482.136902 
	C654.880737,482.136902 654.981079,482.001312 654.968079,482.398315 
	C664.189575,495.985992 673.419434,509.179932 682.662720,522.364441 
	C684.338684,524.755005 686.069275,527.107178 687.774658,529.477112 
	C687.788513,529.821167 687.802368,530.165161 687.922241,531.116760 
	C689.507080,534.510132 691.318726,537.171997 692.409058,540.102295 
	C698.424438,556.269775 704.253479,572.506531 710.198730,588.700378 
	C710.455139,589.398560 711.225952,589.907898 711.757080,590.505249 
	C711.794739,591.756104 711.832397,593.007019 711.892090,595.039062 
	C712.292175,597.578857 712.700073,599.331787 713.043396,601.097229 
	C714.661194,609.415833 716.261353,617.737915 717.867737,626.058716 
	C717.864441,626.492310 717.861145,626.925842 717.873413,628.144592 
	C717.974243,632.423950 717.831055,635.941284 718.183350,639.408386 
	C720.008667,657.372131 718.901062,675.236633 716.395020,693.035767 
	C715.125549,702.052429 713.413330,711.006653 711.901306,719.989136 
	C711.901306,719.989136 711.816711,720.347229 711.530151,720.761169 
	C708.537964,730.120605 705.832397,739.066040 703.126831,748.011536 
	C702.733276,749.095520 702.339661,750.179443 701.671509,751.852844 
	C700.557190,754.953918 699.717407,757.465515 698.877686,759.977173 
	C698.877686,759.977173 698.778931,760.303223 698.480835,760.627258 
	C692.686218,771.440247 687.387390,782.039368 681.624634,792.379944 
	C677.702209,799.418274 673.130188,806.094543 668.848633,812.932739 
	C668.194275,813.714294 667.539917,814.495850 666.547852,815.750610 
	C665.127991,817.468628 664.043640,818.711548 662.963867,819.958557 
	C658.322205,825.318604 653.681946,830.679871 648.957458,835.958008 
	C648.873779,835.875183 648.706421,835.709595 648.796875,835.378174 
	C654.616272,827.198242 660.749756,819.608948 665.982971,811.442688 
	C674.665710,797.893250 683.537476,784.367920 690.882080,770.087952 
	C697.115356,757.968689 702.761841,745.391724 705.443848,731.718506 
	C706.934021,724.121460 710.445862,716.906372 711.783020,709.297119 
	C714.043335,696.434448 715.909119,683.439087 716.841736,670.420776 
	C718.343201,649.462158 715.662415,628.662292 712.445251,607.984924 
	C711.388306,601.191650 709.516541,594.513123 707.798584,587.838440 
	C706.317627,582.084839 704.870300,576.281921 702.787720,570.733704 
	C699.892639,563.021057 696.775452,555.348083 693.109558,547.978394 
	C687.322998,536.345459 681.771240,524.476196 674.696167,513.636353 
	C667.205322,502.159424 658.357361,491.510651 649.518799,480.990265 
	C643.158142,473.419281 636.222412,466.225861 628.856934,459.630432 
	C618.805603,450.629944 608.653198,441.681427 596.735535,435.000061 
	C587.027039,429.557281 577.947693,422.906036 567.982544,418.030975 
	C557.473816,412.889984 546.218628,409.285919 535.351685,404.857910 
	C532.800537,403.818420 530.499146,402.165924 528.329590,400.693695 
	C528.737793,400.516968 528.899170,400.442017 529.060547,400.367065 
	C530.101196,400.585938 531.141846,400.804779 532.752930,401.271149 
	C533.882935,401.669434 534.442505,401.820190 535.002075,401.970947 
z"/>
<path fill="#FBF5EA" opacity="1.000000" stroke="none" 
	d="
M496.004028,902.004883 
	C494.094330,905.126587 492.184692,908.248291 489.846741,911.826782 
	C488.951141,913.193787 488.483887,914.104065 488.016602,915.014343 
	C486.346710,918.273010 485.374847,923.511414 482.867737,924.395325 
	C476.874939,926.508179 476.634979,932.630432 472.531494,936.134033 
	C462.887451,944.368103 454.949066,954.571838 446.006683,963.670166 
	C442.609741,967.126221 438.306671,969.679993 434.499207,972.747559 
	C430.286957,976.141052 425.604004,979.191956 422.253113,983.309265 
	C420.621521,985.314087 420.834106,989.555664 421.451782,992.526855 
	C424.025055,1004.904236 421.356293,1016.151245 414.557648,1026.432129 
	C413.127075,1028.595459 409.961731,1030.687134 407.459045,1030.862549 
	C400.267548,1031.366577 393.005920,1030.812134 385.772095,1030.824707 
	C384.439545,1030.827026 383.103943,1031.523071 381.777710,1031.925049 
	C380.739502,1032.239990 379.696564,1032.922119 378.685577,1032.869385 
	C376.055359,1032.732056 373.435516,1032.321899 370.822601,1031.944092 
	C366.307892,1031.291016 364.176361,1028.306396 364.889984,1023.769958 
	C364.992859,1023.115967 364.998260,1022.441895 364.998413,1021.777100 
	C365.003601,998.962524 365.002716,976.147888 365.002716,953.372375 
	C363.665955,952.743958 362.455109,952.174744 361.316742,951.639587 
	C365.989166,949.557007 370.421783,947.518799 374.919769,945.636536 
	C375.691437,945.313660 377.090576,945.275513 377.608795,945.750549 
	C382.432220,950.172180 385.762939,955.237793 383.751953,962.238953 
	C383.568481,962.877625 383.263031,963.492981 383.162933,964.142090 
	C382.305542,969.701721 385.426971,972.085327 390.801331,971.057190 
	C396.880096,969.894287 403.131378,969.477112 409.328735,969.189026 
	C411.097870,969.106812 412.940216,970.599792 414.032532,971.065247 
	C415.729614,972.907166 410.946045,976.378357 416.614258,975.985596 
	C416.975311,975.975647 417.290833,976.274780 417.284424,976.711060 
	C416.678955,978.484436 416.079956,979.821472 415.443054,981.243042 
	C409.801086,975.069153 404.554260,973.233093 398.480530,972.928467 
	C392.048828,972.605957 387.101318,975.496399 382.010986,980.534546 
	C381.632690,977.783752 381.219910,976.071838 381.193542,974.354126 
	C381.106689,968.692566 380.890717,963.011963 381.244110,957.371216 
	C381.526550,952.862854 379.847046,951.613892 375.519592,951.792847 
	C367.951416,952.105774 367.855896,951.915833 367.850372,957.526611 
	C367.829956,978.346252 367.831970,999.165955 367.828491,1019.985596 
	C367.826904,1029.608276 367.829285,1029.198120 377.407349,1029.182007 
	C382.338531,1029.173706 380.125519,1024.612061 382.560883,1023.074219 
	C383.129120,1023.412720 383.754486,1023.647644 384.200592,1024.071289 
	C393.454041,1032.858765 407.973267,1031.606445 415.051819,1021.093445 
	C422.022339,1010.740845 421.095123,999.401123 418.539093,987.914368 
	C418.177948,986.291443 418.297180,983.712952 419.308105,982.766052 
	C428.362396,974.285217 437.526489,965.908569 446.928589,957.815247 
	C462.464539,944.441711 475.249512,928.700073 487.034698,912.073120 
	C490.515228,907.162842 493.704346,902.046143 497.054321,897.052002 
	C497.081665,897.079956 497.137024,897.135132 497.089508,897.490112 
	C496.696014,899.231689 496.350006,900.618286 496.004028,902.004883 
z"/>
<path fill="#FCF7EE" opacity="1.000000" stroke="none" 
	d="
M723.971863,1012.164856 
	C726.546448,1010.044434 729.063477,1008.212280 732.839783,1009.348877 
	C736.041504,1010.312561 737.099304,1007.857117 736.927917,1005.352051 
	C736.370178,997.199646 736.583130,989.266479 741.228882,982.091064 
	C741.507080,981.661438 741.409485,980.857483 741.249817,980.295044 
	C739.180481,973.008911 741.769287,970.407043 749.591064,971.206116 
	C752.485168,971.501770 755.551147,970.627502 758.476379,969.997314 
	C764.283630,968.746155 769.906860,968.019836 775.716675,970.523438 
	C777.871094,971.451782 780.761047,970.673401 782.194153,970.673401 
	C784.045959,973.422607 785.306702,976.012634 787.212952,977.975891 
	C791.291809,982.176758 791.021851,986.549194 786.384216,990.151001 
	C782.004578,993.552307 777.910034,996.321594 773.375732,990.384338 
	C772.801697,989.632629 771.244263,989.631958 770.480530,989.390381 
	C769.150208,991.383667 767.887268,993.275940 766.213196,995.784302 
	C764.786011,993.718140 763.986511,992.560669 763.136414,991.330017 
	C762.706970,991.471191 762.259827,991.658813 761.794006,991.765259 
	C753.970276,993.554138 756.359375,999.805420 755.975586,1004.909607 
	C755.588806,1010.052979 759.026489,1010.933167 762.926086,1011.964600 
	C765.642822,1012.683228 768.103516,1014.369873 771.777283,1016.159241 
	C771.048889,1009.171814 775.429321,1008.066895 779.000183,1007.387817 
	C783.479126,1006.535889 790.121216,1010.249451 790.496521,1013.757324 
	C790.790405,1016.503967 789.401367,1019.676880 788.044983,1022.289124 
	C786.483215,1025.296875 784.110291,1027.878784 782.135864,1030.678345 
	C780.354492,1033.203979 778.687378,1034.385254 775.113159,1033.012695 
	C772.340149,1031.947754 768.797791,1032.857300 765.598938,1032.943726 
	C764.215088,1032.980957 762.789062,1033.433716 761.462036,1033.214355 
	C757.786133,1032.606689 754.146667,1031.770508 750.505493,1030.966919 
	C749.568176,1030.760010 748.005310,1030.517334 747.867432,1029.992310 
	C746.064575,1023.127441 739.693054,1021.631836 734.332703,1018.380615 
	C734.749695,1017.706604 735.503540,1017.042480 735.425781,1016.497009 
	C735.270386,1015.408020 734.659058,1014.384155 734.236023,1013.333374 
	C733.512512,1014.091492 732.282593,1014.772156 732.152771,1015.621155 
	C731.575378,1019.395752 731.813232,1022.963074 736.086060,1023.661011 
	C733.273865,1025.051270 730.419189,1026.462402 727.278015,1027.484131 
	C730.711365,1020.668823 729.939331,1016.851562 723.971863,1012.164856 
M740.655151,990.441223 
	C740.185425,992.717651 739.590210,994.976440 739.266174,997.273438 
	C737.028748,1013.133301 745.540344,1026.371460 759.908936,1029.470459 
	C771.252563,1031.916992 782.073303,1027.811401 786.898621,1019.235535 
	C788.603760,1016.205078 788.911499,1013.552368 785.014160,1011.974182 
	C782.233887,1010.848328 779.735413,1006.462830 776.688599,1012.178406 
	C773.980347,1017.258606 768.135742,1019.124817 762.420959,1017.812012 
	C758.050659,1016.808105 754.768372,1012.705078 753.965210,1007.143616 
	C753.680298,1005.170837 753.517883,1003.179443 753.327393,1001.193909 
	C752.704346,994.702148 755.761841,988.108215 760.446655,985.809998 
	C765.591125,983.286438 771.454346,985.025757 776.777893,990.274658 
	C777.540344,991.026367 779.590698,991.065979 780.769775,990.651794 
	C782.268311,990.125244 783.438293,988.724548 784.834900,987.833984 
	C787.526672,986.117676 787.831970,983.938904 785.982361,981.538757 
	C784.690491,979.862305 783.304443,978.104614 781.584778,976.935913 
	C767.452393,967.331543 747.745361,973.548462 740.655151,990.441223 
z"/>
<path fill="#FCF7EE" opacity="1.000000" stroke="none" 
	d="
M549.065308,1012.544189 
	C549.316406,1009.457458 549.525696,1006.759583 549.734985,1004.061646 
	C550.050171,1004.057495 550.365356,1004.053284 550.680603,1004.049072 
	C550.594604,1003.735535 550.558228,1003.397095 550.414673,1003.112549 
	C549.444336,1001.188965 548.451355,999.276672 547.465698,997.360779 
	C549.600281,997.229675 551.733337,997.056030 553.869934,996.980713 
	C555.671509,996.917175 557.477173,996.967529 559.780029,996.967529 
	C556.700989,993.632324 554.054993,990.774170 551.417847,987.907837 
	C546.777527,982.864014 548.273132,977.880981 554.693604,976.146484 
	C556.857605,975.561829 558.642822,973.659180 560.794189,972.922302 
	C563.416138,972.024170 566.248047,971.749756 568.976990,971.150391 
	C571.511230,970.593872 574.003418,969.788269 576.561096,969.410400 
	C580.118896,968.884766 583.591980,968.152100 586.847900,971.156616 
	C589.236267,973.360474 592.837585,974.253540 595.212830,976.466614 
	C597.024719,978.154846 598.583008,980.915100 598.824341,983.340759 
	C599.578979,990.923584 599.717224,998.571899 599.959045,1006.199158 
	C600.035278,1008.604492 600.828613,1009.915222 602.723206,1011.768921 
	C605.531372,1014.516418 608.217773,1018.925964 608.168091,1022.590149 
	C608.125244,1025.751343 604.632629,1029.379761 601.802856,1031.788574 
	C600.210999,1033.143433 596.801331,1032.926147 594.338745,1032.558594 
	C590.872253,1032.041382 587.524597,1030.727661 584.326111,1030.753662 
	C582.055603,1031.263916 579.805542,1032.063232 577.510437,1032.225708 
	C572.372253,1032.589111 567.086121,1033.412598 562.093140,1032.606445 
	C556.388000,1031.685181 552.776550,1027.454956 551.578308,1021.262573 
	C549.862732,1021.777039 548.350891,1022.230408 546.686829,1022.729431 
	C547.491821,1019.354431 548.257629,1016.143738 549.065308,1012.544189 
M604.008606,1020.918579 
	C603.360535,1020.047607 602.721069,1018.431152 602.063110,1018.423584 
	C596.755615,1018.362549 597.070496,1014.784851 597.093201,1011.154236 
	C597.140015,1003.670105 597.245483,996.178772 596.980164,988.702698 
	C596.743835,982.042908 593.926392,976.844910 587.177307,974.598694 
	C578.059509,971.564270 569.200439,972.284729 560.732727,976.817932 
	C552.256531,981.355652 552.035339,984.968811 559.853882,990.984070 
	C562.299683,989.409058 564.605530,987.110535 567.337280,986.357666 
	C571.332764,985.256531 575.634155,984.980347 579.804016,984.987854 
	C580.915222,984.989868 582.796387,987.231140 582.931152,988.583191 
	C583.092041,990.197449 582.219299,992.608398 580.960693,993.508850 
	C579.048279,994.876770 576.451355,995.363892 574.085449,996.004639 
	C570.088562,997.087219 565.881897,997.578186 562.054871,999.067017 
	C553.989990,1002.204468 550.578308,1008.940002 552.159058,1017.456360 
	C553.556091,1024.983154 559.528442,1029.549194 567.899292,1029.990967 
	C574.710999,1030.350586 580.132874,1027.905396 584.387268,1022.236267 
	C585.878235,1023.974670 586.871643,1025.272583 588.007751,1026.430420 
	C591.043884,1029.524780 598.316040,1031.109009 601.888306,1028.584351 
	C603.439758,1027.487915 603.385681,1024.119751 604.008606,1020.918579 
z"/>
<path fill="#FCF7EE" opacity="1.000000" stroke="none" 
	d="
M861.592529,1025.058228 
	C862.075195,1028.184937 863.834106,1029.120605 866.874023,1029.089844 
	C877.837402,1028.978638 875.942017,1029.826904 876.168091,1020.243225 
	C876.360229,1012.094543 876.410339,1003.935181 876.923096,995.805725 
	C877.081543,993.293152 878.127747,990.587708 879.513062,988.454712 
	C882.194641,984.325562 886.667175,984.375000 890.787659,985.294983 
	C894.781189,986.186707 895.177246,989.739258 895.223755,993.257507 
	C895.357910,1003.408020 895.699524,1013.557617 895.687561,1023.707275 
	C895.682922,1027.651001 896.769104,1029.750610 901.072571,1029.077271 
	C902.047729,1028.924683 903.082947,1028.948730 904.064758,1029.081787 
	C907.935120,1029.606445 909.427795,1028.155029 909.277832,1024.096558 
	C909.001160,1016.610840 909.028564,1009.101257 909.288086,1001.614075 
	C909.407227,998.177063 910.005310,994.652405 911.043640,991.375732 
	C912.665894,986.255920 917.475952,983.763977 923.031555,985.097412 
	C927.712585,986.220947 928.873840,990.018799 928.877136,994.331848 
	C928.884766,1004.326599 929.015686,1014.323730 928.826416,1024.314819 
	C928.756531,1028.005249 929.951721,1029.590332 933.692444,1029.110596 
	C935.003662,1028.942383 936.364746,1028.973877 937.684937,1029.101929 
	C941.576233,1029.479492 943.109253,1027.878906 942.860535,1023.908752 
	C942.590454,1019.595825 942.822937,1015.251343 942.924316,1010.458862 
	C947.365723,1014.209229 947.251648,1028.718750 942.221069,1030.817261 
	C938.544983,1032.350708 933.279724,1032.758301 929.813660,1031.216064 
	C927.289917,1030.093262 925.278625,1024.973267 925.270630,1021.629944 
	C925.253723,1014.510193 923.526611,1007.272827 926.736450,1000.126282 
	C928.461548,996.285461 925.726379,992.873718 922.008911,990.902222 
	C920.288879,989.990112 918.466248,989.271423 916.400574,988.333557 
	C913.584290,993.469666 910.003723,998.080505 910.857056,1004.803223 
	C911.729797,1011.678040 911.716492,1018.708618 911.529358,1025.655884 
	C911.439758,1028.982666 910.053284,1032.274414 909.066101,1036.360718 
	C907.865234,1035.445190 906.986633,1034.677246 906.013245,1034.058472 
	C904.837952,1033.311157 903.222595,1031.822754 902.419373,1032.155518 
	C897.081116,1034.367310 894.114441,1030.399292 891.588928,1027.469849 
	C889.734680,1025.319214 889.489380,1021.559204 889.067078,1018.443237 
	C888.225708,1012.234863 887.746582,1005.976990 887.136719,999.737793 
	C886.816223,996.458862 886.046753,992.952637 891.066711,992.246948 
	C889.790955,987.979858 886.486938,988.136963 883.860840,988.720703 
	C879.928894,989.594666 880.680054,993.640869 880.157471,996.444458 
	C879.243103,1001.349548 878.857483,1006.362000 878.435242,1011.345215 
	C878.287842,1013.085022 878.793823,1014.869934 878.813171,1016.636292 
	C878.855530,1020.498901 879.363220,1024.504028 878.481873,1028.162109 
	C878.087769,1029.797852 874.951172,1031.217773 872.797546,1031.864014 
	C870.488586,1032.557007 867.870361,1032.196167 865.390381,1032.348389 
	C861.983704,1032.557129 859.690186,1028.581421 861.592529,1025.058228 
z"/>
<path fill="#F8D4B7" opacity="1.000000" stroke="none" 
	d="
M189.061646,979.016296 
	C189.369263,980.790649 189.676880,982.565002 189.895264,984.726685 
	C188.385162,982.770813 186.964279,980.427673 184.916534,977.050720 
	C183.119431,983.782715 181.411148,989.689087 179.977432,995.661255 
	C177.441422,1006.225098 175.174759,1016.854858 172.509415,1027.384766 
	C172.159973,1028.765137 170.219208,1030.559448 168.853882,1030.711670 
	C164.906265,1031.151489 160.518692,1031.808960 157.006439,1030.498169 
	C154.739166,1029.652100 153.132309,1025.673218 152.240723,1022.786011 
	C150.343872,1016.643066 149.148590,1010.285950 147.565903,1004.041931 
	C147.218796,1002.672485 146.454941,1001.408691 145.883011,1000.096252 
	C145.374557,1000.023987 144.866104,999.951782 144.357651,999.879517 
	C143.272720,1003.254822 142.021820,1006.587585 141.142242,1010.015625 
	C139.867447,1014.983887 139.029831,1020.066833 137.684937,1025.013672 
	C136.672363,1028.737915 134.864853,1031.222412 130.006561,1031.053467 
	C118.712646,1030.660522 118.718048,1030.953369 116.369019,1020.413940 
	C113.202301,1006.205505 110.154617,991.967896 106.737656,977.819641 
	C105.704178,973.540405 107.391937,972.360229 111.059364,972.099670 
	C120.951225,971.396790 121.422340,972.079102 123.098495,981.917114 
	C124.297600,988.955200 126.464935,995.828308 129.304352,1002.949951 
	C129.865036,1001.543091 130.577744,1000.176331 130.960571,998.722595 
	C132.774887,991.832886 134.571823,984.936829 136.231995,978.008850 
	C137.510330,972.674255 141.051086,971.978577 145.944931,971.921753 
	C151.241241,971.860168 153.541199,974.527588 154.823776,978.821777 
	C156.896515,985.761353 158.733093,992.771301 160.737259,999.731934 
	C160.981903,1000.581665 161.644501,1001.311035 162.857208,1003.341675 
	C163.789124,1000.436707 164.510468,998.730408 164.874619,996.951111 
	C165.968002,991.608582 166.892105,986.231628 167.937271,980.878906 
	C169.630112,972.208984 174.084137,969.789307 182.498123,972.839478 
	C184.028717,973.394348 185.661438,973.667603 187.648987,974.476318 
	C188.387863,976.259949 188.724747,977.638123 189.061646,979.016296 
M108.919754,978.524902 
	C112.089966,992.268921 115.278534,1006.008789 118.424110,1019.758484 
	C120.621704,1029.364380 120.592331,1029.274658 130.554993,1028.953125 
	C133.396240,1028.861328 134.558258,1027.862183 135.253769,1025.077271 
	C137.586639,1015.736450 140.203934,1006.464722 142.848587,997.205444 
	C143.277771,995.703003 144.398972,994.398193 145.200241,993.002014 
	C146.089996,994.450562 147.355820,995.791260 147.807281,997.365479 
	C150.369537,1006.299377 152.747421,1015.286316 155.167084,1024.260864 
	C156.697510,1029.937256 161.268875,1028.721802 165.340424,1028.915649 
	C169.075546,1029.093262 170.538055,1027.379517 171.330353,1023.832520 
	C174.478577,1009.738708 177.991440,995.726562 181.181366,981.641602 
	C181.647980,979.581177 181.886719,976.046631 180.842850,975.385254 
	C179.081268,974.269348 175.856186,974.139709 173.909561,975.035706 
	C172.182587,975.830566 170.727142,978.435608 170.243484,980.497681 
	C168.275345,988.888611 166.782898,997.390076 164.972900,1005.820251 
	C164.643066,1007.356506 163.648621,1008.750122 162.960968,1010.209595 
	C160.126038,1005.004822 158.521347,1000.021362 157.038712,995.001892 
	C155.486404,989.746582 154.010635,984.467712 152.578445,979.178284 
	C151.502228,975.203552 148.388641,974.199951 144.952637,974.245483 
	C141.880844,974.286255 139.481400,975.193848 138.636642,979.132568 
	C137.107407,986.262939 134.831085,993.239136 132.724777,1000.237061 
	C131.666550,1003.752869 130.296585,1007.174927 129.067596,1010.639343 
	C127.557098,1009.571350 127.102188,1008.724304 126.911232,1007.821411 
	C124.886665,998.248657 122.592941,988.717590 121.026085,979.069031 
	C120.079124,973.237976 115.590294,975.338257 112.520081,974.582275 
	C111.619583,974.360596 110.118645,976.577515 108.919754,978.524902 
z"/>
<path fill="#FBF5EA" opacity="1.000000" stroke="none" 
	d="
M299.992676,1000.889954 
	C299.992676,988.581543 300.021606,976.757385 299.961792,964.933594 
	C299.952698,963.136108 299.728241,961.288208 299.265106,959.554016 
	C297.774353,953.972168 298.995026,951.389160 304.185059,949.364746 
	C304.112701,948.136108 303.560944,946.344177 304.085815,945.935730 
	C304.911987,945.292786 306.762726,945.057495 307.653229,945.567993 
	C311.000519,947.486694 314.659180,949.308228 317.155579,952.100769 
	C318.695984,953.823914 318.620087,957.223022 318.776733,959.898499 
	C319.019379,964.042847 318.840729,968.211853 318.840729,973.700500 
	C327.622223,965.776245 335.499847,970.766541 343.075989,972.894836 
	C351.303864,975.206299 354.787048,981.955383 356.229523,990.058655 
	C358.048096,1000.274231 357.089935,1010.199341 353.802765,1019.943115 
	C353.405609,1021.120300 352.127899,1021.971436 351.438629,1023.085327 
	C350.109863,1025.232544 348.909912,1027.459595 347.378815,1030.139648 
	C341.792114,1031.467651 335.206726,1033.380615 328.486633,1034.434082 
	C326.260803,1034.783081 322.856232,1033.608154 321.454742,1031.916870 
	C318.889130,1028.820312 317.040375,1029.810791 314.839722,1031.678345 
	C312.253937,1033.872681 309.879181,1034.833740 307.074219,1031.848145 
	C306.345947,1031.072998 304.384338,1031.582275 303.379547,1030.914307 
	C301.460876,1029.638550 299.068451,1028.270874 298.227448,1026.348633 
	C296.547333,1022.508423 295.781708,1018.268127 294.420837,1013.417664 
	C300.769409,1012.079590 300.134918,1006.616516 299.992676,1000.889954 
M315.195099,1026.078491 
	C315.614655,1025.160645 316.034180,1024.242798 316.532471,1023.152588 
	C328.774658,1035.082642 348.213745,1031.068359 352.663666,1013.763855 
	C354.500000,1006.622742 354.654419,998.465210 353.286774,991.200562 
	C350.003235,973.758728 333.824310,967.676147 319.147705,977.527100 
	C318.254242,978.126770 317.268463,978.588928 315.901611,979.351685 
	C315.605530,977.943176 315.257385,977.036133 315.248840,976.125916 
	C315.189575,969.825073 314.811707,963.493042 315.301697,957.230957 
	C315.770538,951.239502 312.125031,951.995911 308.381409,951.860107 
	C304.261383,951.710632 301.614014,951.948547 301.698792,957.500549 
	C302.031647,979.299438 301.808105,1001.106323 301.962128,1022.909058 
	C301.976257,1024.908447 303.268219,1028.633301 303.860413,1028.612061 
	C307.632721,1028.475708 312.041260,1031.209473 315.195099,1026.078491 
z"/>
<path fill="#FCF9F2" opacity="1.000000" stroke="none" 
	d="
M524.989380,862.380005 
	C526.328308,853.768127 531.493530,849.696716 539.384277,850.659790 
	C543.596985,851.173950 548.632996,846.746033 548.375244,842.524719 
	C549.383545,842.142944 550.571594,841.214417 551.502563,841.424561 
	C555.279907,842.277344 556.106750,840.531067 556.153748,837.227173 
	C556.167480,836.261963 557.522583,835.236023 558.407593,834.389160 
	C559.081787,833.744019 560.488525,833.496765 560.707825,832.800964 
	C562.852112,825.996765 568.408997,826.777771 573.572998,826.981262 
	C577.866089,827.150452 582.041260,827.399292 583.821594,822.329468 
	C585.641602,817.146790 590.757263,815.726501 594.529297,812.773560 
	C595.043884,812.370850 596.054626,812.287659 596.237183,811.821899 
	C599.206177,804.247742 607.897278,802.103699 611.703979,795.540100 
	C613.295837,792.795349 613.338745,789.152161 614.215820,785.355164 
	C615.431580,785.059814 617.692139,784.931396 619.505310,783.964233 
	C621.801147,782.739624 623.773926,780.882385 625.808960,779.204529 
	C627.389893,777.900818 628.852905,776.453918 630.699402,775.400269 
	C629.009827,779.150696 626.989075,782.571472 624.897095,786.162231 
	C624.825745,786.332092 624.746765,786.691956 624.427673,786.800537 
	C619.068420,792.936768 614.028259,798.964417 608.994019,804.996094 
	C609.000000,805.000000 608.991394,804.987732 608.652344,805.032715 
	C601.757874,811.042542 595.213013,817.018860 588.643127,822.967590 
	C585.042908,826.227417 581.400513,829.440552 577.799194,832.717590 
	C577.821228,832.760376 577.727295,832.739319 577.369080,832.766663 
	C576.071350,833.084290 574.948608,833.137268 574.219482,833.700073 
	C561.719360,843.348206 547.707336,850.305420 533.223022,856.276611 
	C528.335876,858.291260 526.489136,861.151062 527.208374,866.421387 
	C527.133789,867.161255 527.119446,867.586914 526.823364,868.220825 
	C526.475281,869.332703 526.409058,870.236267 526.265137,871.447937 
	C526.154480,872.178101 526.121399,872.600159 525.814697,873.199097 
	C525.018921,874.585144 524.496765,875.794312 523.974609,877.003479 
	C519.555847,875.930237 520.760742,872.797974 521.666077,870.086426 
	C522.510559,867.557251 523.856262,865.195496 524.989380,862.380005 
z"/>
<path fill="#FCF9F2" opacity="1.000000" stroke="none" 
	d="
M597.943787,499.055420 
	C593.956360,498.646759 589.951843,498.357117 585.987366,497.788452 
	C583.253113,497.396271 581.669800,496.057373 581.933838,492.759766 
	C582.431030,486.551086 581.160034,485.052979 575.076172,484.066071 
	C572.159424,483.592896 569.481384,483.262909 568.761780,479.602875 
	C568.668274,479.127350 566.469421,478.643585 565.397644,478.873108 
	C563.362671,479.308929 561.487793,480.642792 559.455750,480.907349 
	C556.140259,481.339020 555.366455,479.821686 556.782349,476.705872 
	C558.355591,473.243988 555.387268,469.348114 551.821472,470.300018 
	C548.475037,471.193329 546.381165,470.635925 543.428528,468.403870 
	C539.442383,465.390564 534.085449,464.257935 529.532593,461.901398 
	C526.851501,460.513611 524.728943,458.071960 522.091003,456.567413 
	C520.095215,455.429047 517.681458,455.023468 515.305481,454.771759 
	C512.137878,453.180023 508.970306,451.588318 505.401855,449.995850 
	C501.576691,448.991974 497.955811,448.404083 494.770599,446.895935 
	C488.850708,444.092957 483.147736,440.818207 477.456665,437.561310 
	C476.677582,437.115448 476.484497,435.645599 476.380371,434.319122 
	C477.455994,434.394348 478.207092,434.748566 478.881531,435.213898 
	C489.506470,442.544495 501.023560,447.611084 514.294189,449.132538 
	C515.295654,449.227325 515.963989,449.281677 516.799194,449.650696 
	C517.357605,450.257690 517.711121,450.634521 518.146118,450.829895 
	C530.319458,456.296539 542.640564,461.458313 554.631042,467.299164 
	C562.314331,471.041901 569.511780,475.781799 576.956299,480.062866 
	C576.985596,480.051056 576.931335,480.059570 577.007690,480.310181 
	C577.389099,480.700806 577.694153,480.840790 578.190308,481.051056 
	C578.381409,481.121338 578.782654,481.189911 578.873657,481.516602 
	C582.556213,484.925629 586.081116,488.090515 589.761780,491.062469 
	C592.391418,493.185760 595.229980,495.050140 597.987671,497.014404 
	C598.000000,496.998779 597.969360,497.025757 597.981750,497.332336 
	C598.060242,498.041656 598.126282,498.444458 598.129761,498.898743 
	C598.067200,498.950256 597.943787,499.055450 597.943787,499.055420 
z"/>
<path fill="#FCF7EE" opacity="1.000000" stroke="none" 
	d="
M943.211243,991.768677 
	C942.636963,990.773865 942.226135,989.517029 942.137695,988.237915 
	C941.608765,980.584045 938.126160,975.769897 931.496399,973.672668 
	C924.486572,971.455261 915.433044,973.647278 911.311707,978.555603 
	C910.502747,979.519043 909.680237,980.471130 908.827759,981.471008 
	C898.460510,970.257385 887.830139,970.061523 877.038513,979.668213 
	C874.761047,974.439148 870.618591,972.319641 865.003479,974.183899 
	C863.716858,974.611023 862.436218,977.055786 862.324585,978.653870 
	C861.966797,983.775574 862.158508,988.935791 862.044434,994.542297 
	C861.301758,993.700623 860.167725,992.424744 860.093384,991.089844 
	C859.853333,986.779114 860.110901,982.442993 859.961182,978.124023 
	C859.873779,975.602600 859.375427,973.095459 858.979431,969.920898 
	C865.772766,970.348816 872.896118,968.160217 880.916138,970.405518 
	C883.627014,966.317383 888.401184,970.574402 892.695007,970.113953 
	C894.652222,969.904175 896.609741,969.529236 898.565613,969.538940 
	C899.747314,969.544861 901.042175,969.938660 902.080505,970.523376 
	C903.922485,971.560669 905.607849,972.875854 907.687622,974.299988 
	C909.695984,970.419678 912.502869,971.136902 916.109009,971.557617 
	C921.854614,972.227783 927.794556,971.032104 933.641785,971.149963 
	C935.426575,971.185913 937.689453,972.534546 938.849854,973.987549 
	C942.901978,979.061707 945.405090,984.747375 943.211243,991.768677 
z"/>
<path fill="#FBF5EA" opacity="1.000000" stroke="none" 
	d="
M524.980835,963.082153 
	C525.588135,968.089172 525.870850,973.275757 526.225037,979.773315 
	C522.818176,977.513184 520.854736,975.812683 518.588013,974.774597 
	C507.856140,969.859558 495.874115,974.025330 490.615936,984.599121 
	C484.897095,996.099243 485.229340,1007.959229 490.848053,1019.168335 
	C497.119904,1031.680298 513.276001,1033.666748 523.301636,1023.849548 
	C523.630432,1023.527527 524.120056,1023.369751 524.167908,1023.342651 
	C525.847290,1025.327637 527.369873,1028.668701 528.971069,1028.706787 
	C532.572388,1028.792480 537.005066,1031.159790 540.253662,1026.541382 
	C540.807068,1026.504517 540.972168,1026.760498 541.076599,1027.055664 
	C540.131042,1028.392578 539.425415,1030.064453 538.167664,1030.965698 
	C537.065308,1031.755615 535.095581,1032.330566 533.933044,1031.887573 
	C521.400208,1027.112183 508.258209,1035.292358 495.816650,1029.982178 
	C494.213806,1029.297974 491.655701,1028.703857 491.321014,1027.551025 
	C489.381348,1020.869629 483.231812,1015.600708 485.210266,1007.470337 
	C485.921387,1004.548218 483.696747,1001.029419 483.626740,997.765442 
	C483.560394,994.671936 484.380127,991.511414 485.125549,988.457336 
	C487.425293,979.035278 499.366669,970.382019 508.990295,970.945068 
	C511.296417,971.080017 513.617126,970.965027 516.479370,970.965027 
	C516.507202,970.874390 516.768677,970.024048 517.026794,969.184753 
	C517.418823,969.184753 517.797974,969.072021 518.055420,969.201416 
	C522.743225,971.558533 524.213745,970.657776 524.068359,965.576965 
	C524.046448,964.811462 524.449707,964.033752 524.980835,963.082153 
z"/>
<path fill="#EDB289" opacity="1.000000" stroke="none" 
	d="
M607.579102,874.804688 
	C606.202454,875.687134 604.825806,876.569641 602.845093,877.670654 
	C599.032410,880.199890 595.823792,882.510681 592.615173,884.821411 
	C591.853271,885.043030 591.091431,885.264648 589.811157,885.765198 
	C587.138367,887.638306 584.984070,889.232422 582.829712,890.826599 
	C582.829712,890.826599 582.918762,890.851318 582.506348,890.832153 
	C564.706482,898.152954 547.319153,905.492920 529.931824,912.832886 
	C529.500488,912.895630 529.069153,912.958435 527.953796,913.092529 
	C523.491699,914.391785 519.713623,915.619751 515.935547,916.847656 
	C515.077820,917.003235 514.220093,917.158813 512.737976,917.571777 
	C510.362274,917.552185 508.610962,917.275208 506.859650,916.998230 
	C506.931519,916.560181 507.003418,916.122070 507.353882,915.159546 
	C509.335541,912.333008 511.038544,910.031006 512.823364,908.143799 
	C512.664795,910.152405 512.424500,911.746277 512.127808,913.714355 
	C513.696960,913.828003 515.079834,914.284241 516.241089,913.955994 
	C525.626221,911.303711 535.188538,909.075806 544.269043,905.600708 
	C555.006165,901.491821 565.568115,896.744873 575.737427,891.385437 
	C585.035522,886.485291 594.213806,881.058350 602.536560,874.678589 
	C614.485352,865.519470 625.605835,855.280212 637.088196,845.511902 
	C640.947571,842.228760 644.832886,838.976135 648.706421,835.709595 
	C648.706421,835.709595 648.873779,835.875183 648.816467,836.261597 
	C641.489990,844.061340 634.220825,851.474792 626.951660,858.888184 
	C626.288452,859.344360 625.625305,859.800659 624.532776,860.621826 
	C618.595215,865.592651 613.087158,870.198669 607.579102,874.804688 
z"/>
<path fill="#F8D4B7" opacity="1.000000" stroke="none" 
	d="
M194.031296,1000.963440 
	C195.931610,1008.319702 198.091095,1015.622559 199.625061,1023.054443 
	C200.415237,1026.882690 201.904419,1028.333008 205.885391,1028.232788 
	C214.549454,1028.014526 214.585358,1028.213867 216.849823,1019.787720 
	C218.914200,1012.106079 220.853333,1004.390625 222.961105,996.721191 
	C223.402618,995.114746 224.339691,993.644531 225.048386,992.111511 
	C225.548233,992.121948 226.048080,992.132324 226.547928,992.142700 
	C228.306793,998.235901 230.127090,1004.312256 231.803986,1010.427917 
	C233.025223,1014.881836 233.923737,1019.426819 235.223282,1023.855713 
	C236.009918,1026.536499 237.593735,1028.221680 240.952271,1028.246094 
	C249.626923,1028.308716 249.695755,1028.500610 252.068405,1020.331299 
	C253.532776,1015.289124 254.744232,1010.173462 256.401184,1004.955933 
	C255.596695,1012.240601 254.590057,1019.684509 253.205917,1027.057617 
	C252.952194,1028.409058 251.258804,1030.381104 250.066940,1030.505493 
	C245.798538,1030.951294 241.438492,1030.969116 237.162979,1030.588623 
	C236.051590,1030.489868 234.589539,1028.506348 234.194107,1027.128540 
	C231.515213,1017.794739 229.085754,1008.389526 226.552811,999.013550 
	C226.342148,998.233582 225.963501,997.498901 225.249084,995.702820 
	C224.323853,999.300049 223.665863,1001.954041 222.957367,1004.594543 
	C220.955414,1012.055847 219.141891,1019.579407 216.758896,1026.917725 
	C216.220230,1028.576416 213.720795,1029.652100 212.016586,1030.842529 
	C211.568466,1031.155518 210.719055,1030.890991 210.053955,1030.896729 
	C199.872040,1030.984253 199.212143,1032.255615 196.271225,1019.854736 
	C194.829330,1013.774780 193.813477,1007.593811 193.084503,1001.222229 
	C193.564224,1000.985901 194.031296,1000.963440 194.031296,1000.963440 
z"/>
<path fill="#EBAC7F" opacity="1.000000" stroke="none" 
	d="
M842.857971,979.305054 
	C842.857971,979.305054 842.889648,979.666199 843.072144,980.153809 
	C845.123596,983.776611 846.992615,986.911804 848.861572,990.046997 
	C848.893677,990.480957 848.925842,990.914856 848.999634,992.038330 
	C849.358276,994.151855 849.675171,995.575867 849.992188,996.999939 
	C849.964478,999.694641 849.936768,1002.389282 849.696045,1005.764526 
	C849.256165,1007.296753 849.029358,1008.148438 848.557800,1008.674133 
	C848.222168,1004.860901 848.273499,1001.362976 848.013000,997.888550 
	C847.302795,988.416870 842.551331,981.041321 834.190308,977.196167 
	C829.535156,975.055237 823.646606,975.218994 818.293274,975.065308 
	C811.026184,974.856812 805.127075,979.285950 801.704773,984.582458 
	C796.133118,993.205200 795.648010,1003.494385 799.139160,1013.218201 
	C800.566589,1017.193970 803.526978,1021.734314 807.068054,1023.628967 
	C818.059937,1029.510132 829.390991,1030.332642 839.922424,1021.795410 
	C841.027527,1020.899475 842.595215,1020.574402 843.944946,1020.332764 
	C837.111328,1030.461792 826.198853,1031.585571 815.312866,1029.297729 
	C810.532043,1028.292969 805.360657,1025.097046 802.235840,1021.340881 
	C798.743164,1017.142456 797.370667,1011.180237 795.075500,1005.985535 
	C795.091125,1003.298706 795.106750,1000.611816 795.350586,997.193176 
	C796.733215,992.316101 797.887634,988.170837 799.042114,984.025635 
	C799.042114,984.025635 799.005127,983.995789 799.321899,983.872559 
	C804.773438,976.092834 812.104736,973.305359 821.143738,972.684265 
	C829.492310,972.110474 836.233765,975.103516 842.857971,979.305054 
z"/>
<path fill="#FCF9F2" opacity="1.000000" stroke="none" 
	d="
M687.846252,529.026550 
	C686.069275,527.107178 684.338684,524.755005 682.662720,522.364441 
	C673.419434,509.179932 664.189575,495.985992 654.908325,482.457886 
	C656.793457,483.216583 659.205200,483.902832 660.561462,485.490631 
	C663.780884,489.259674 663.578796,489.432312 668.884521,486.568237 
	C669.307861,490.041077 669.673645,493.041534 670.192017,497.293671 
	C672.371704,498.005096 676.114868,498.386871 678.719421,500.310333 
	C681.389893,502.282410 683.622681,505.507294 684.810120,508.651093 
	C686.135681,512.160339 684.638123,516.270081 688.274902,519.651367 
	C689.694519,520.971252 688.125549,525.505676 687.846252,529.026550 
z"/>
<path fill="#EDB289" opacity="1.000000" stroke="none" 
	d="
M432.860229,408.134338 
	C432.451141,408.082062 432.042023,408.029785 431.128601,407.692017 
	C424.722656,404.018738 418.648773,400.886749 413.018921,397.095398 
	C411.207550,395.875580 410.566437,392.917938 409.389984,390.755280 
	C411.285583,390.139160 413.178864,388.996796 415.077148,388.988434 
	C433.715057,388.906097 452.353821,389.012451 470.992340,389.065247 
	C471.421387,389.106659 471.850433,389.148071 472.920380,389.429993 
	C487.206329,391.842499 500.885956,393.824524 514.476562,396.295776 
	C518.869324,397.094574 523.028381,399.178009 526.854858,400.790955 
	C519.802856,399.687531 513.213684,398.317657 506.572906,397.277374 
	C492.580902,395.085541 478.622498,392.157501 464.537811,391.217651 
	C448.661102,390.158295 432.660736,390.943909 416.714142,390.956635 
	C415.304565,390.957733 413.895172,391.177185 411.181824,391.404419 
	C413.016815,393.496765 413.875946,395.137451 415.229919,395.902832 
	C420.386230,398.817749 425.759583,401.348877 430.912262,404.269714 
	C432.001709,404.887329 432.501923,406.544403 433.170654,407.825806 
	C433.067627,407.929108 432.860229,408.134338 432.860229,408.134338 
z"/>
<path fill="#FCF9F2" opacity="1.000000" stroke="none" 
	d="
M712.273376,719.932983 
	C713.413330,711.006653 715.125549,702.052429 716.395020,693.035767 
	C718.901062,675.236633 720.008667,657.372131 718.183350,639.408386 
	C717.831055,635.941284 717.974243,632.423950 717.923401,628.466309 
	C719.256836,631.842041 721.386658,635.618591 721.682068,639.533569 
	C722.299744,647.718750 722.247192,655.989197 721.910522,664.201660 
	C721.599426,671.791626 720.406738,679.341675 719.905823,686.929382 
	C719.673218,690.452698 720.128113,694.025391 720.336853,697.571899 
	C720.423096,699.036682 721.256836,700.731445 720.764832,701.910217 
	C718.234680,707.972717 715.384155,713.901428 712.273376,719.932983 
z"/>
<path fill="#FCF9F2" opacity="1.000000" stroke="none" 
	d="
M627.140503,859.154785 
	C634.220825,851.474792 641.489990,844.061340 648.900146,836.344360 
	C653.681946,830.679871 658.322205,825.318604 662.963867,819.958557 
	C664.043640,818.711548 665.127991,817.468628 666.495422,816.063171 
	C666.881287,818.045410 666.981873,820.188293 667.057007,821.787476 
	C660.781067,824.890869 657.098022,829.091309 655.985046,835.430481 
	C655.444092,838.511475 654.869507,841.619202 653.910461,844.585632 
	C652.796509,848.031372 650.184082,849.447449 646.526306,848.937378 
	C640.161560,848.049744 640.166443,848.077759 640.929260,854.763977 
	C641.003296,855.413147 640.933167,856.094849 640.818176,856.741638 
	C640.771179,857.005920 640.426819,857.217346 639.366943,858.411133 
	C638.542480,856.740540 637.755981,855.146973 637.103271,853.824402 
	C633.254700,856.028259 630.291992,857.724854 627.140503,859.154785 
z"/>
<path fill="#FCF9F2" opacity="1.000000" stroke="none" 
	d="
M669.229065,812.925171 
	C673.130188,806.094543 677.702209,799.418274 681.624634,792.379944 
	C687.387390,782.039368 692.686218,771.440247 698.506592,760.795654 
	C699.145508,763.663940 699.579590,766.682678 699.708679,769.714478 
	C699.751831,770.728882 699.319763,772.278320 698.584534,772.723083 
	C695.171204,774.787781 694.833923,777.888550 694.918518,781.379517 
	C694.943115,782.393860 694.315186,784.178711 693.685974,784.325867 
	C690.697266,785.024719 689.963257,788.740845 688.659912,789.313965 
	C683.368164,791.640564 683.700439,796.869629 681.155396,800.437744 
	C676.007080,807.655762 675.594971,807.361816 684.570801,810.175354 
	C683.098816,811.613525 682.107727,813.126648 680.729980,813.684143 
	C680.098755,813.939575 678.807922,812.022461 677.634460,811.618896 
	C676.200500,811.125732 674.532532,810.888123 673.047058,811.102783 
	C671.838989,811.277405 670.750854,812.281433 669.229065,812.925171 
z"/>
<path fill="#FCF9F2" opacity="1.000000" stroke="none" 
	d="
M470.946411,388.666382 
	C452.353821,389.012451 433.715057,388.906097 415.077148,388.988434 
	C413.178864,388.996796 411.285583,390.139160 409.389984,390.755280 
	C410.566437,392.917938 411.207550,395.875580 413.018921,397.095398 
	C418.648773,400.886749 424.722656,404.018738 430.819336,407.685364 
	C426.439087,410.788727 424.608337,405.469543 421.242493,404.580383 
	C418.642120,403.893402 416.281281,402.260162 413.854156,400.971863 
	C412.444946,400.223877 410.865601,399.564392 409.800171,398.447784 
	C407.396667,395.928802 404.693695,393.190277 406.542023,389.281464 
	C408.273651,385.619415 411.828827,385.868164 415.219208,385.988678 
	C425.484528,386.353607 435.750458,386.863037 446.018616,386.928314 
	C452.676331,386.970673 459.336975,386.174713 465.999420,386.117035 
	C467.626770,386.102936 469.266449,387.512543 470.946411,388.666382 
z"/>
<path fill="#F4995C" opacity="1.000000" stroke="none" 
	d="
M279.001221,1012.002563 
	C279.438934,1012.050293 279.876617,1012.098083 280.871216,1012.369751 
	C287.294159,1014.685974 289.596893,1018.154297 288.208038,1022.805664 
	C286.865692,1027.301392 282.494385,1029.806641 277.621094,1028.873047 
	C273.103973,1028.007690 270.439911,1024.062866 271.080048,1019.213196 
	C271.692352,1014.573914 275.357971,1013.304077 279.001221,1012.002563 
z"/>
<path fill="#FCF9F2" opacity="1.000000" stroke="none" 
	d="
M711.828491,590.034668 
	C711.225952,589.907898 710.455139,589.398560 710.198730,588.700378 
	C704.253479,572.506531 698.424438,556.269775 692.409058,540.102295 
	C691.318726,537.171997 689.507080,534.510132 688.001099,531.364136 
	C689.756287,531.003906 691.538757,531.003906 693.355103,531.003906 
	C693.951172,534.609558 693.918945,537.904358 695.129517,540.651306 
	C696.125549,542.911560 698.596069,544.521912 700.958923,546.990601 
	C697.656616,551.851501 703.599365,555.307129 705.529846,560.001038 
	C708.327576,566.803345 712.755249,573.151001 711.244629,581.255310 
	C710.753052,583.892517 711.642273,586.787231 711.828491,590.034668 
z"/>
<path fill="#FCF7EE" opacity="1.000000" stroke="none" 
	d="
M848.802490,1009.000183 
	C849.029358,1008.148438 849.256165,1007.296753 849.716492,1006.213013 
	C852.005798,1009.891602 850.886292,1017.237488 847.879639,1020.075500 
	C846.933716,1020.968384 846.064575,1021.986145 845.346802,1023.070251 
	C840.568970,1030.285767 833.987305,1033.115112 825.362244,1032.102661 
	C820.854675,1031.573486 816.178467,1032.426514 811.687439,1031.831909 
	C809.216003,1031.504761 806.323730,1030.007812 804.744263,1028.105591 
	C799.961365,1022.345215 795.726624,1016.129700 791.214172,1010.004517 
	C792.388062,1008.551086 793.384521,1007.317383 794.728271,1006.034607 
	C797.370667,1011.180237 798.743164,1017.142456 802.235840,1021.340881 
	C805.360657,1025.097046 810.532043,1028.292969 815.312866,1029.297729 
	C826.198853,1031.585571 837.111328,1030.461792 844.044312,1020.181641 
	C844.147034,1019.682068 844.234558,1019.331299 844.558105,1019.208740 
	C846.111206,1016.349731 847.340759,1013.613281 848.647827,1010.570068 
	C848.751099,1009.842285 848.776794,1009.421265 848.802490,1009.000183 
z"/>
<path fill="#EDB289" opacity="1.000000" stroke="none" 
	d="
M513.961121,449.092072 
	C501.023560,447.611084 489.506470,442.544495 478.881531,435.213898 
	C478.207092,434.748566 477.455994,434.394348 476.389404,433.940125 
	C475.566711,433.583588 475.095154,433.275330 474.166504,432.640686 
	C468.142914,429.080536 462.599152,425.806976 457.004578,422.622589 
	C449.012787,418.073761 440.983826,413.590179 432.970703,409.078735 
	C432.970703,409.078735 432.917938,408.605804 432.889099,408.370056 
	C432.860229,408.134338 433.067627,407.929108 433.533203,407.908020 
	C436.164032,408.635498 438.518616,409.060486 440.458038,410.195099 
	C445.656494,413.236176 450.556946,416.793030 455.792206,419.763611 
	C468.806946,427.148529 481.904907,434.389832 495.063232,441.515686 
	C497.431885,442.798431 500.386658,442.970490 502.825104,444.159363 
	C506.483124,445.942810 511.765991,444.080750 513.961121,449.092072 
z"/>
<path fill="#FBF5EA" opacity="1.000000" stroke="none" 
	d="
M429.740906,1005.237732 
	C431.498413,1011.250366 432.931580,1017.419250 437.701111,1022.343079 
	C449.788727,1034.821655 471.572571,1031.804565 479.635040,1016.184082 
	C483.052826,1019.477356 482.416107,1021.715637 478.090393,1024.979248 
	C469.370300,1031.558350 459.837036,1032.986816 449.219727,1031.131470 
	C442.868683,1030.021606 437.668304,1027.833252 433.973938,1022.468140 
	C433.433197,1021.682800 432.640076,1020.427856 432.002808,1020.451355 
	C425.238831,1020.700317 428.188263,1015.273193 427.620697,1012.409851 
	C427.203705,1010.306274 428.768066,1007.810059 429.740906,1005.237732 
z"/>
<path fill="#FBF5EA" opacity="1.000000" stroke="none" 
	d="
M479.402649,985.922485 
	C477.590210,977.615051 470.838745,974.567261 464.081726,973.481445 
	C450.032166,971.223877 438.577820,973.971741 432.041321,990.040344 
	C430.881409,992.891785 430.734100,996.155029 430.010498,999.620239 
	C427.505676,996.135071 428.636047,991.854858 429.916229,988.346802 
	C433.261353,979.180054 441.029114,973.761353 449.628296,970.641602 
	C454.006500,969.053284 459.638733,971.305664 464.637787,970.932739 
	C469.309052,970.584290 472.908325,971.732605 476.727692,974.412537 
	C481.162842,977.524597 482.374084,980.313416 479.402649,985.922485 
z"/>
<path fill="#FCF9F2" opacity="1.000000" stroke="none" 
	d="
M530.166504,913.140869 
	C547.319153,905.492920 564.706482,898.152954 582.457703,890.812256 
	C581.586792,895.877869 577.559692,897.468201 573.143616,898.527527 
	C572.385132,898.709534 571.445740,898.380615 570.788635,898.698486 
	C568.464600,899.822632 566.226929,901.125061 563.679504,902.510620 
	C563.978882,903.888367 564.465149,906.126404 565.141174,909.237610 
	C560.970947,907.576111 558.889832,902.575500 554.187073,905.727783 
	C555.487061,907.182190 556.636475,908.468201 558.539490,910.597290 
	C554.956421,910.597290 552.313843,910.333374 549.742432,910.649109 
	C543.278259,911.442810 536.846130,912.498352 530.166504,913.140869 
z"/>
<path fill="#FCF9F2" opacity="1.000000" stroke="none" 
	d="
M527.294373,400.673798 
	C523.028381,399.178009 518.869324,397.094574 514.476562,396.295776 
	C500.885956,393.824524 487.206329,391.842499 473.243927,389.424072 
	C476.933594,388.946167 480.939087,388.571106 484.948090,388.529083 
	C488.118561,388.495850 491.881439,391.085510 493.597351,385.852173 
	C493.802338,385.226959 496.299835,385.068420 497.693817,385.184540 
	C499.812073,385.360992 501.894562,385.967316 504.346680,386.462646 
	C503.518005,392.128662 506.992310,393.008728 511.794891,393.082550 
	C518.244568,393.181702 524.623657,394.018372 529.025513,400.056244 
	C528.899170,400.442017 528.737793,400.516968 528.134399,400.651459 
	C527.692505,400.710999 527.294373,400.673798 527.294373,400.673798 
z"/>
<path fill="#FBF5EA" opacity="1.000000" stroke="none" 
	d="
M510.998779,834.000000 
	C511.002777,836.037842 511.006744,838.075684 511.007751,840.996155 
	C511.012482,844.257080 511.020233,846.635376 511.027954,849.013672 
	C510.683105,857.040405 510.338318,865.067139 509.612793,873.594971 
	C508.485626,876.393311 507.739136,878.690552 506.992676,880.987793 
	C506.324432,882.716187 505.656158,884.444580 504.649597,886.633423 
	C503.541290,888.395996 502.771240,889.697998 502.001221,891.000000 
	C501.024353,892.768372 500.047485,894.536682 498.721558,896.657654 
	C497.960632,897.051880 497.548828,897.093445 497.137024,897.135132 
	C497.137024,897.135132 497.081665,897.079956 497.074097,896.675903 
	C497.399384,895.360168 497.631531,894.395325 498.079865,893.544434 
	C507.683258,875.317932 511.464600,856.049377 508.088623,835.627869 
	C507.214172,830.338135 505.451965,825.195068 504.436829,820.022278 
	C506.846954,821.378723 508.917755,822.697510 510.988586,824.016357 
	C510.988403,824.454285 510.988220,824.892151 510.621979,825.735291 
	C507.884460,829.007874 508.884613,831.556580 510.998779,834.000000 
z"/>
<path fill="#FCF7EE" opacity="1.000000" stroke="none" 
	d="
M842.881592,978.927124 
	C836.233765,975.103516 829.492310,972.110474 821.143738,972.684265 
	C812.104736,973.305359 804.773438,976.092834 799.333130,983.892944 
	C799.584961,974.579895 809.689392,967.971252 818.777832,969.809998 
	C823.872009,970.840698 829.493591,969.352417 834.866455,968.874512 
	C836.938965,968.690247 838.980774,968.161072 841.464355,967.711609 
	C839.300171,972.189514 839.300171,972.189514 842.881592,978.927124 
z"/>
<path fill="#FBF5EA" opacity="1.000000" stroke="none" 
	d="
M278.941650,1011.646301 
	C275.357971,1013.304077 271.692352,1014.573914 271.080048,1019.213196 
	C270.439911,1024.062866 273.103973,1028.007690 277.621094,1028.873047 
	C282.494385,1029.806641 286.865692,1027.301392 288.208038,1022.805664 
	C289.596893,1018.154297 287.294159,1014.685974 281.200439,1012.332764 
	C283.362915,1011.785706 287.490204,1010.637878 287.848175,1011.359680 
	C289.574738,1014.840759 292.085083,1019.461487 291.008026,1022.507996 
	C288.183350,1030.497559 282.012573,1033.388550 274.296021,1030.625610 
	C271.848846,1029.749268 270.757629,1024.947510 269.163849,1021.880859 
	C268.282440,1020.184937 267.645233,1018.362122 266.509003,1015.677612 
	C267.250092,1015.213562 268.669556,1014.324707 270.495270,1013.181396 
	C270.440338,1012.245361 270.360626,1010.887207 270.254730,1009.082336 
	C273.426086,1009.893860 276.154083,1010.591919 278.941650,1011.646301 
z"/>
<path fill="#F8D4B7" opacity="1.000000" stroke="none" 
	d="
M216.988312,986.002258 
	C216.682571,986.779663 216.376831,987.557007 215.776627,988.897156 
	C215.325714,990.645447 215.169250,991.830872 215.012802,993.016357 
	C214.595825,993.580261 214.178848,994.144165 213.545807,994.861389 
	C214.163101,990.539001 214.978378,986.059753 215.850220,981.591553 
	C215.974030,980.957031 216.430283,980.394897 216.634979,979.767395 
	C219.073593,972.292480 222.227112,970.211365 229.722809,972.398132 
	C231.812714,973.007874 234.024673,975.783142 234.749039,978.027039 
	C237.399048,986.235901 239.445129,994.639709 241.718643,1002.970093 
	C242.248474,1002.974182 242.778320,1002.978333 243.308151,1002.982422 
	C244.160461,1001.014038 245.012772,999.045593 246.160385,996.923096 
	C245.910690,1000.765076 245.477859,1004.783936 244.676636,1008.727905 
	C244.578110,1009.212952 242.511154,1009.298035 241.193939,1008.972107 
	C240.770096,1007.865417 240.508682,1007.355164 240.247253,1006.844849 
	C240.192307,1006.437622 240.137375,1006.030334 240.046982,1004.928833 
	C239.333710,1002.150635 238.655899,1000.066772 237.978088,997.982910 
	C237.623779,996.598267 237.269485,995.213562 236.874969,993.087158 
	C235.500778,987.570679 234.371735,982.723145 232.718704,978.061523 
	C232.160049,976.485962 230.460037,974.530823 228.964066,974.204346 
	C221.513077,972.578369 219.760788,973.846802 217.992447,981.168152 
	C217.606277,982.767090 217.320389,984.390259 216.988312,986.002258 
z"/>
<path fill="#FCF9F2" opacity="1.000000" stroke="none" 
	d="
M621.091675,448.786865 
	C616.078369,445.476379 611.346863,441.667725 606.331665,438.279083 
	C598.965698,433.302094 591.396851,428.625244 583.763184,423.500793 
	C588.619995,424.597351 593.628113,426.013580 598.636230,427.429810 
	C599.945251,435.134979 608.507019,434.812683 612.315430,439.780304 
	C614.251465,442.305603 616.488770,443.804718 620.029297,442.293152 
	C621.289001,441.755371 623.179260,442.694397 625.746765,443.118805 
	C623.726990,445.523468 622.486816,446.999969 621.091675,448.786865 
z"/>
<path fill="#FBF5EA" opacity="1.000000" stroke="none" 
	d="
M247.726013,990.950317 
	C247.595856,991.528381 247.465698,992.106384 247.063599,992.826416 
	C246.093994,990.416260 244.128204,987.342468 244.925201,985.405273 
	C247.880127,978.222595 248.141647,977.732727 242.259399,972.729309 
	C245.732391,971.727966 248.546814,970.493347 251.457230,970.194824 
	C254.444656,969.888428 257.602142,971.007874 260.545715,970.568665 
	C263.820282,970.079956 265.828918,971.250061 266.702972,973.943298 
	C267.614227,976.751099 267.237274,979.584229 263.514862,980.590576 
	C262.995514,978.002686 262.832611,975.747620 262.669739,973.492493 
	C259.086517,973.906494 255.503311,974.320496 251.920105,974.734497 
	C251.920105,974.734497 251.844360,974.844177 251.450684,974.959839 
	C250.325928,978.376160 249.594879,981.676697 248.863831,984.977295 
	C248.816727,985.699219 248.769638,986.421143 248.357437,987.594727 
	C247.903564,989.014404 247.814789,989.982361 247.726013,990.950317 
z"/>
<path fill="#FCF9F2" opacity="1.000000" stroke="none" 
	d="
M432.984558,409.459198 
	C440.983826,413.590179 449.012787,418.073761 457.004578,422.622589 
	C462.599152,425.806976 468.142914,429.080536 473.875854,432.609528 
	C470.945618,432.320587 467.701202,432.126556 464.799255,431.027985 
	C462.503723,430.158905 460.707306,428.052155 458.551971,426.716583 
	C452.374298,422.888428 445.991943,419.372925 439.973053,415.314819 
	C437.562073,413.689240 432.696350,414.926208 432.984558,409.459198 
z"/>
<path fill="#EBAC7F" opacity="1.000000" stroke="none" 
	d="
M723.537109,1012.135254 
	C729.939331,1016.851562 730.711365,1020.668823 726.921387,1027.461426 
	C726.851135,1027.828369 726.879700,1027.852051 726.550476,1027.899658 
	C724.792969,1028.574585 723.364685,1029.201782 721.936462,1029.829102 
	C721.205750,1029.842407 720.475037,1029.855835 719.379578,1029.889404 
	C711.021484,1026.634766 709.601318,1023.787537 712.083496,1015.463257 
	C712.997437,1014.541565 713.834229,1014.014771 715.133118,1013.231140 
	C716.063477,1012.648193 716.531738,1012.321960 717.000000,1011.995850 
	C719.034119,1012.032471 721.068237,1012.069092 723.537109,1012.135254 
M717.514771,1014.937561 
	C712.501099,1018.301086 711.096008,1022.458374 714.638672,1024.588013 
	C716.976868,1025.993652 720.669189,1026.791016 723.061829,1025.931152 
	C724.907898,1025.267700 727.074463,1021.280029 726.625183,1019.344604 
	C725.753662,1015.590576 722.149963,1014.069824 717.514771,1014.937561 
z"/>
<path fill="#FCF7EE" opacity="1.000000" stroke="none" 
	d="
M541.442871,1026.963379 
	C540.972168,1026.760498 540.807068,1026.504517 540.389282,1026.166260 
	C540.134460,1017.991943 540.071655,1009.939087 540.132874,1001.427490 
	C541.494385,1001.966797 543.081604,1002.744446 543.883667,1004.016907 
	C544.961853,1005.727661 545.434143,1007.820374 545.947144,1009.171448 
	C545.243835,1011.731262 544.538879,1013.980591 544.016663,1016.271606 
	C543.214783,1019.789734 542.538574,1023.336487 541.442871,1026.963379 
z"/>
<path fill="#FCF9F2" opacity="1.000000" stroke="none" 
	d="
M718.184937,625.788696 
	C716.261353,617.737915 714.661194,609.415833 713.043396,601.097229 
	C712.700073,599.331787 712.292175,597.578857 711.934326,595.412903 
	C712.963135,596.691833 714.004517,598.359619 714.969360,600.070496 
	C715.683777,601.337280 716.298706,602.660156 717.437744,604.902588 
	C718.617371,602.666443 719.221069,601.522034 719.824768,600.377625 
	C720.330933,600.518799 720.837097,600.659912 721.343262,600.801086 
	C720.946655,602.493835 721.052612,604.620789 720.045654,605.786194 
	C718.120117,608.014954 718.614319,609.861084 718.981873,612.493042 
	C719.573547,616.729919 718.735901,621.166443 718.184937,625.788696 
z"/>
<path fill="#FCF9F2" opacity="1.000000" stroke="none" 
	d="
M703.494141,747.957397 
	C705.832397,739.066040 708.537964,730.120605 711.551147,720.943909 
	C711.858765,728.874634 711.858765,737.036499 711.858765,745.007324 
	C708.978394,746.050354 706.419922,746.976807 703.494141,747.957397 
z"/>
<path fill="#EDB289" opacity="1.000000" stroke="none" 
	d="
M523.988525,877.392639 
	C524.496765,875.794312 525.018921,874.585144 526.076599,873.452942 
	C526.040344,884.979614 519.971313,894.625122 516.156860,905.002991 
	C515.815063,905.933044 514.062500,906.344482 512.931763,906.662354 
	C512.896729,905.880188 512.895020,905.432312 512.893311,904.984497 
	C513.009460,904.353760 513.125610,903.722961 513.548706,902.622437 
	C515.238220,899.435120 516.620789,896.717590 518.003296,894.000000 
	C518.416626,892.714478 518.830017,891.428955 519.555420,889.653687 
	C520.294312,888.130005 520.721130,887.096069 521.148010,886.062073 
	C522.099487,883.302002 523.050903,880.541870 523.988525,877.392639 
z"/>
<path fill="#FCF9F2" opacity="1.000000" stroke="none" 
	d="
M654.888794,481.760681 
	C652.560669,479.883362 650.183899,477.684723 647.935974,475.361389 
	C645.546570,472.891754 643.279297,470.303986 640.879089,467.431244 
	C643.943909,466.419952 647.088318,465.745239 651.243469,464.853638 
	C650.198853,466.607910 649.659363,467.486084 649.146851,468.379700 
	C647.615540,471.049805 649.169556,474.164581 652.135437,473.713379 
	C655.894043,473.141602 656.601257,473.704285 654.655945,477.167236 
	C654.074951,478.201477 654.780701,479.958527 654.888794,481.760681 
z"/>
<path fill="#FCF7EE" opacity="1.000000" stroke="none" 
	d="
M539.046265,951.869629 
	C538.576233,951.850525 538.106140,951.831482 537.175293,951.336487 
	C533.036133,950.263489 529.140198,947.893494 526.059448,952.167725 
	C525.864563,954.866394 525.669678,957.565063 525.384155,960.650085 
	C520.146240,956.582336 519.922424,955.307922 523.559204,949.986145 
	C524.078613,949.226135 524.899902,948.253662 525.677002,948.158264 
	C529.884644,947.641785 534.120117,947.339050 538.350647,947.025513 
	C541.223511,946.812500 540.654663,948.554077 539.844482,950.708740 
	C539.334167,951.336304 539.148438,951.569641 539.046265,951.869629 
z"/>
<path fill="#FBF5EA" opacity="1.000000" stroke="none" 
	d="
M257.254028,1000.953674 
	C257.287567,1000.551697 257.321045,1000.149658 257.726105,999.320129 
	C259.339294,995.953674 260.580933,993.014709 261.822571,990.075806 
	C263.427155,990.346741 265.197662,990.293030 266.579651,990.999451 
	C267.547913,991.494324 268.301300,992.970764 268.505676,994.123169 
	C268.587585,994.585144 266.860962,995.270142 266.097534,996.000000 
	C264.547455,997.481812 263.081512,999.052734 261.601959,1000.606934 
	C260.255127,1002.021790 258.936157,1003.463135 257.253479,1004.916504 
	C256.915314,1004.208557 256.928650,1003.477051 257.157410,1002.301086 
	C257.440948,1001.757996 257.585358,1001.634949 257.563049,1001.565247 
	C257.494232,1001.350464 257.361755,1001.156006 257.254028,1000.953674 
z"/>
<path fill="#FBF5EA" opacity="1.000000" stroke="none" 
	d="
M479.010010,1012.578979 
	C476.151550,1011.724487 473.297668,1010.473938 471.497253,1009.684937 
	C467.194305,1012.657532 463.446350,1015.246643 459.331238,1017.752930 
	C459.776703,1014.818665 460.254639,1011.734253 464.307526,1011.662292 
	C464.953583,1011.650879 465.651703,1010.765686 466.208832,1010.174377 
	C469.516418,1006.663513 475.778900,1006.646484 478.745483,1010.250244 
	C479.097839,1010.678223 478.937317,1011.528320 479.010010,1012.578979 
z"/>
<path fill="#FCF9F2" opacity="1.000000" stroke="none" 
	d="
M608.001343,874.863037 
	C613.087158,870.198669 618.595215,865.592651 624.429932,860.924988 
	C624.456482,862.450195 624.713318,864.676270 623.756836,865.509399 
	C620.255432,868.559021 616.417603,871.245361 612.554504,873.842407 
	C611.439575,874.591858 609.814697,874.582764 608.001343,874.863037 
z"/>
<path fill="#FBF5EA" opacity="1.000000" stroke="none" 
	d="
M454.974731,1017.338135 
	C450.134949,1017.290161 446.507782,1014.635986 445.954102,1009.963623 
	C445.290253,1004.361816 445.952240,998.602783 446.394287,992.688110 
	C446.835724,997.193115 446.639648,1001.952515 447.147003,1006.635681 
	C447.355499,1008.560547 448.893311,1010.464417 450.164368,1012.118042 
	C451.541809,1013.909973 453.312958,1015.399292 454.974731,1017.338135 
z"/>
<path fill="#FCF9F2" opacity="1.000000" stroke="none" 
	d="
M582.960327,422.738342 
	C576.785400,419.954681 570.585754,416.812134 564.157043,413.423035 
	C564.450562,412.814423 565.138489,412.024902 565.471375,412.153595 
	C571.838196,414.615479 579.153198,415.507416 582.960327,422.738342 
z"/>
<path fill="#FCF9F2" opacity="1.000000" stroke="none" 
	d="
M593.091858,884.877197 
	C595.823792,882.510681 599.032410,880.199890 602.607422,877.880127 
	C603.306702,880.380554 603.639465,882.889893 603.989746,885.531067 
	C600.055908,885.305298 596.812195,885.119141 593.091858,884.877197 
z"/>
<path fill="#F8D4B7" opacity="1.000000" stroke="none" 
	d="
M205.051498,1000.946777 
	C204.983505,999.578979 204.915543,998.211121 204.908066,996.437256 
	C206.278671,998.198853 207.588791,1000.366516 208.988510,1002.682495 
	C209.764694,1001.853394 210.575241,1000.987671 211.736145,1000.095459 
	C211.763611,1002.122253 211.440704,1004.175537 211.016602,1006.839233 
	C210.915405,1007.449768 210.903732,1007.908020 210.903732,1007.908020 
	C210.820068,1008.232605 210.668030,1008.521118 209.878082,1008.913940 
	C208.557846,1009.020996 207.807144,1008.987793 207.056442,1008.954590 
	C207.056442,1008.954590 206.996704,1008.500854 206.983063,1007.986328 
	C206.933655,1007.283997 206.897919,1007.096130 206.862167,1006.908264 
	C206.535858,1005.549072 206.209534,1004.189880 205.829330,1002.225830 
	C205.534119,1001.396240 205.292816,1001.171509 205.051498,1000.946777 
z"/>
<path fill="#FCF9F2" opacity="1.000000" stroke="none" 
	d="
M556.943848,409.732178 
	C551.132812,407.955505 545.277161,405.818878 539.174194,403.428711 
	C541.338379,402.501312 543.749939,401.827454 545.118896,401.444916 
	C549.572876,404.442108 553.236084,406.907135 556.943848,409.732178 
z"/>
<path fill="#FBF5EA" opacity="1.000000" stroke="none" 
	d="
M447.349762,990.968323 
	C449.963257,984.483765 458.061890,982.566528 464.195587,987.308533 
	C462.508392,987.605408 460.639526,987.156616 458.972473,987.554077 
	C455.167053,988.461365 451.460510,989.783264 447.349762,990.968323 
z"/>
<path fill="#FBF5EA" opacity="1.000000" stroke="none" 
	d="
M464.246643,988.062317 
	C468.915741,992.841797 473.153412,990.781189 477.662598,987.066528 
	C477.894165,991.402771 474.320679,992.943176 471.281860,993.762024 
	C467.517395,994.776428 465.512726,991.623169 464.246643,988.062317 
z"/>
<path fill="#FFFFFF" opacity="1.000000" stroke="none" 
	d="
M477.038635,745.012268 
	C476.988495,744.562866 476.938354,744.113464 477.211578,743.336243 
	C477.534943,743.008423 478.027863,742.997070 478.027863,742.997070 
	C479.912903,743.555176 482.541168,743.531982 483.527466,744.793030 
	C485.551178,747.380371 483.353180,749.690430 478.435486,749.612732 
	C477.688416,747.821899 477.363525,746.417114 477.038635,745.012268 
z"/>
<path fill="#FCF9F2" opacity="1.000000" stroke="none" 
	d="
M496.890625,915.251282 
	C495.880402,913.782043 494.948120,912.685303 494.015869,911.588562 
	C494.116821,911.109070 494.217743,910.629639 494.318695,910.150146 
	C496.283691,910.423401 498.843628,910.066528 500.018951,911.176147 
	C500.904907,912.012573 500.599548,915.044128 499.788116,916.534485 
	C498.444427,919.002380 497.307495,917.888123 496.890625,915.251282 
z"/>
<path fill="#F8D4B7" opacity="1.000000" stroke="none" 
	d="
M252.376648,974.819580 
	C255.503311,974.320496 259.086517,973.906494 262.669739,973.492493 
	C262.832611,975.747620 262.995514,978.002686 263.159058,980.654419 
	C262.906830,981.617310 262.653931,982.183716 262.111084,982.876953 
	C261.710114,982.883667 261.505859,982.766846 261.501312,982.642761 
	C261.214752,974.866577 261.216248,974.866516 252.376648,974.819580 
z"/>
<path fill="#FCF7EE" opacity="1.000000" stroke="none" 
	d="
M849.212769,989.870361 
	C846.992615,986.911804 845.123596,983.776611 843.153564,980.315735 
	C850.015503,980.845215 852.448303,984.470764 849.212769,989.870361 
z"/>
<path fill="#FCF9F2" opacity="1.000000" stroke="none" 
	d="
M516.178894,917.154175 
	C519.713623,915.619751 523.491699,914.391785 527.642395,913.194763 
	C527.574951,914.830566 527.134888,916.435425 526.572021,918.488220 
	C523.664856,918.193848 520.043579,917.827209 516.178894,917.154175 
z"/>
<path fill="#FCF9F2" opacity="1.000000" stroke="none" 
	d="
M512.523987,904.995117 
	C512.895020,905.432312 512.896729,905.880188 512.866211,906.842407 
	C512.834045,907.356812 512.741516,907.729004 512.741577,907.729004 
	C511.038544,910.031006 509.335541,912.333008 507.364349,914.826477 
	C505.789185,913.732788 506.478455,908.436157 508.358307,906.926453 
	C509.440186,906.057617 510.877350,905.631226 512.523987,904.995117 
z"/>
<path fill="#FCF7EE" opacity="1.000000" stroke="none" 
	d="
M798.683899,984.049561 
	C797.887634,988.170837 796.733215,992.316101 795.327576,996.746033 
	C791.858154,989.901306 792.058289,989.103088 798.683899,984.049561 
z"/>
<path fill="#FCF9F2" opacity="1.000000" stroke="none" 
	d="
M197.011292,609.009033 
	C196.566940,609.006042 196.122604,609.003113 195.345215,609.000488 
	C194.474060,607.766113 193.168777,605.800781 193.529510,605.422058 
	C195.166672,603.703064 197.273499,602.431335 199.598541,600.992004 
	C199.985764,600.985352 199.997467,600.997498 199.855881,601.322083 
	C198.812576,604.101074 197.910858,606.555542 197.004730,609.005371 
	C197.000305,609.000854 197.011292,609.009033 197.011292,609.009033 
z"/>
<path fill="#FCF9F2" opacity="1.000000" stroke="none" 
	d="
M506.557129,917.050781 
	C508.610962,917.275208 510.362274,917.552185 512.509155,917.809631 
	C512.814941,919.769470 512.725159,921.748901 512.635376,923.728333 
	C512.174011,923.902161 511.712708,924.075989 511.251373,924.249878 
	C509.585785,921.867737 507.920166,919.485596 506.557129,917.050781 
z"/>
<path fill="#F8D4B7" opacity="1.000000" stroke="none" 
	d="
M261.830170,989.815552 
	C260.580933,993.014709 259.339294,995.953674 257.803223,999.027588 
	C258.666260,994.439941 259.823761,989.717346 261.302612,984.843079 
	C261.733948,985.199463 261.843903,985.707520 261.751038,986.748535 
	C261.643951,987.865845 261.739716,988.450195 261.835480,989.034546 
	C261.836243,989.208130 261.836975,989.381714 261.830170,989.815552 
z"/>
<path fill="#FCF7EE" opacity="1.000000" stroke="none" 
	d="
M849.247986,972.182617 
	C855.099243,973.791016 852.111267,976.224670 850.235901,978.796204 
	C846.206848,977.180786 847.137695,974.812744 849.247986,972.182617 
z"/>
<path fill="#FCF9F2" opacity="1.000000" stroke="none" 
	d="
M478.020569,742.559814 
	C478.027863,742.997070 477.534943,743.008423 477.288574,743.003784 
	C476.883179,738.525269 476.724213,734.051453 476.804565,729.296265 
	C477.368164,731.064514 477.692413,733.114197 478.026764,735.580688 
	C478.029022,738.039246 478.021149,740.080872 478.020569,742.559814 
z"/>
<path fill="#FCF7EE" opacity="1.000000" stroke="none" 
	d="
M883.974426,1023.332520 
	C883.375732,1019.450073 883.170044,1015.577698 882.964355,1011.705383 
	C883.660889,1011.714050 884.357483,1011.722656 885.054077,1011.731323 
	C884.825195,1015.601807 884.596313,1019.472229 883.974426,1023.332520 
z"/>
<path fill="#FCF9F2" opacity="1.000000" stroke="none" 
	d="
M477.581543,443.417603 
	C477.016052,444.709412 476.273895,445.683167 475.531738,446.656921 
	C474.205841,445.301544 472.879944,443.946136 471.554077,442.590729 
	C471.933807,442.053314 472.313568,441.515900 472.693329,440.978485 
	C474.263855,441.685516 475.834381,442.392517 477.581543,443.417603 
z"/>
<path fill="#FCF9F2" opacity="1.000000" stroke="none" 
	d="
M517.615234,894.003418 
	C516.620789,896.717590 515.238220,899.435120 513.531006,902.300537 
	C513.091003,898.941223 511.779602,894.864319 517.615234,894.003418 
z"/>
<path fill="#FCF9F2" opacity="1.000000" stroke="none" 
	d="
M526.296143,391.983124 
	C524.753113,391.271027 523.448303,390.279968 522.143494,389.288940 
	C522.928345,388.569122 523.713196,387.849274 524.498047,387.129456 
	C525.176819,388.654358 525.855652,390.179260 526.296143,391.983124 
z"/>
<path fill="#FCF9F2" opacity="1.000000" stroke="none" 
	d="
M699.285156,759.979370 
	C699.717407,757.465515 700.557190,754.953918 701.648254,752.199341 
	C703.262329,755.208496 704.991760,758.561523 699.285156,759.979370 
z"/>
<path fill="#FCF9F2" opacity="1.000000" stroke="none" 
	d="
M287.571228,659.356628 
	C286.111694,662.421509 285.008636,662.031555 282.970978,656.763184 
	C284.447845,657.524475 285.924713,658.285706 287.571228,659.356628 
z"/>
<path fill="#FCF9F2" opacity="1.000000" stroke="none" 
	d="
M638.935303,464.810120 
	C636.592346,463.060547 634.278320,460.924835 631.914856,458.452454 
	C635.184814,459.145935 638.457825,460.228271 638.935303,464.810120 
z"/>
<path fill="#FCF9F2" opacity="1.000000" stroke="none" 
	d="
M485.600891,446.507812 
	C484.623718,447.511505 483.479858,448.177124 482.336029,448.842712 
	C482.022797,448.064514 481.709534,447.286316 481.396301,446.508118 
	C482.742279,446.395325 484.088287,446.282501 485.600891,446.507812 
z"/>
<path fill="#FCF9F2" opacity="1.000000" stroke="none" 
	d="
M518.114685,877.821167 
	C517.994751,879.260986 517.613403,880.450073 517.232056,881.639160 
	C516.515015,880.896179 515.797974,880.153137 515.080933,879.410156 
	C516.005066,878.796936 516.929199,878.183716 518.114685,877.821167 
z"/>
<path fill="#FCF9F2" opacity="1.000000" stroke="none" 
	d="
M507.353333,880.925537 
	C507.739136,878.690552 508.485626,876.393311 509.609161,874.044495 
	C509.228790,876.283020 508.471405,878.573181 507.353333,880.925537 
z"/>
<path fill="#FCF7EE" opacity="1.000000" stroke="none" 
	d="
M850.356445,996.995728 
	C849.675171,995.575867 849.358276,994.151855 849.070923,992.357178 
	C850.341431,993.031006 851.582336,994.075439 852.823242,995.119751 
	C852.122437,995.743652 851.421631,996.367554 850.356445,996.995728 
z"/>
<path fill="#FCF9F2" opacity="1.000000" stroke="none" 
	d="
M583.206726,890.853943 
	C584.984070,889.232422 587.138367,887.638306 589.643372,886.016907 
	C587.857300,887.620178 585.720520,889.250732 583.206726,890.853943 
z"/>
<path fill="#F8D4B7" opacity="1.000000" stroke="none" 
	d="
M197.781860,974.332886 
	C196.110748,974.256714 194.439651,974.180603 192.357605,974.006714 
	C194.313080,970.708740 197.048523,971.770508 199.873505,973.650208 
	C199.928802,974.073059 199.805099,974.198853 199.466812,974.155945 
	C198.679642,974.186340 198.230759,974.259644 197.781860,974.332886 
z"/>
<path fill="#F8D4B7" opacity="1.000000" stroke="none" 
	d="
M203.794312,993.030640 
	C203.509781,991.258728 203.225235,989.486755 203.015656,987.327759 
	C206.399857,987.772034 206.263916,990.009949 204.845383,992.791199 
	C204.137360,993.030151 203.965851,993.030396 203.794312,993.030640 
z"/>
<path fill="#FCF9F2" opacity="1.000000" stroke="none" 
	d="
M497.089508,897.490112 
	C497.548828,897.093445 497.960632,897.051880 498.683777,897.003906 
	C501.448578,900.119263 499.109131,901.032043 496.364349,901.964478 
	C496.350006,900.618286 496.696014,899.231689 497.089508,897.490112 
z"/>
<path fill="#F8D4B7" opacity="1.000000" stroke="none" 
	d="
M202.007935,979.884033 
	C201.688904,980.091187 201.336960,980.137512 201.182800,979.747192 
	C200.707199,978.555908 200.385757,977.755005 200.064331,976.954041 
	C200.064331,976.954041 199.971161,976.527649 199.972305,975.992676 
	C199.917328,975.038086 199.861206,974.618469 199.805099,974.198853 
	C199.805099,974.198853 199.928802,974.073059 199.991180,974.009888 
	C203.438263,974.814575 203.372726,977.051331 202.007935,979.884033 
z"/>
<path fill="#FCF9F2" opacity="1.000000" stroke="none" 
	d="
M511.264954,848.851929 
	C511.020233,846.635376 511.012482,844.257080 511.009949,841.432739 
	C511.676147,842.717224 512.337097,844.447815 512.998840,846.587952 
	C512.500366,847.561707 512.001160,848.125916 511.264954,848.851929 
z"/>
<path fill="#FCF9F2" opacity="1.000000" stroke="none" 
	d="
M631.894043,457.771545 
	C629.981018,456.653778 628.078064,455.171356 626.046326,453.381073 
	C627.913086,454.517761 629.908569,455.962341 631.894043,457.771545 
z"/>
<path fill="#FCF7EE" opacity="1.000000" stroke="none" 
	d="
M854.146851,1028.291992 
	C854.871521,1029.198486 855.241028,1030.193848 855.610474,1031.189331 
	C855.048950,1031.343750 854.487427,1031.498291 853.925903,1031.652710 
	C853.881165,1030.562134 853.836426,1029.471436 854.146851,1028.291992 
z"/>
<path fill="#EDB289" opacity="1.000000" stroke="none" 
	d="
M189.304688,978.791016 
	C188.724747,977.638123 188.387863,976.259949 188.062378,974.507935 
	C188.828583,974.069519 189.583389,974.004944 190.704498,974.052124 
	C190.563110,975.631104 190.055420,977.098389 189.304688,978.791016 
z"/>
<path fill="#FCF9F2" opacity="1.000000" stroke="none" 
	d="
M476.804626,745.278076 
	C477.363525,746.417114 477.688416,747.821899 478.020935,749.612183 
	C477.922760,750.315369 477.737183,750.576477 477.253601,750.887329 
	C476.880493,749.177063 476.725586,747.360474 476.804626,745.278076 
z"/>
<path fill="#FCF9F2" opacity="1.000000" stroke="none" 
	d="
M555.208008,402.300537 
	C556.351257,402.073273 557.456848,402.172455 558.562439,402.271606 
	C558.427185,402.818176 558.291870,403.364777 558.156555,403.911346 
	C557.161194,403.483215 556.165771,403.055054 555.208008,402.300537 
z"/>
<path fill="#FCF7EE" opacity="1.000000" stroke="none" 
	d="
M595.852417,1038.417236 
	C597.242981,1038.266113 598.365051,1038.336670 599.487061,1038.407227 
	C599.370117,1038.939087 599.253174,1039.470947 599.136169,1040.002930 
	C597.952087,1039.548218 596.768005,1039.093628 595.852417,1038.417236 
z"/>
<path fill="#FCF9F2" opacity="1.000000" stroke="none" 
	d="
M670.541870,827.388916 
	C670.847290,828.294250 670.853271,829.039551 670.859192,829.784912 
	C670.067322,829.387451 669.275513,828.989990 668.483643,828.592529 
	C669.069946,828.138000 669.656250,827.683411 670.541870,827.388916 
z"/>
<path fill="#FBF5EA" opacity="1.000000" stroke="none" 
	d="
M259.643066,782.488892 
	C259.878601,783.364014 259.825897,784.090210 259.773193,784.816406 
	C259.000793,784.517761 258.228363,784.219177 257.455933,783.920532 
	C258.088928,783.393677 258.721924,782.866821 259.643066,782.488892 
z"/>
<path fill="#FBF5EA" opacity="1.000000" stroke="none" 
	d="
M322.640320,965.858948 
	C321.693909,965.464844 321.075012,964.998352 320.456085,964.531860 
	C321.065582,964.142273 321.675079,963.752747 322.284607,963.363220 
	C322.512360,964.171021 322.740082,964.978760 322.640320,965.858948 
z"/>
<path fill="#FCF9F2" opacity="1.000000" stroke="none" 
	d="
M501.957458,903.424561 
	C502.638824,904.211060 502.993774,905.067139 503.348694,905.923218 
	C502.792786,906.104248 502.236908,906.285278 501.681030,906.466309 
	C501.664368,905.475647 501.647675,904.484985 501.957458,903.424561 
z"/>
<path fill="#F8D4B7" opacity="1.000000" stroke="none" 
	d="
M192.206879,992.990845 
	C192.504578,994.737976 192.802261,996.485107 193.053314,998.615173 
	C192.363983,997.240051 191.721283,995.481934 191.383392,993.337769 
	C191.861099,992.964722 192.033981,992.977783 192.206879,992.990845 
z"/>
<path fill="#FCF7EE" opacity="1.000000" stroke="none" 
	d="
M722.126953,1030.109009 
	C723.364685,1029.201782 724.792969,1028.574585 726.560791,1027.908203 
	C725.372620,1028.709106 723.845032,1029.548950 722.126953,1030.109009 
z"/>
<path fill="#FBF5EA" opacity="1.000000" stroke="none" 
	d="
M220.625397,750.856567 
	C219.516235,750.955505 218.407578,750.740417 217.298920,750.525330 
	C217.501678,750.013550 217.704437,749.501770 217.907196,748.989990 
	C218.813431,749.507507 219.719666,750.025024 220.625397,750.856567 
z"/>
<path fill="#FCF9F2" opacity="1.000000" stroke="none" 
	d="
M424.796265,412.278900 
	C424.151794,412.927094 423.457306,413.252502 422.762817,413.577881 
	C422.686218,413.162079 422.609650,412.746307 422.533051,412.330536 
	C423.270782,412.205750 424.008514,412.080933 424.796265,412.278900 
z"/>
<path fill="#FCF9F2" opacity="1.000000" stroke="none" 
	d="
M502.338379,890.951294 
	C502.771240,889.697998 503.541290,888.395996 504.646362,887.042603 
	C504.212799,888.294983 503.444183,889.598816 502.338379,890.951294 
z"/>
<path fill="#FBF5EA" opacity="1.000000" stroke="none" 
	d="
M320.528259,1037.308838 
	C319.679993,1037.054199 319.043884,1036.558105 318.407837,1036.061890 
	C318.720490,1035.774536 319.033142,1035.487061 319.345764,1035.199707 
	C319.810638,1035.822266 320.275543,1036.444946 320.528259,1037.308838 
z"/>
<path fill="#FCF7EE" opacity="1.000000" stroke="none" 
	d="
M540.538757,955.016968 
	C540.134033,954.575134 540.041443,954.126526 540.256104,953.332886 
	C540.732727,952.976013 540.902161,952.964172 541.071533,952.952393 
	C541.574402,953.256897 542.077271,953.561401 542.580139,953.865906 
	C542.003723,954.247375 541.427307,954.628845 540.538757,955.016968 
z"/>
<path fill="#FCF9F2" opacity="1.000000" stroke="none" 
	d="
M575.305420,905.523926 
	C574.551758,905.529785 574.017029,905.325134 573.482239,905.120544 
	C573.616821,904.846802 573.751465,904.573059 573.886047,904.299316 
	C574.432129,904.637390 574.978271,904.975403 575.305420,905.523926 
z"/>
<path fill="#FCF9F2" opacity="1.000000" stroke="none" 
	d="
M269.695190,264.476227 
	C270.448608,264.470459 270.983124,264.675049 271.517670,264.879608 
	C271.383087,265.153198 271.248505,265.426788 271.113892,265.700348 
	C270.568054,265.362427 270.022186,265.024475 269.695190,264.476227 
z"/>
<path fill="#FCF9F2" opacity="1.000000" stroke="none" 
	d="
M505.204590,896.192017 
	C505.764709,895.947388 506.263611,896.001038 506.762543,896.054688 
	C506.649048,896.502563 506.535553,896.950500 506.422089,897.398376 
	C505.995850,897.095703 505.569611,896.792969 505.204590,896.192017 
z"/>
<path fill="#FCF9F2" opacity="1.000000" stroke="none" 
	d="
M520.760376,886.097534 
	C520.721130,887.096069 520.294312,888.130005 519.541138,889.311401 
	C519.600708,888.350220 519.986694,887.241638 520.760376,886.097534 
z"/>
<path fill="#EDB289" opacity="1.000000" stroke="none" 
	d="
M192.411026,992.778381 
	C192.033981,992.977783 191.861099,992.964722 191.430511,992.926514 
	C191.102097,992.497742 191.031372,992.094055 191.033722,991.067627 
	C191.183090,990.240356 191.259384,990.035767 191.335663,989.831177 
	C191.762177,990.742737 192.188675,991.654358 192.411026,992.778381 
z"/>
<path fill="#F8D4B7" opacity="1.000000" stroke="none" 
	d="
M191.252213,989.560669 
	C191.259384,990.035767 191.183090,990.240356 191.014832,990.759949 
	C190.495667,990.147278 190.068481,989.219543 189.929993,988.135071 
	C190.535370,988.415588 190.852066,988.852905 191.252213,989.560669 
z"/>
<path fill="#EDB289" opacity="1.000000" stroke="none" 
	d="
M203.588776,993.247070 
	C203.965851,993.030396 204.137360,993.030151 204.564606,993.015991 
	C204.871964,993.790771 204.923615,994.579468 204.857910,995.750366 
	C204.288116,995.242859 203.835678,994.353210 203.588776,993.247070 
z"/>
<path fill="#FCF7EE" opacity="1.000000" stroke="none" 
	d="
M783.202881,1004.208008 
	C783.802917,1004.570312 784.104614,1004.995239 784.406372,1005.420044 
	C783.960571,1005.534485 783.514832,1005.648926 783.069092,1005.763428 
	C783.014221,1005.265808 782.959412,1004.768188 783.202881,1004.208008 
z"/>
<path fill="#FCF7EE" opacity="1.000000" stroke="none" 
	d="
M855.495239,997.668518 
	C856.036377,998.011902 856.360962,998.569641 856.685486,999.127441 
	C856.407898,999.254456 856.130310,999.381531 855.852722,999.508545 
	C855.661377,998.966675 855.470032,998.424744 855.495239,997.668518 
z"/>
<path fill="#F8D4B7" opacity="1.000000" stroke="none" 
	d="
M203.035492,984.849976 
	C202.469101,984.217102 202.186508,983.422485 201.977844,982.296997 
	C202.474274,982.873474 202.896790,983.780884 203.035492,984.849976 
z"/>
<path fill="#FCF9F2" opacity="1.000000" stroke="none" 
	d="
M461.667938,438.505859 
	C462.011780,437.963593 462.570190,437.638428 463.128571,437.313232 
	C463.255707,437.591339 463.382843,437.869446 463.509949,438.147552 
	C462.967468,438.339355 462.424988,438.531158 461.667938,438.505859 
z"/>
<path fill="#FCF9F2" opacity="1.000000" stroke="none" 
	d="
M488.358582,914.954834 
	C488.483887,914.104065 488.951141,913.193787 489.707214,912.141113 
	C489.564209,912.964294 489.132385,913.929871 488.358582,914.954834 
z"/>
<path fill="#FCF9F2" opacity="1.000000" stroke="none" 
	d="
M625.909302,452.747437 
	C625.049255,452.593842 624.169006,452.087921 623.175781,451.286743 
	C624.004944,451.459320 624.946960,451.927246 625.909302,452.747437 
z"/>
<path fill="#FBF5EA" opacity="1.000000" stroke="none" 
	d="
M386.387268,862.460571 
	C385.942627,862.345642 385.670013,862.065430 385.418640,861.767273 
	C385.411987,861.759521 385.636658,861.556763 385.753571,861.444946 
	C386.023102,861.727539 386.292603,862.010193 386.387268,862.460571 
z"/>
<path fill="#FCF9F2" opacity="1.000000" stroke="none" 
	d="
M560.947937,411.621216 
	C560.284790,411.579437 559.561646,411.187531 558.688965,410.503296 
	C559.322266,410.564362 560.105164,410.917725 560.947937,411.621216 
z"/>
<path fill="#FBF5EA" opacity="1.000000" stroke="none" 
	d="
M262.088135,988.902893 
	C261.739716,988.450195 261.643951,987.865845 261.764984,987.138672 
	C262.101471,987.587585 262.221130,988.179443 262.088135,988.902893 
z"/>
<path fill="#FCF9F2" opacity="1.000000" stroke="none" 
	d="
M720.045410,709.396240 
	C720.286438,709.791199 720.284729,710.184204 720.254578,710.574951 
	C720.253723,710.585938 719.949158,710.573425 719.786255,710.571899 
	C719.790405,710.179260 719.794556,709.786560 720.045410,709.396240 
z"/>
<path fill="#FBF5EA" opacity="1.000000" stroke="none" 
	d="
M541.032104,952.660522 
	C540.902161,952.964172 540.732727,952.976013 540.308960,952.990112 
	C539.771729,952.788818 539.488953,952.585144 539.126221,952.125488 
	C539.148438,951.569641 539.334167,951.336304 539.812256,951.076294 
	C540.344910,951.445007 540.668823,951.906860 541.032104,952.660522 
z"/>
<path fill="#FCF7EE" opacity="1.000000" stroke="none" 
	d="
M716.831543,1011.764893 
	C716.531738,1012.321960 716.063477,1012.648193 715.295654,1012.981934 
	C715.551758,1012.504333 716.107483,1012.019165 716.831543,1011.764893 
z"/>
<path fill="#FCF9F2" opacity="1.000000" stroke="none" 
	d="
M534.971191,401.679138 
	C534.442505,401.820190 533.882935,401.669434 533.157104,401.272156 
	C533.640625,401.146149 534.290466,401.266724 534.971191,401.679138 
z"/>
<path fill="#FCF9F2" opacity="1.000000" stroke="none" 
	d="
M279.988831,273.244141 
	C280.098633,273.410126 280.044739,273.577606 279.990845,273.745087 
	C279.935638,273.578613 279.880371,273.412140 279.988831,273.244141 
z"/>
<path fill="#EDB289" opacity="1.000000" stroke="none" 
	d="
M193.960800,1000.752380 
	C194.031296,1000.963440 193.564224,1000.985901 193.331039,1000.991333 
	C193.087082,1000.816650 193.076309,1000.636475 193.158325,1000.201538 
	C193.537415,1000.066162 193.750488,1000.264343 193.960800,1000.752380 
z"/>
<path fill="#FEFEFE" opacity="1.000000" stroke="none" 
	d="
M641.002441,740.004883 
	C641.284790,738.240906 641.256287,736.350586 641.911438,734.738098 
	C643.479614,730.878723 645.515015,727.164246 641.233459,723.673584 
	C640.922668,723.420227 641.192688,722.454529 641.192688,721.073425 
	C646.528931,724.767334 649.751770,720.478760 653.513977,718.504272 
	C654.228455,718.129333 654.584351,716.629700 654.688110,715.606628 
	C655.244202,710.128296 655.648560,704.634766 656.146179,699.150208 
	C656.385315,696.513733 656.875000,693.890930 656.965271,691.252136 
	C657.104248,687.190735 657.223145,683.105042 656.939697,679.058411 
	C656.539551,673.345276 655.557861,667.670532 655.222717,661.955994 
	C655.001770,658.189575 655.457458,654.380981 655.653381,650.593506 
	C655.826904,647.239624 656.310608,644.291138 654.118042,640.808228 
	C652.474609,638.197571 653.603027,633.575073 654.146423,629.945496 
	C654.527893,627.397095 655.990295,625.007751 656.989990,622.555481 
	C658.060242,619.930115 658.865906,617.425354 655.259949,615.986206 
	C654.756958,615.785461 654.182312,615.008301 654.159302,614.475586 
	C653.860962,607.572083 653.650330,600.664612 653.462219,593.756958 
	C653.457214,593.571228 653.952087,593.371948 654.932495,593.264526 
	C656.038940,593.869690 656.683533,594.347229 656.776428,594.915039 
	C658.227661,603.785889 659.312195,612.730286 661.154541,621.516602 
	C661.856812,624.865906 664.259644,627.858582 665.952393,631.434204 
	C666.046143,633.555420 666.075012,635.256042 665.807861,637.198364 
	C665.174561,639.149170 664.837463,640.858398 664.543701,642.347778 
	C662.720947,642.199097 661.243042,642.078552 659.765198,641.958008 
	C659.765198,645.170654 659.765198,648.383301 659.765198,651.595886 
	C660.451477,651.655396 661.137756,651.714905 661.824036,651.774475 
	C662.461304,650.013672 663.098511,648.252930 663.735779,646.492188 
	C663.992554,646.543579 664.249268,646.595032 664.505981,646.646484 
	C664.505981,654.584473 664.505981,662.522400 664.505981,671.361145 
	C662.882996,669.475403 661.895630,668.328247 660.830017,667.090088 
	C656.798157,673.400452 658.540833,680.134338 665.149048,685.412354 
	C665.101074,686.521729 665.045471,687.260437 664.624023,688.063049 
	C663.709900,688.710022 662.816345,689.215881 662.665833,689.888062 
	C660.857117,697.967285 659.147827,706.068909 657.505371,714.183472 
	C657.472107,714.347351 658.551453,714.736389 659.072449,715.399048 
	C658.487183,718.226257 657.941162,720.674683 657.293701,723.427795 
	C657.136475,724.156189 657.080627,724.579834 656.763489,725.121582 
	C656.023315,725.828735 655.544495,726.417847 654.706787,727.056763 
	C652.191528,728.668762 650.035095,730.230957 647.342285,732.181824 
	C648.074036,740.899292 648.002563,741.001709 641.002441,740.004883 
z"/>
<path fill="#FEFEFE" opacity="1.000000" stroke="none" 
	d="
M654.997498,591.000000 
	C651.869690,591.883484 648.783936,593.029907 645.596313,593.538818 
	C644.018372,593.790771 641.986572,593.240601 640.691345,592.312256 
	C640.095764,591.885315 640.779236,589.676086 640.880188,588.274902 
	C641.040588,586.048767 641.244873,583.824585 641.348450,581.595825 
	C641.572998,576.760254 641.433777,571.882751 642.039978,567.097961 
	C642.510864,563.380798 640.722778,560.279297 637.002075,560.011108 
	C636.175232,559.951477 635.326904,560.088562 634.513000,559.968750 
	C631.905762,559.584961 628.803162,559.824341 626.890442,558.441650 
	C625.643311,557.540161 626.165405,554.232971 625.835449,552.012390 
	C625.046509,546.703552 623.057373,545.052612 617.454590,545.001709 
	C616.125366,544.989624 614.790894,544.922119 613.467834,545.012573 
	C610.318115,545.228088 609.177612,543.869446 608.912598,540.669861 
	C608.373718,534.163635 609.519775,528.301331 615.345459,522.601379 
	C606.945312,523.049194 608.631165,517.702087 608.410645,513.380005 
	C609.611511,512.907104 610.412476,512.814392 611.197021,512.753235 
	C611.180542,512.784790 611.148254,512.843018 611.136719,513.246338 
	C611.988403,517.162598 611.815125,521.300110 617.048462,521.885254 
	C617.823364,521.971863 618.405762,524.235168 618.978699,525.534912 
	C619.479370,526.670715 619.546265,528.138428 620.322632,528.983887 
	C622.206909,531.035522 624.404724,532.797302 626.453735,534.700073 
	C628.677307,536.764954 630.875549,538.857239 633.062256,540.974365 
	C633.039734,541.011169 633.064575,540.930420 633.045776,541.333008 
	C633.679138,543.915833 635.151611,546.224731 634.833679,548.252869 
	C633.766907,555.057068 638.351624,556.459595 643.362915,558.122803 
	C643.767334,558.853821 643.939392,559.405090 643.847412,560.140015 
	C644.404358,561.547119 645.225403,562.770508 646.224731,564.291138 
	C646.733521,565.877808 647.063965,567.167419 647.314697,568.870850 
	C648.027832,572.386475 647.943054,576.280823 649.842102,578.383362 
	C652.068481,580.848328 651.428589,582.084778 649.622681,584.005859 
	C648.227722,584.995056 647.212463,585.978394 646.197205,586.961792 
	C647.444824,587.733643 648.692444,588.505554 650.932312,589.891296 
	C650.424133,587.207092 650.214539,586.099915 650.380493,584.998962 
	C654.454529,585.387085 654.740845,588.183044 654.997498,591.000000 
M643.776917,577.365662 
	C643.591980,578.379150 643.406982,579.392639 643.222046,580.406128 
	C643.924866,580.397400 644.627686,580.388733 645.330566,580.380005 
	C645.040100,579.312866 644.749695,578.245789 643.776917,577.365662 
z"/>
<path fill="#FEFEFE" opacity="1.000000" stroke="none" 
	d="
M504.158386,808.693787 
	C497.770111,803.960083 493.813354,797.110657 489.408936,790.621399 
	C487.887421,788.379761 486.466064,786.070007 484.998260,783.396912 
	C487.588104,782.393066 490.178619,781.783752 493.106506,781.095032 
	C495.228241,783.383850 497.966522,786.337830 500.988068,789.597473 
	C500.602142,790.011902 498.913666,791.018921 498.607086,792.345398 
	C498.158630,794.285461 497.996460,796.948730 499.009827,798.394104 
	C500.135712,800.000061 502.642944,800.788086 504.691193,801.548645 
	C506.299438,802.145752 508.124451,802.158997 509.853088,802.431641 
	C508.519135,804.011597 507.207581,805.611389 505.837830,807.159729 
	C505.413757,807.639038 504.831238,807.978149 504.158386,808.693787 
z"/>
<path fill="#EDB289" opacity="1.000000" stroke="none" 
	d="
M655.065674,727.006958 
	C655.544495,726.417847 656.023315,725.828735 657.029541,725.358093 
	C657.531433,726.593933 657.802185,727.820557 657.436584,728.812561 
	C650.722656,747.028015 641.763672,764.137390 631.390564,780.497742 
	C629.948486,782.772217 627.142822,784.182129 624.968384,785.992310 
	C626.989075,782.571472 629.009827,779.150696 631.063110,775.374329 
	C631.095764,775.018799 631.196411,774.706787 631.508423,774.410278 
	C634.920471,768.774597 638.020569,763.435547 641.120728,758.096436 
	C641.120728,758.096436 641.118896,758.032776 641.410583,757.903015 
	C646.156738,747.517822 650.611206,737.262390 655.065674,727.006958 
z"/>
<path fill="#FCF9F2" opacity="1.000000" stroke="none" 
	d="
M650.002441,584.000000 
	C651.428589,582.084778 652.068481,580.848328 649.842102,578.383362 
	C647.943054,576.280823 648.027832,572.386475 647.611633,569.057129 
	C651.204651,576.624146 655.250244,584.193176 657.446716,592.265320 
	C660.912842,605.003845 663.137390,618.080322 665.887451,631.013733 
	C664.259644,627.858582 661.856812,624.865906 661.154541,621.516602 
	C659.312195,612.730286 658.227661,603.785889 656.776428,594.915039 
	C656.683533,594.347229 656.038940,593.869690 655.326721,593.175049 
	C655.001648,592.555542 655.000854,592.111084 654.998779,591.333313 
	C654.740845,588.183044 654.454529,585.387085 650.379761,584.750854 
	C650.003479,584.496460 650.002441,584.000000 650.002441,584.000000 
z"/>
<path fill="#FCF9F2" opacity="1.000000" stroke="none" 
	d="
M654.706787,727.056763 
	C650.611206,737.262390 646.156738,747.517822 641.395996,757.930847 
	C641.708740,754.000977 642.327759,749.913513 642.869263,746.338013 
	C642.194031,744.338257 641.598267,742.573669 641.002441,740.406982 
	C648.002563,741.001709 648.074036,740.899292 647.342285,732.181824 
	C650.035095,730.230957 652.191528,728.668762 654.706787,727.056763 
z"/>
<path fill="#FEFEFE" opacity="1.000000" stroke="none" 
	d="
M607.000000,513.000000 
	C604.466370,512.976929 601.906921,513.169373 599.406555,512.869019 
	C596.995789,512.579407 594.646179,511.781128 592.269653,511.206360 
	C593.608154,508.830414 594.946655,506.454498 596.649963,504.418213 
	C597.735229,507.622131 598.031372,511.098389 602.137573,507.144836 
	C602.873840,506.435944 604.802917,506.966003 606.377441,507.017883 
	C606.819519,507.288025 606.988159,507.520355 607.049194,508.241516 
	C607.012573,510.116119 607.006287,511.558075 607.000000,513.000000 
z"/>
<path fill="#FCF9F2" opacity="1.000000" stroke="none" 
	d="
M640.740601,758.067505 
	C638.020569,763.435547 634.920471,768.774597 631.496948,774.246704 
	C632.451843,768.856873 631.634033,762.440918 637.663269,758.940857 
	C638.466370,758.474731 639.456787,758.331421 640.740601,758.067505 
z"/>
<path fill="#FEFEFE" opacity="1.000000" stroke="none" 
	d="
M511.162903,823.677429 
	C508.917755,822.697510 506.846954,821.378723 504.356171,819.786743 
	C503.936218,819.513550 503.934113,819.015625 503.934082,819.015625 
	C504.273071,818.546753 504.612061,818.077881 504.974915,817.304504 
	C506.256836,816.178955 507.502228,815.337585 508.777771,814.544739 
	C509.534119,814.074585 510.461517,813.211060 511.088348,813.375732 
	C511.819916,813.567871 512.871887,814.748657 512.802612,815.395203 
	C512.516479,818.064636 511.858551,820.694275 511.162903,823.677429 
z"/>
<path fill="#FCF9F2" opacity="1.000000" stroke="none" 
	d="
M606.181274,506.924011 
	C604.802917,506.966003 602.873840,506.435944 602.137573,507.144836 
	C598.031372,511.098389 597.735229,507.622131 597.009827,504.379822 
	C597.330383,502.606873 597.655884,501.211975 597.962585,499.436279 
	C597.943787,499.055450 598.067200,498.950256 598.407837,499.073120 
	C601.226135,501.772003 603.703674,504.347992 606.181274,506.924011 
z"/>
<path fill="#FEFEFE" opacity="1.000000" stroke="none" 
	d="
M511.272888,833.771545 
	C508.884613,831.556580 507.884460,829.007874 510.622864,826.063721 
	C514.202515,828.282410 512.586792,830.933960 511.272888,833.771545 
z"/>
<path fill="#FCF9F2" opacity="1.000000" stroke="none" 
	d="
M607.250000,513.000610 
	C607.006287,511.558075 607.012573,510.116119 607.376953,508.274414 
	C608.174316,507.894623 608.613708,507.914551 609.023926,507.961670 
	C608.994812,507.988861 609.063416,507.950134 609.057312,508.291412 
	C609.771973,509.995697 610.492737,511.358704 611.213501,512.721680 
	C610.412476,512.814392 609.611511,512.907104 608.405273,513.002686 
	C607.833313,513.004211 607.666687,513.002747 607.250000,513.000610 
z"/>
<path fill="#FCF9F2" opacity="1.000000" stroke="none" 
	d="
M476.809570,601.280273 
	C477.224762,602.841064 477.405457,604.667114 477.315918,606.738098 
	C476.888824,605.170532 476.731964,603.358032 476.809570,601.280273 
z"/>
<path fill="#FCF9F2" opacity="1.000000" stroke="none" 
	d="
M476.809570,529.280273 
	C477.224762,530.841064 477.405426,532.667114 477.315918,534.738098 
	C476.888824,533.170532 476.731964,531.358032 476.809570,529.280273 
z"/>
<path fill="#FCF9F2" opacity="1.000000" stroke="none" 
	d="
M476.809570,545.280273 
	C477.224762,546.841064 477.405457,548.667114 477.315918,550.738098 
	C476.888824,549.170532 476.731934,547.358032 476.809570,545.280273 
z"/>
<path fill="#FCF9F2" opacity="1.000000" stroke="none" 
	d="
M476.809570,649.280273 
	C477.224762,650.841064 477.405426,652.667114 477.315918,654.738098 
	C476.888794,653.170532 476.731934,651.358032 476.809570,649.280273 
z"/>
<path fill="#FCF9F2" opacity="1.000000" stroke="none" 
	d="
M476.809570,681.280273 
	C477.224762,682.841064 477.405457,684.667114 477.315918,686.738098 
	C476.888824,685.170532 476.731934,683.358032 476.809570,681.280273 
z"/>
<path fill="#FCF9F2" opacity="1.000000" stroke="none" 
	d="
M476.809570,673.280273 
	C477.224762,674.841064 477.405457,676.667114 477.315918,678.738098 
	C476.888794,677.170532 476.731934,675.358032 476.809570,673.280273 
z"/>
<path fill="#FCF9F2" opacity="1.000000" stroke="none" 
	d="
M476.809540,497.280396 
	C477.224731,498.841187 477.405396,500.667175 477.315857,502.738190 
	C476.888794,501.170654 476.731903,499.358124 476.809540,497.280396 
z"/>
<path fill="#FCF9F2" opacity="1.000000" stroke="none" 
	d="
M476.809570,489.280396 
	C477.224762,490.841187 477.405426,492.667175 477.315887,494.738190 
	C476.888794,493.170654 476.731903,491.358124 476.809570,489.280396 
z"/>
<path fill="#FCF9F2" opacity="1.000000" stroke="none" 
	d="
M476.809570,633.280273 
	C477.224762,634.841064 477.405457,636.667114 477.315918,638.738098 
	C476.888824,637.170532 476.731934,635.358032 476.809570,633.280273 
z"/>
<path fill="#FCF9F2" opacity="1.000000" stroke="none" 
	d="
M476.809570,641.280273 
	C477.224762,642.841064 477.405457,644.667114 477.315918,646.738098 
	C476.888824,645.170532 476.731964,643.358032 476.809570,641.280273 
z"/>
<path fill="#FCF9F2" opacity="1.000000" stroke="none" 
	d="
M476.809570,513.280273 
	C477.224762,514.841064 477.405457,516.667114 477.315918,518.738098 
	C476.888824,517.170532 476.731964,515.358032 476.809570,513.280273 
z"/>
<path fill="#FCF9F2" opacity="1.000000" stroke="none" 
	d="
M476.809570,609.280273 
	C477.224762,610.841064 477.405426,612.667114 477.315918,614.738098 
	C476.888824,613.170532 476.731934,611.358032 476.809570,609.280273 
z"/>
<path fill="#FCF9F2" opacity="1.000000" stroke="none" 
	d="
M476.809570,665.280273 
	C477.224762,666.841064 477.405457,668.667114 477.315918,670.738098 
	C476.888824,669.170532 476.731964,667.358032 476.809570,665.280273 
z"/>
<path fill="#FCF9F2" opacity="1.000000" stroke="none" 
	d="
M476.809570,617.280273 
	C477.224762,618.841064 477.405426,620.667114 477.315918,622.738098 
	C476.888824,621.170532 476.731964,619.358032 476.809570,617.280273 
z"/>
<path fill="#FCF9F2" opacity="1.000000" stroke="none" 
	d="
M476.809570,625.280273 
	C477.224762,626.841064 477.405457,628.667114 477.315918,630.738098 
	C476.888824,629.170532 476.731964,627.358032 476.809570,625.280273 
z"/>
<path fill="#FCF9F2" opacity="1.000000" stroke="none" 
	d="
M476.809570,657.280273 
	C477.224762,658.841064 477.405426,660.667114 477.315918,662.738098 
	C476.888824,661.170532 476.731934,659.358032 476.809570,657.280273 
z"/>
<path fill="#FCF9F2" opacity="1.000000" stroke="none" 
	d="
M476.809570,577.280273 
	C477.224762,578.841064 477.405426,580.667114 477.315918,582.738098 
	C476.888824,581.170532 476.731964,579.358032 476.809570,577.280273 
z"/>
<path fill="#FCF9F2" opacity="1.000000" stroke="none" 
	d="
M476.809570,721.280273 
	C477.224762,722.841064 477.405457,724.667114 477.315918,726.738098 
	C476.888824,725.170532 476.731934,723.358032 476.809570,721.280273 
z"/>
<path fill="#FCF9F2" opacity="1.000000" stroke="none" 
	d="
M476.809570,537.280273 
	C477.224762,538.841064 477.405426,540.667114 477.315918,542.738098 
	C476.888824,541.170532 476.731964,539.358032 476.809570,537.280273 
z"/>
<path fill="#FCF9F2" opacity="1.000000" stroke="none" 
	d="
M476.809570,481.280396 
	C477.224731,482.841187 477.405396,484.667175 477.315857,486.738190 
	C476.888794,485.170654 476.731934,483.358124 476.809570,481.280396 
z"/>
<path fill="#FCF9F2" opacity="1.000000" stroke="none" 
	d="
M476.809570,713.280273 
	C477.224762,714.841064 477.405457,716.667114 477.315918,718.738098 
	C476.888824,717.170532 476.731964,715.358032 476.809570,713.280273 
z"/>
<path fill="#FCF9F2" opacity="1.000000" stroke="none" 
	d="
M476.809570,569.280273 
	C477.224762,570.841064 477.405426,572.667114 477.315918,574.738098 
	C476.888824,573.170532 476.731934,571.358032 476.809570,569.280273 
z"/>
<path fill="#FCF9F2" opacity="1.000000" stroke="none" 
	d="
M476.809601,561.280273 
	C477.224762,562.841064 477.405457,564.667114 477.315918,566.738098 
	C476.888824,565.170532 476.731964,563.358032 476.809601,561.280273 
z"/>
<path fill="#FCF9F2" opacity="1.000000" stroke="none" 
	d="
M476.809570,585.280273 
	C477.224762,586.841064 477.405457,588.667114 477.315918,590.738098 
	C476.888824,589.170532 476.731964,587.358032 476.809570,585.280273 
z"/>
<path fill="#FCF9F2" opacity="1.000000" stroke="none" 
	d="
M476.809570,521.280273 
	C477.224762,522.841064 477.405457,524.667114 477.315918,526.738098 
	C476.888824,525.170532 476.731934,523.358032 476.809570,521.280273 
z"/>
<path fill="#FCF9F2" opacity="1.000000" stroke="none" 
	d="
M476.809570,705.280273 
	C477.224762,706.841064 477.405457,708.667114 477.315918,710.738098 
	C476.888824,709.170532 476.731964,707.358032 476.809570,705.280273 
z"/>
<path fill="#FCF9F2" opacity="1.000000" stroke="none" 
	d="
M476.809570,697.280273 
	C477.224762,698.841064 477.405426,700.667114 477.315918,702.738098 
	C476.888794,701.170532 476.731934,699.358032 476.809570,697.280273 
z"/>
<path fill="#FCF9F2" opacity="1.000000" stroke="none" 
	d="
M476.809570,505.280396 
	C477.224731,506.841187 477.405396,508.667175 477.315857,510.738190 
	C476.888794,509.170654 476.731934,507.358124 476.809570,505.280396 
z"/>
<path fill="#FCF9F2" opacity="1.000000" stroke="none" 
	d="
M476.809570,473.280396 
	C477.224762,474.841187 477.405426,476.667175 477.315857,478.738190 
	C476.888794,477.170654 476.731903,475.358124 476.809570,473.280396 
z"/>
<path fill="#FCF9F2" opacity="1.000000" stroke="none" 
	d="
M476.819458,465.283020 
	C477.233337,466.845093 477.409515,468.669525 477.315674,470.738586 
	C476.891022,469.170593 476.736359,467.357971 476.819458,465.283020 
z"/>
<path fill="#FCF9F2" opacity="1.000000" stroke="none" 
	d="
M476.809570,553.280273 
	C477.224762,554.841064 477.405457,556.667114 477.315918,558.738098 
	C476.888824,557.170532 476.731964,555.358032 476.809570,553.280273 
z"/>
<path fill="#FCF9F2" opacity="1.000000" stroke="none" 
	d="
M476.809570,593.280273 
	C477.224762,594.841064 477.405457,596.667114 477.315918,598.738098 
	C476.888824,597.170532 476.731934,595.358032 476.809570,593.280273 
z"/>
<path fill="#FCF9F2" opacity="1.000000" stroke="none" 
	d="
M476.809570,689.280273 
	C477.224762,690.841064 477.405426,692.667114 477.315918,694.738098 
	C476.888824,693.170532 476.731964,691.358032 476.809570,689.280273 
z"/>
<path fill="#FD9250" opacity="1.000000" stroke="none" 
	d="
M241.048401,399.000000 
	C241.048431,352.537567 241.048431,306.575104 241.048431,259.429596 
	C245.540848,261.870819 249.277740,263.806488 252.924225,265.899597 
	C268.057770,274.586365 283.104858,283.426025 298.315186,291.975525 
	C303.090027,294.659424 305.235748,297.740845 305.224396,303.680786 
	C305.003937,419.153961 305.058441,534.627686 305.056488,650.101257 
	C305.056458,653.215088 305.056488,656.328979 305.056488,660.915588 
	C299.038971,657.435303 294.034637,654.667297 289.154846,651.694946 
	C274.531219,642.787537 260.108154,633.629578 248.186249,621.063110 
	C243.553864,616.180298 240.817749,611.261780 240.852188,603.952209 
	C241.173340,535.802673 241.045853,467.651031 241.048401,399.000000 
z"/>
<path fill="#FD9251" opacity="1.000000" stroke="none" 
	d="
M390.898804,444.000000 
	C390.898712,530.847046 390.898712,617.194092 390.898712,704.649658 
	C388.053680,703.498291 386.397736,702.960449 384.860992,702.186096 
	C367.510254,693.442200 350.211029,684.595215 332.815704,675.941284 
	C328.767090,673.927185 326.738586,671.664368 326.747101,666.578979 
	C326.925568,560.311768 326.869904,454.044250 326.860352,347.776764 
	C326.860199,345.979248 326.860321,344.181732 326.860321,341.293335 
	C330.536133,342.849243 333.489716,343.752380 336.094757,345.258392 
	C353.185333,355.138824 370.244537,365.076233 387.172821,375.230408 
	C388.869965,376.248444 390.636139,378.825623 390.660645,380.701477 
	C390.934143,401.631775 390.871155,422.566498 390.898804,444.000000 
z"/>
<path fill="#F59556" opacity="1.000000" stroke="none" 
	d="
M503.667328,818.809937 
	C503.934113,819.015625 503.936218,819.513550 504.016876,819.749146 
	C505.451965,825.195068 507.214172,830.338135 508.088623,835.627869 
	C511.464600,856.049377 507.683258,875.317932 498.079865,893.544434 
	C497.631531,894.395325 497.399384,895.360168 497.046783,896.647949 
	C493.704346,902.046143 490.515228,907.162842 487.034698,912.073120 
	C475.249512,928.700073 462.464539,944.441711 446.928589,957.815247 
	C437.526489,965.908569 428.362396,974.285217 419.308105,982.766052 
	C418.297180,983.712952 418.177948,986.291443 418.539093,987.914368 
	C421.095123,999.401123 422.022339,1010.740845 415.051819,1021.093445 
	C407.973267,1031.606445 393.454041,1032.858765 384.200592,1024.071289 
	C383.754486,1023.647644 383.129120,1023.412720 382.560883,1023.074219 
	C380.125519,1024.612061 382.338531,1029.173706 377.407349,1029.182007 
	C367.829285,1029.198120 367.826904,1029.608276 367.828491,1019.985596 
	C367.831970,999.165955 367.829956,978.346252 367.850372,957.526611 
	C367.855896,951.915833 367.951416,952.105774 375.519592,951.792847 
	C379.847046,951.613892 381.526550,952.862854 381.244110,957.371216 
	C380.890717,963.011963 381.106689,968.692566 381.193542,974.354126 
	C381.219910,976.071838 381.632690,977.783752 382.010986,980.534546 
	C387.101318,975.496399 392.048828,972.605957 398.480530,972.928467 
	C404.554260,973.233093 409.801086,975.069153 415.443054,981.243042 
	C416.079956,979.821472 416.678955,978.484436 417.442322,976.860596 
	C424.491028,968.148743 431.744690,959.994507 438.168823,951.232056 
	C446.716339,939.573364 453.261566,926.707825 457.587372,912.894165 
	C459.644714,906.324402 461.197205,899.471252 461.926788,892.634216 
	C464.253662,870.828796 458.181763,851.060730 445.722961,833.285706 
	C435.895905,819.265442 424.057892,807.049927 410.323334,796.768799 
	C400.039642,789.070801 389.991180,780.981018 379.205017,774.050842 
	C364.890839,764.853882 349.963409,756.610962 335.293213,747.968811 
	C330.601898,745.205261 325.890533,742.475769 321.096344,739.363403 
	C321.004364,738.996155 321.111755,738.887085 321.506256,738.922729 
	C325.861694,740.066528 329.822632,741.174683 334.140656,742.382751 
	C336.410034,743.781494 339.167389,745.481018 341.924744,747.180481 
	C342.293396,746.884399 342.662048,746.588318 343.030731,746.292236 
	C342.420654,744.114807 341.810608,741.937439 341.132538,739.517334 
	C345.424927,738.481140 346.577454,741.267700 345.696503,743.817322 
	C343.863342,749.123047 347.120911,751.243652 350.631012,753.214111 
	C354.115906,755.170471 357.877930,756.629517 361.494781,758.356140 
	C362.923553,759.038147 364.326904,760.556580 365.675415,760.482666 
	C370.512604,760.217773 373.462738,762.388855 375.729187,766.431396 
	C376.665100,768.100769 378.450806,769.355225 380.009491,770.598450 
	C381.987213,772.175903 385.303436,771.379211 386.321716,775.205017 
	C386.687256,776.578674 389.954895,780.174500 393.689392,777.635986 
	C394.437653,777.127319 396.127930,778.004456 397.112488,778.191162 
	C397.112488,779.681335 396.812317,781.706909 397.157745,781.823730 
	C401.909454,783.430847 404.418457,789.807373 410.793732,788.210266 
	C411.511627,788.030273 413.939392,790.608459 413.739624,791.493591 
	C412.463165,797.149414 417.265747,800.134399 420.187866,800.445801 
	C425.201202,800.980225 426.819183,802.731384 426.937042,807.169617 
	C426.940826,807.312622 427.483185,807.441345 427.776886,807.577820 
	C429.247650,806.773254 430.738892,805.957458 432.860168,804.796997 
	C433.112762,811.071228 431.495331,817.930176 439.181244,817.330750 
	C440.579865,820.178772 441.002075,822.849243 442.498657,823.766052 
	C448.330017,827.338562 448.276093,836.276611 455.867340,838.092407 
	C456.285217,838.192322 456.880493,839.362061 456.722900,839.782593 
	C454.564240,845.542480 460.456604,848.788574 460.594574,853.710327 
	C462.206757,853.710327 463.435699,853.710327 464.954620,853.710327 
	C464.954620,855.278625 465.382660,856.790710 464.881744,857.872681 
	C462.507141,863.001831 464.611633,868.031250 464.728455,873.123535 
	C464.939301,882.314026 464.835510,891.553162 464.092804,900.709534 
	C463.590332,906.904602 461.769409,912.992676 460.769409,917.976257 
	C458.586182,921.648560 456.640503,924.525940 455.108093,927.608765 
	C453.533234,930.777039 452.762390,934.392334 450.927765,937.373962 
	C447.463898,943.003540 447.464783,943.939331 453.512634,947.983032 
	C455.971100,945.358582 458.670441,942.794556 460.995667,939.927185 
	C465.541138,934.321716 470.917755,929.551880 474.576172,923.005005 
	C478.655182,915.705505 484.626434,909.475891 489.617889,902.666138 
	C491.073212,900.680603 493.156891,898.633057 490.419434,895.674500 
	C489.910858,895.124756 491.665283,891.102722 493.213531,890.300049 
	C497.078033,888.296509 499.284546,885.549866 500.367096,881.433838 
	C501.487030,877.175842 502.994659,873.019775 504.404510,868.593872 
	C503.583160,868.545654 502.530457,868.483826 500.311127,868.353516 
	C500.733765,861.796021 500.895874,855.273071 501.660217,848.821472 
	C502.175110,844.475647 500.697876,839.320068 505.658783,836.255615 
	C506.050354,836.013733 506.063293,834.570374 505.769073,833.896240 
	C502.095642,825.478943 500.198090,816.010925 491.697815,810.411255 
	C490.731689,809.774841 489.831665,807.356506 490.288391,806.557861 
	C491.647827,804.180786 491.314972,802.781738 489.143341,801.286865 
	C487.423767,800.103088 485.771790,797.223267 484.400085,797.407410 
	C478.341644,798.220947 480.722687,794.037170 480.540771,792.204895 
	C474.443970,786.532837 472.397797,776.851440 463.783630,776.102051 
	C462.945618,778.968079 462.849762,781.026001 461.785675,782.223572 
	C461.233093,782.845398 458.008240,782.378235 457.678711,781.644714 
	C456.996765,780.126770 456.674469,777.142700 457.550842,776.439331 
	C463.601318,771.583130 459.546783,769.780396 455.382294,767.558044 
	C453.820648,766.724731 452.677917,765.120789 451.298950,763.920105 
	C450.960052,763.625061 450.443939,763.533386 449.874695,763.290833 
	C449.201599,764.577148 448.572723,765.778931 447.939514,766.989075 
	C447.131653,766.741394 446.308533,766.678406 446.257263,766.445435 
	C445.204681,761.665894 435.748230,762.397522 438.719025,754.990845 
	C438.780640,754.837219 438.405212,754.370361 438.140900,754.244507 
	C434.132660,752.335571 430.108856,750.459229 425.806030,748.442749 
	C425.472748,751.283569 424.607208,751.406372 413.477264,751.047302 
	C416.621948,749.230408 418.994720,747.859436 422.392883,745.896057 
	C421.253693,745.151855 420.078979,743.759155 418.795044,743.649902 
	C411.832245,743.057373 408.959564,741.162598 407.257507,735.662720 
	C405.476868,735.031128 403.315491,734.923523 402.293976,733.789307 
	C397.594330,728.571167 392.515778,726.685791 386.163330,730.742126 
	C386.072784,730.799927 385.684570,730.391541 385.438538,730.196411 
	C387.893646,724.597595 387.642670,723.537598 382.283905,721.975830 
	C378.839844,720.972046 375.169769,720.743591 371.524017,720.155151 
	C370.215240,714.497437 368.591736,713.312805 362.345001,712.934082 
	C360.340332,712.812500 357.206696,712.575317 356.595367,711.372009 
	C353.549866,705.377197 348.799194,703.690186 343.662445,704.471741 
	C341.076599,701.629639 339.004089,699.351807 337.000732,696.997253 
	C337.069916,696.920593 337.208160,696.767273 337.208160,696.767273 
	C343.492523,699.605530 349.879639,702.238708 356.039398,705.325623 
	C368.124237,711.381958 380.230438,717.423584 392.052734,723.968201 
	C404.604767,730.916687 417.178314,737.928467 429.118103,745.853333 
	C442.126678,754.487610 454.564423,764.010681 466.985413,773.494202 
	C482.755493,785.534790 494.209778,801.186829 503.667328,818.809937 
M384.986267,1014.746338 
	C386.024323,1015.437134 387.062378,1016.127991 388.643433,1017.002686 
	C389.017487,1017.079102 389.391571,1017.155579 390.080963,1017.919434 
	C397.544281,1020.667786 404.359070,1016.647522 405.703461,1008.209106 
	C405.715057,1007.791199 405.726654,1007.373352 406.399567,1006.464233 
	C406.525299,1003.700562 407.638977,1000.442566 406.558105,998.282715 
	C404.677734,994.525452 406.622314,988.827881 401.000000,987.000000 
	C401.000000,987.000000 401.084656,986.913940 400.967255,986.267517 
	C400.256195,985.973389 399.545135,985.679199 398.023895,985.179138 
	C396.351959,985.096497 394.680023,985.013916 392.681641,984.528870 
	C392.124969,984.718384 391.568298,984.907959 390.386902,985.122314 
	C389.981201,985.214050 389.575500,985.305847 388.539276,985.226990 
	C387.965881,985.761353 387.392487,986.295654 386.242157,987.017578 
	C385.802429,987.647705 385.362701,988.277832 384.241455,989.078857 
	C381.673767,991.572021 380.185669,994.369629 381.705048,998.890198 
	C381.705994,1000.921631 381.706909,1002.953125 381.271606,1005.548889 
	C382.491882,1008.388306 383.712158,1011.227661 385.000000,1014.000000 
	C385.000000,1014.000000 384.930023,1014.065369 384.986267,1014.746338 
z"/>
<path fill="#F59556" opacity="1.000000" stroke="none" 
	d="
M209.603348,590.536987 
	C213.821106,582.862732 220.277466,577.368347 227.676727,571.464111 
	C225.479050,577.753906 223.372864,583.018372 221.799500,588.437500 
	C218.067368,601.292053 221.870529,612.652771 229.305695,623.136536 
	C240.623489,639.094971 256.098145,650.282043 272.654541,660.008667 
	C290.129730,670.275208 307.932007,679.985168 325.593750,689.934082 
	C329.327148,692.037048 333.070648,694.122070 337.008820,696.491333 
	C337.208160,696.767273 337.069916,696.920593 336.597839,696.971313 
	C333.073395,696.774353 330.020996,696.526733 327.143372,696.293274 
	C325.659637,694.977844 324.273163,693.376099 322.558136,692.295898 
	C316.742035,688.632568 310.902649,684.979736 304.846497,681.745483 
	C303.574554,681.066223 301.075592,681.719482 299.602081,682.562744 
	C295.093811,685.142883 291.140686,683.596069 289.749603,678.070862 
	C292.118317,678.651306 294.345367,679.196960 296.572388,679.742676 
	C296.784119,679.135925 296.995880,678.529236 297.207611,677.922485 
	C292.126831,675.645386 287.046021,673.368286 281.479187,670.873413 
	C281.551971,667.024719 280.533325,666.568481 271.528229,667.357910 
	C270.281891,669.139709 269.328552,670.502625 268.223969,672.081787 
	C266.533661,666.841248 265.227722,662.792358 263.652710,657.909180 
	C261.242004,657.538574 258.020691,657.043335 254.616592,656.520020 
	C254.169235,655.149963 253.694763,653.696777 253.165024,652.074402 
	C251.461075,653.164490 250.115402,654.025391 247.551788,655.665405 
	C247.548737,642.858887 236.761353,640.724304 229.529968,635.643616 
	C229.904465,633.577515 230.991241,631.500427 230.374222,630.296509 
	C227.912033,625.492188 224.927490,620.954773 222.109024,616.335205 
	C221.877136,615.955078 221.440567,615.699829 220.948761,615.248657 
	C220.219772,616.179626 219.579712,616.997070 218.959717,617.788818 
	C214.101654,614.750366 214.101654,614.750366 214.322189,609.848633 
	C215.989059,608.813721 217.343674,607.935974 219.086548,606.806702 
	C214.603287,600.921509 221.573395,593.137207 216.373611,587.360229 
	C213.841904,588.828857 211.938629,589.932922 209.926987,590.912292 
	C209.818619,590.787598 209.603348,590.536987 209.603348,590.536987 
z"/>
<path fill="#F59556" opacity="1.000000" stroke="none" 
	d="
M288.817657,721.172913 
	C282.042206,717.464539 274.949585,714.230591 268.553650,709.954529 
	C252.534821,699.244995 236.394150,688.631775 221.140274,676.891235 
	C208.937012,667.498779 199.521790,655.189575 195.326218,640.087402 
	C192.945587,631.518127 192.932632,622.386414 196.095352,613.651245 
	C196.537827,612.429138 196.686691,611.100830 196.991379,609.415344 
	C197.011292,609.009033 197.000305,609.000854 197.314331,608.856079 
	C198.778778,606.151428 199.929199,603.591492 201.079636,601.031555 
	C203.722290,597.635315 206.364914,594.239075 209.305450,590.689941 
	C209.603348,590.536987 209.818619,590.787598 209.874588,591.274475 
	C207.234436,597.242859 204.538315,602.724304 201.842194,608.205750 
	C202.459518,608.542419 203.076859,608.879089 203.694183,609.215698 
	C204.546967,608.003235 205.329193,606.732727 206.269165,605.592041 
	C207.650009,603.916443 209.082199,602.881958 209.996475,606.371643 
	C209.993240,607.493896 209.990891,608.242065 209.603073,609.003540 
	C207.140884,611.339722 205.064163,613.662537 202.739502,615.987854 
	C202.491577,615.990356 201.995728,615.999268 201.675278,616.039307 
	C200.900864,616.717102 200.446915,617.354919 199.626007,618.062744 
	C198.771423,624.029724 197.141861,630.124939 198.121536,635.767029 
	C199.075760,641.262573 198.364532,648.427856 205.612350,652.020508 
	C205.792496,650.450012 205.729889,649.428040 206.051361,648.546021 
	C206.587524,647.074829 207.302856,645.651306 208.134995,644.328674 
	C208.235687,644.168579 210.122559,644.682922 210.163528,645.011597 
	C210.353836,646.538086 210.397903,648.151428 210.099777,649.655457 
	C209.532623,652.516785 208.696472,655.324829 207.904770,658.417664 
	C210.121826,658.828735 211.850510,659.149231 213.554108,659.465088 
	C213.031601,666.282837 214.385864,667.833435 221.495544,669.052979 
	C222.384247,669.205383 223.429718,669.630493 223.997070,670.282166 
	C228.513184,675.469666 233.019058,680.671509 237.347549,686.015076 
	C240.074646,689.381714 243.294601,690.722290 247.654388,690.397278 
	C249.260742,690.277588 251.991653,691.476318 252.505936,692.757507 
	C255.361969,699.872864 259.513031,701.907959 265.480652,697.996887 
	C264.797028,703.660889 267.143158,706.811157 272.657043,707.509583 
	C273.472931,707.612976 274.130920,708.783691 274.908875,709.401550 
	C276.814636,710.914978 278.621918,713.324097 280.707916,713.606506 
	C282.536530,713.854004 284.690308,711.698914 287.557526,710.138733 
	C288.093597,713.903931 288.592255,717.406372 289.022980,720.975220 
	C288.955017,721.041626 288.817657,721.172913 288.817657,721.172913 
z"/>
<path fill="#FEFEFE" opacity="1.000000" stroke="none" 
	d="
M241.999695,623.000000 
	C238.356430,620.761169 232.169998,623.455872 230.632217,617.164551 
	C230.321396,615.892883 230.257996,614.562256 229.981033,613.280334 
	C229.709213,612.022217 229.712662,610.159729 228.905685,609.646118 
	C220.502502,604.298279 223.471298,596.051025 224.365295,589.074951 
	C224.982025,584.262756 228.691788,579.846924 231.150208,574.995605 
	C233.359283,575.932251 235.568756,576.869080 238.169556,577.971802 
	C236.197983,582.292847 239.273712,588.549500 231.776871,590.136719 
	C238.176575,595.171936 237.566833,602.024902 236.428299,607.552979 
	C235.593277,611.607422 236.927979,613.130615 238.898346,615.432434 
	C240.584106,617.401733 243.972855,618.662720 242.033539,622.655273 
	C242.000610,623.000000 242.000000,623.000000 241.999695,623.000000 
z"/>
<path fill="#FBF5EA" opacity="1.000000" stroke="none" 
	d="
M200.809845,601.019531 
	C199.929199,603.591492 198.778778,606.151428 197.318756,608.860657 
	C197.910858,606.555542 198.812576,604.101074 199.857147,601.323364 
	C200.180023,601.002441 200.360046,601.004944 200.809845,601.019531 
z"/>
<path fill="#FB9352" opacity="1.000000" stroke="none" 
	d="
M333.471436,759.535156 
	C363.124634,775.818481 392.018250,792.624023 415.971619,816.440491 
	C436.906738,837.255920 449.173737,862.038269 447.811920,892.224487 
	C447.124390,907.463806 443.234436,922.088745 437.054352,936.063660 
	C436.743073,936.767578 436.187531,937.363464 436.352844,937.121704 
	C433.629425,926.433105 431.668365,914.965149 427.754303,904.207764 
	C420.894073,885.353027 408.012360,870.495422 393.269379,857.207886 
	C366.379486,832.972595 334.753448,815.921143 303.762299,797.836731 
	C281.419464,784.799011 259.236084,771.424438 237.612442,757.238525 
	C221.029221,746.359314 206.493958,732.956116 195.478821,716.074524 
	C183.686920,698.002563 180.141434,678.465271 184.151810,657.431885 
	C184.980484,653.085754 186.192795,648.812683 187.526306,643.266479 
	C188.806564,645.393066 189.628098,646.354553 190.019104,647.467285 
	C196.578217,666.132996 209.151123,680.261719 223.880432,692.853699 
	C247.327530,712.898315 274.392700,727.205200 301.203094,741.927673 
	C311.835632,747.766296 322.499878,753.547241 333.471436,759.535156 
z"/>
<path fill="#F59556" opacity="1.000000" stroke="none" 
	d="
M320.950928,739.050903 
	C311.595337,734.228882 302.283997,729.369385 293.000732,724.456787 
	C291.599091,723.714966 290.328247,722.725891 288.907257,721.512085 
	C288.817657,721.172913 288.955017,721.041626 289.430786,721.004028 
	C291.992554,721.341370 294.213959,721.380005 296.139008,722.154846 
	C301.724731,724.403320 306.996063,727.760193 312.760132,729.146240 
	C318.514008,730.529846 320.663849,733.635681 321.111755,738.887085 
	C321.111755,738.887085 321.004364,738.996155 320.950928,739.050903 
z"/>
<path fill="#FCF7EE" opacity="1.000000" stroke="none" 
	d="
M501.950806,994.342163 
	C504.980682,986.173950 507.364502,984.502930 514.837830,984.943420 
	C520.325562,985.266907 524.217712,988.792847 525.309265,995.754822 
	C525.962036,999.918396 525.806396,1004.403381 525.016541,1008.554077 
	C523.722900,1015.351807 518.671265,1018.639465 511.905701,1018.004333 
	C506.118134,1017.461060 501.416199,1012.479736 501.222992,1006.156433 
	C501.107147,1002.364014 501.647430,998.551575 501.950806,994.342163 
z"/>
<path fill="#FBF5EA" opacity="1.000000" stroke="none" 
	d="
M526.512329,952.126953 
	C529.140198,947.893494 533.036133,950.263489 536.848633,951.197021 
	C533.643616,951.717712 530.304382,951.901978 526.512329,952.126953 
z"/>
<path fill="#FF914E" opacity="1.000000" stroke="none" 
	d="
M939.670166,1024.108398 
	C937.093262,1025.167969 934.388489,1026.839600 932.439880,1026.201660 
	C931.088989,1025.759521 930.114136,1022.133606 930.120483,1019.933411 
	C930.147644,1010.498657 930.731262,1001.065979 930.801758,991.630249 
	C930.818237,989.427795 930.129761,986.853149 928.871338,985.086853 
	C926.470703,981.717529 926.594543,979.064697 930.238403,976.044250 
	C933.379150,979.376221 936.617065,982.779480 939.794067,986.238708 
	C940.063721,986.532288 939.999634,987.204224 939.967896,987.692444 
	C939.185364,999.704834 940.689331,1011.723450 939.670166,1024.108398 
z"/>
<path fill="#FF914E" opacity="1.000000" stroke="none" 
	d="
M921.468445,976.331177 
	C922.510254,977.019226 923.943054,978.177917 923.752075,978.597778 
	C923.189392,979.834229 922.173706,981.175903 920.985107,981.744812 
	C919.100159,982.647156 916.953918,983.090637 914.870056,983.443665 
	C914.133850,983.568359 913.256348,982.858826 912.443359,982.530090 
	C912.889954,981.764587 913.150330,980.685791 913.816589,980.289856 
	C916.175171,978.888489 918.672607,977.720581 921.468445,976.331177 
z"/>
<path fill="#EBAC7F" opacity="1.000000" stroke="none" 
	d="
M217.307755,985.835205 
	C217.320389,984.390259 217.606277,982.767090 217.992447,981.168152 
	C219.760788,973.846802 221.513077,972.578369 228.964066,974.204346 
	C230.460037,974.530823 232.160049,976.485962 232.718704,978.061523 
	C234.371735,982.723145 235.500778,987.570679 236.814697,992.732300 
	C235.207947,989.312561 233.309753,985.599060 232.135880,981.669189 
	C231.241898,978.676392 230.888229,976.001160 226.819229,975.888977 
	C222.772339,975.777405 220.145920,976.764404 219.643036,981.297729 
	C219.475250,982.810486 218.327164,984.214478 217.307755,985.835205 
z"/>
<path fill="#F8D4B7" opacity="1.000000" stroke="none" 
	d="
M249.163788,984.761475 
	C249.594879,981.676697 250.325928,978.376160 251.393204,974.993652 
	C250.974197,978.122986 250.218979,981.334290 249.163788,984.761475 
z"/>
<path fill="#EBAC7F" opacity="1.000000" stroke="none" 
	d="
M237.712387,998.234436 
	C238.655899,1000.066772 239.333710,1002.150635 240.001526,1004.623901 
	C236.551483,1003.848022 236.795578,1001.246338 237.712387,998.234436 
z"/>
<path fill="#EBAC7F" opacity="1.000000" stroke="none" 
	d="
M215.347778,992.952820 
	C215.169250,991.830872 215.325714,990.645447 215.751465,989.221375 
	C215.908081,990.284973 215.795410,991.587097 215.347778,992.952820 
z"/>
<path fill="#EDB289" opacity="1.000000" stroke="none" 
	d="
M207.075638,1009.297607 
	C207.807144,1008.987793 208.557846,1009.020996 209.685944,1009.068237 
	C209.448257,1011.258972 208.507446,1011.703979 207.075638,1009.297607 
z"/>
<path fill="#F8D4B7" opacity="1.000000" stroke="none" 
	d="
M248.032288,990.762451 
	C247.814789,989.982361 247.903564,989.014404 248.254929,987.942078 
	C248.457870,988.750000 248.398209,989.662231 248.032288,990.762451 
z"/>
<path fill="#EDB289" opacity="1.000000" stroke="none" 
	d="
M199.466812,974.155945 
	C199.861206,974.618469 199.917328,975.038086 199.939667,975.776917 
	C199.273392,975.688171 198.640884,975.280273 197.895111,974.602661 
	C198.230759,974.259644 198.679642,974.186340 199.466812,974.155945 
z"/>
<path fill="#EDB289" opacity="1.000000" stroke="none" 
	d="
M200.041656,977.270325 
	C200.385757,977.755005 200.707199,978.555908 201.010910,979.702881 
	C200.668457,979.228088 200.343719,978.407349 200.041656,977.270325 
z"/>
<path fill="#EBAC7F" opacity="1.000000" stroke="none" 
	d="
M240.191498,1007.155151 
	C240.508682,1007.355164 240.770096,1007.865417 241.043137,1008.671143 
	C240.748413,1008.466248 240.442078,1007.965881 240.191498,1007.155151 
z"/>
<path fill="#EDB289" opacity="1.000000" stroke="none" 
	d="
M205.109787,1001.184326 
	C205.292816,1001.171509 205.534119,1001.396240 205.802246,1001.873413 
	C205.537430,1001.958130 205.317093,1001.723511 205.109787,1001.184326 
z"/>
<path fill="#F8D4B7" opacity="1.000000" stroke="none" 
	d="
M257.135193,1001.206543 
	C257.361755,1001.156006 257.494232,1001.350464 257.563049,1001.565247 
	C257.585358,1001.634949 257.440948,1001.757996 257.209900,1001.937134 
	C257.036743,1001.831543 257.026550,1001.645508 257.135193,1001.206543 
z"/>
<path fill="#EDB289" opacity="1.000000" stroke="none" 
	d="
M211.080811,1007.853638 
	C210.903732,1007.908020 210.915405,1007.449768 210.951080,1007.223267 
	C211.148071,1007.164917 211.323181,1007.323181 211.457474,1007.510681 
	C211.480423,1007.542847 211.328827,1007.699951 211.080811,1007.853638 
z"/>
<path fill="#EDB289" opacity="1.000000" stroke="none" 
	d="
M206.716309,1007.032104 
	C206.897919,1007.096130 206.933655,1007.283997 206.959183,1007.758789 
	C206.822784,1007.749084 206.696625,1007.452515 206.716309,1007.032104 
z"/>
<path fill="#F4995C" opacity="1.000000" stroke="none" 
	d="
M656.213135,1003.199463 
	C661.197388,998.113586 667.567871,997.412354 673.683533,996.034607 
	C676.103516,995.489380 678.697571,995.142151 680.801514,993.968262 
	C682.228882,993.171875 683.897095,990.975220 683.730164,989.617126 
	C683.521484,987.919495 681.846680,985.581238 680.269836,985.018555 
	C674.281921,982.881531 668.742554,984.682190 664.027649,988.614258 
	C661.464111,990.752197 659.461487,992.031189 657.237122,988.430542 
	C655.356873,985.387024 652.339722,982.699402 657.173035,979.318542 
	C665.294312,973.637939 674.246521,971.917175 683.765686,973.302307 
	C692.184021,974.527161 697.680115,981.636780 697.823547,990.722717 
	C697.941833,998.218140 697.899658,1005.716614 697.872986,1013.213440 
	C697.863770,1015.788696 698.286682,1017.828186 701.462708,1018.120544 
	C705.833801,1018.522888 704.995300,1022.140747 704.688416,1024.618774 
	C704.494751,1026.182129 702.734009,1028.297729 701.213257,1028.821655 
	C694.375488,1031.177368 688.949463,1028.631226 685.141052,1022.734680 
	C674.203186,1031.208618 663.604614,1032.208618 657.155029,1025.657715 
	C651.014099,1019.420227 650.533020,1010.443909 656.213135,1003.199463 
M683.456421,1013.623474 
	C683.907898,1011.190552 684.697388,1008.752075 684.657959,1006.327148 
	C684.640137,1005.231812 682.749756,1003.112915 682.089600,1003.252991 
	C677.756897,1004.171936 673.265930,1005.070801 669.385864,1007.064636 
	C667.666260,1007.948181 666.217773,1011.529846 666.537354,1013.594116 
	C666.850037,1015.614441 669.250000,1018.185913 671.293457,1018.889221 
	C676.123352,1020.551514 680.387634,1019.023804 683.456421,1013.623474 
z"/>
<path fill="#F4995C" opacity="1.000000" stroke="none" 
	d="
M615.790161,988.467896 
	C614.222046,987.011475 612.677124,985.197144 611.095886,985.164856 
	C607.537170,985.092346 606.817810,983.356750 607.094116,980.450684 
	C607.355713,977.699829 605.985229,973.843262 610.897278,973.950439 
	C614.201843,974.022461 615.050232,972.431946 615.354553,969.421814 
	C615.961670,963.416504 621.738342,959.500854 627.412598,961.466980 
	C628.679565,961.906006 629.641968,964.839172 629.761963,966.687805 
	C630.227783,973.861633 630.085083,973.722107 637.373413,973.958130 
	C640.656860,974.064514 645.545349,971.833252 645.167175,978.753113 
	C644.828552,984.949341 645.157959,984.932495 638.717773,984.980774 
	C628.335999,985.058533 629.945312,983.843140 629.816772,994.097351 
	C629.754150,999.091309 629.768127,1004.087280 629.824341,1009.081421 
	C629.914062,1017.058289 632.894165,1019.184998 640.573303,1016.829895 
	C641.337585,1016.595459 642.099609,1016.353638 642.642517,1016.183960 
	C643.078918,1016.439331 643.286316,1016.486328 643.349609,1016.608215 
	C648.369751,1026.272461 648.424194,1028.498657 636.060974,1029.816895 
	C622.713257,1031.239990 615.879883,1024.449829 615.823853,1010.895691 
	C615.793518,1003.570129 615.804504,996.244446 615.790161,988.467896 
z"/>
<path fill="#FC9251" opacity="1.000000" stroke="none" 
	d="
M624.897095,786.162231 
	C627.142822,784.182129 629.948486,782.772217 631.390564,780.497742 
	C641.763672,764.137390 650.722656,747.028015 657.436584,728.812561 
	C657.802185,727.820557 657.531433,726.593933 657.290833,725.240051 
	C657.080627,724.579834 657.136475,724.156189 657.624146,723.340393 
	C659.022461,721.340759 660.301392,719.838135 660.884277,718.101807 
	C661.828491,715.288940 662.485596,712.350586 662.966980,709.416443 
	C665.025635,696.868408 668.101685,684.356934 668.711670,671.730896 
	C669.728027,650.694214 669.657043,629.540161 664.848999,608.786804 
	C663.434021,602.679382 662.455322,596.335205 660.040344,590.622131 
	C654.251892,576.929016 647.785522,563.519958 641.488525,550.044983 
	C634.266174,534.589844 622.709412,522.194275 612.206665,509.069000 
	C611.615723,508.330475 610.131775,508.306610 609.063416,507.950134 
	C609.063416,507.950134 608.994812,507.988861 609.028259,507.601746 
	C605.364258,503.818329 601.666809,500.422028 597.969360,497.025757 
	C597.969360,497.025757 598.000000,496.998779 597.999268,496.642639 
	C597.532898,495.274902 597.329956,493.949371 596.558289,493.303711 
	C592.198059,489.655823 587.767944,486.081604 583.206116,482.691498 
	C582.016602,481.807495 580.272217,481.670197 578.782654,481.189941 
	C578.782654,481.189911 578.381409,481.121338 578.085571,480.823608 
	C577.503540,480.370422 577.217468,480.214996 576.931335,480.059570 
	C576.931335,480.059570 576.985596,480.051056 576.988403,479.695068 
	C576.000061,478.129730 575.214905,476.618652 573.975708,475.772827 
	C571.267944,473.924622 568.382812,472.313263 565.476135,470.783630 
	C553.167603,464.306274 540.936157,457.663788 528.422180,451.607239 
	C524.941223,449.922516 520.586304,450.043457 516.632324,449.336029 
	C515.963989,449.281677 515.295654,449.227325 514.294189,449.132538 
	C511.765991,444.080750 506.483124,445.942810 502.825104,444.159363 
	C500.386658,442.970490 497.431885,442.798431 495.063232,441.515686 
	C481.904907,434.389832 468.806946,427.148529 455.792206,419.763611 
	C450.556946,416.793030 445.656494,413.236176 440.458038,410.195099 
	C438.518616,409.060486 436.164032,408.635498 433.636230,407.804749 
	C432.501923,406.544403 432.001709,404.887329 430.912262,404.269714 
	C425.759583,401.348877 420.386230,398.817749 415.229919,395.902832 
	C413.875946,395.137451 413.016815,393.496765 411.181824,391.404419 
	C413.895172,391.177185 415.304565,390.957733 416.714142,390.956635 
	C432.660736,390.943909 448.661102,390.158295 464.537811,391.217651 
	C478.622498,392.157501 492.580902,395.085541 506.572906,397.277374 
	C513.213684,398.317657 519.802856,399.687531 526.854858,400.790955 
	C527.294373,400.673798 527.692505,400.710999 527.887695,400.753235 
	C530.499146,402.165924 532.800537,403.818420 535.351685,404.857910 
	C546.218628,409.285919 557.473816,412.889984 567.982544,418.030975 
	C577.947693,422.906036 587.027039,429.557281 596.735535,435.000061 
	C608.653198,441.681427 618.805603,450.629944 628.856934,459.630432 
	C636.222412,466.225861 643.158142,473.419281 649.518799,480.990265 
	C658.357361,491.510651 667.205322,502.159424 674.696167,513.636353 
	C681.771240,524.476196 687.322998,536.345459 693.109558,547.978394 
	C696.775452,555.348083 699.892639,563.021057 702.787720,570.733704 
	C704.870300,576.281921 706.317627,582.084839 707.798584,587.838440 
	C709.516541,594.513123 711.388306,601.191650 712.445251,607.984924 
	C715.662415,628.662292 718.343201,649.462158 716.841736,670.420776 
	C715.909119,683.439087 714.043335,696.434448 711.783020,709.297119 
	C710.445862,716.906372 706.934021,724.121460 705.443848,731.718506 
	C702.761841,745.391724 697.115356,757.968689 690.882080,770.087952 
	C683.537476,784.367920 674.665710,797.893250 665.982971,811.442688 
	C660.749756,819.608948 654.616272,827.198242 648.796875,835.378174 
	C644.832886,838.976135 640.947571,842.228760 637.088196,845.511902 
	C625.605835,855.280212 614.485352,865.519470 602.536560,874.678589 
	C594.213806,881.058350 585.035522,886.485291 575.737427,891.385437 
	C565.568115,896.744873 555.006165,901.491821 544.269043,905.600708 
	C535.188538,909.075806 525.626221,911.303711 516.241089,913.955994 
	C515.079834,914.284241 513.696960,913.828003 512.127808,913.714355 
	C512.424500,911.746277 512.664795,910.152405 512.823303,908.143799 
	C512.741516,907.729004 512.834045,907.356812 512.899536,907.176758 
	C514.062500,906.344482 515.815063,905.933044 516.156860,905.002991 
	C519.971313,894.625122 526.040344,884.979614 526.350220,873.276123 
	C526.121399,872.600159 526.154480,872.178101 526.548340,871.355286 
	C526.974548,869.973938 527.039856,868.993286 527.105103,868.012634 
	C527.119446,867.586914 527.133789,867.161255 527.529358,866.311584 
	C529.328003,861.165466 533.216675,859.177307 537.281006,857.331055 
	C541.905823,855.230103 546.602112,853.247009 551.064392,850.836975 
	C557.591614,847.311768 564.066101,843.651306 570.303650,839.644592 
	C573.102051,837.847046 575.272095,835.071228 577.727295,832.739319 
	C577.727295,832.739319 577.821228,832.760376 578.171265,832.822388 
	C579.684021,832.381775 581.014954,832.094849 581.981506,831.340820 
	C587.144409,827.312927 592.548401,823.509766 597.222717,818.964233 
	C601.567505,814.739258 605.100830,809.679810 608.991394,804.987732 
	C608.991394,804.987732 609.000000,805.000000 609.376343,805.006104 
	C611.218750,804.203979 613.043579,803.711304 614.083435,802.528503 
	C617.205933,798.976685 620.126587,795.231262 622.900513,791.398132 
	C623.857056,790.076355 624.150452,788.274475 624.746765,786.691956 
	C624.746765,786.691956 624.825745,786.332092 624.897095,786.162231 
z"/>
<path fill="#F5985B" opacity="1.000000" stroke="none" 
	d="
M740.766846,990.057373 
	C747.745361,973.548462 767.452393,967.331543 781.584778,976.935913 
	C783.304443,978.104614 784.690491,979.862305 785.982361,981.538757 
	C787.831970,983.938904 787.526672,986.117676 784.834900,987.833984 
	C783.438293,988.724548 782.268311,990.125244 780.769775,990.651794 
	C779.590698,991.065979 777.540344,991.026367 776.777893,990.274658 
	C771.454346,985.025757 765.591125,983.286438 760.446655,985.809998 
	C755.761841,988.108215 752.704346,994.702148 753.327393,1001.193909 
	C753.517883,1003.179443 753.680298,1005.170837 753.965210,1007.143616 
	C754.768372,1012.705078 758.050659,1016.808105 762.420959,1017.812012 
	C768.135742,1019.124817 773.980347,1017.258606 776.688599,1012.178406 
	C779.735413,1006.462830 782.233887,1010.848328 785.014160,1011.974182 
	C788.911499,1013.552368 788.603760,1016.205078 786.898621,1019.235535 
	C782.073303,1027.811401 771.252563,1031.916992 759.908936,1029.470459 
	C745.540344,1026.371460 737.028748,1013.133301 739.266174,997.273438 
	C739.590210,994.976440 740.185425,992.717651 740.766846,990.057373 
z"/>
<path fill="#F4995C" opacity="1.000000" stroke="none" 
	d="
M604.034729,1021.350952 
	C603.385681,1024.119751 603.439758,1027.487915 601.888306,1028.584351 
	C598.316040,1031.109009 591.043884,1029.524780 588.007751,1026.430420 
	C586.871643,1025.272583 585.878235,1023.974670 584.387268,1022.236267 
	C580.132874,1027.905396 574.710999,1030.350586 567.899292,1029.990967 
	C559.528442,1029.549194 553.556091,1024.983154 552.159058,1017.456360 
	C550.578308,1008.940002 553.989990,1002.204468 562.054871,999.067017 
	C565.881897,997.578186 570.088562,997.087219 574.085449,996.004639 
	C576.451355,995.363892 579.048279,994.876770 580.960693,993.508850 
	C582.219299,992.608398 583.092041,990.197449 582.931152,988.583191 
	C582.796387,987.231140 580.915222,984.989868 579.804016,984.987854 
	C575.634155,984.980347 571.332764,985.256531 567.337280,986.357666 
	C564.605530,987.110535 562.299683,989.409058 559.853882,990.984070 
	C552.035339,984.968811 552.256531,981.355652 560.732727,976.817932 
	C569.200439,972.284729 578.059509,971.564270 587.177307,974.598694 
	C593.926392,976.844910 596.743835,982.042908 596.980164,988.702698 
	C597.245483,996.178772 597.140015,1003.670105 597.093201,1011.154236 
	C597.070496,1014.784851 596.755615,1018.362549 602.063110,1018.423584 
	C602.721069,1018.431152 603.360535,1020.047607 604.034729,1021.350952 
M581.881042,1015.244263 
	C582.779785,1011.387512 583.678528,1007.530823 584.577332,1003.674072 
	C584.057800,1003.278992 583.538330,1002.883850 583.018860,1002.488770 
	C578.327698,1003.831665 573.371033,1004.640259 569.073853,1006.775757 
	C567.313232,1007.650757 566.096313,1011.458618 566.274719,1013.807434 
	C566.414429,1015.646667 568.819397,1018.328430 570.687683,1018.808777 
	C574.481567,1019.784180 578.698486,1019.889587 581.881042,1015.244263 
z"/>
<path fill="#F5985B" opacity="1.000000" stroke="none" 
	d="
M108.906334,978.100830 
	C110.118645,976.577515 111.619583,974.360596 112.520081,974.582275 
	C115.590294,975.338257 120.079124,973.237976 121.026085,979.069031 
	C122.592941,988.717590 124.886665,998.248657 126.911232,1007.821411 
	C127.102188,1008.724304 127.557098,1009.571350 129.067596,1010.639343 
	C130.296585,1007.174927 131.666550,1003.752869 132.724777,1000.237061 
	C134.831085,993.239136 137.107407,986.262939 138.636642,979.132568 
	C139.481400,975.193848 141.880844,974.286255 144.952637,974.245483 
	C148.388641,974.199951 151.502228,975.203552 152.578445,979.178284 
	C154.010635,984.467712 155.486404,989.746582 157.038712,995.001892 
	C158.521347,1000.021362 160.126038,1005.004822 162.960968,1010.209595 
	C163.648621,1008.750122 164.643066,1007.356506 164.972900,1005.820251 
	C166.782898,997.390076 168.275345,988.888611 170.243484,980.497681 
	C170.727142,978.435608 172.182587,975.830566 173.909561,975.035706 
	C175.856186,974.139709 179.081268,974.269348 180.842850,975.385254 
	C181.886719,976.046631 181.647980,979.581177 181.181366,981.641602 
	C177.991440,995.726562 174.478577,1009.738708 171.330353,1023.832520 
	C170.538055,1027.379517 169.075546,1029.093262 165.340424,1028.915649 
	C161.268875,1028.721802 156.697510,1029.937256 155.167084,1024.260864 
	C152.747421,1015.286316 150.369537,1006.299377 147.807281,997.365479 
	C147.355820,995.791260 146.089996,994.450562 145.200256,993.002014 
	C144.398972,994.398193 143.277771,995.703003 142.848587,997.205444 
	C140.203934,1006.464722 137.586639,1015.736450 135.253769,1025.077271 
	C134.558258,1027.862183 133.396240,1028.861328 130.554993,1028.953125 
	C120.592331,1029.274658 120.621704,1029.364380 118.424110,1019.758484 
	C115.278534,1006.008789 112.089966,992.268921 108.906334,978.100830 
z"/>
<path fill="#F69759" opacity="1.000000" stroke="none" 
	d="
M315.165039,1026.471436 
	C312.041260,1031.209473 307.632721,1028.475708 303.860413,1028.612061 
	C303.268219,1028.633301 301.976257,1024.908447 301.962128,1022.909058 
	C301.808105,1001.106323 302.031647,979.299438 301.698792,957.500549 
	C301.614014,951.948547 304.261383,951.710632 308.381409,951.860107 
	C312.125031,951.995911 315.770538,951.239502 315.301697,957.230957 
	C314.811707,963.493042 315.189575,969.825073 315.248840,976.125916 
	C315.257385,977.036133 315.605530,977.943176 315.901611,979.351685 
	C317.268463,978.588928 318.254242,978.126770 319.147705,977.527100 
	C333.824310,967.676147 350.003235,973.758728 353.286774,991.200562 
	C354.654419,998.465210 354.500000,1006.622742 352.663666,1013.763855 
	C348.213745,1031.068359 328.774658,1035.082642 316.532471,1023.152588 
	C316.034180,1024.242798 315.614655,1025.160645 315.165039,1026.471436 
M320.117767,987.477478 
	C313.780029,994.780762 314.356842,1003.063782 317.291229,1011.323425 
	C319.080872,1016.360962 323.708618,1018.450500 329.387054,1018.024475 
	C334.724823,1017.623962 338.147217,1014.621826 339.197754,1009.653687 
	C340.042755,1005.657471 340.097961,1001.419128 339.964752,997.305664 
	C339.607239,986.264465 330.559937,981.492432 320.117767,987.477478 
z"/>
<path fill="#EDB289" opacity="1.000000" stroke="none" 
	d="
M577.369080,832.766663 
	C575.272095,835.071228 573.102051,837.847046 570.303650,839.644592 
	C564.066101,843.651306 557.591614,847.311768 551.064392,850.836975 
	C546.602112,853.247009 541.905823,855.230103 537.281006,857.331055 
	C533.216675,859.177307 529.328003,861.165466 527.589600,865.997437 
	C526.489136,861.151062 528.335876,858.291260 533.223022,856.276611 
	C547.707336,850.305420 561.719360,843.348206 574.219482,833.700073 
	C574.948608,833.137268 576.071350,833.084290 577.369080,832.766663 
z"/>
<path fill="#EDB289" opacity="1.000000" stroke="none" 
	d="
M608.652344,805.032715 
	C605.100830,809.679810 601.567505,814.739258 597.222717,818.964233 
	C592.548401,823.509766 587.144409,827.312927 581.981506,831.340820 
	C581.014954,832.094849 579.684021,832.381775 578.149231,832.779541 
	C581.400513,829.440552 585.042908,826.227417 588.643127,822.967590 
	C595.213013,817.018860 601.757874,811.042542 608.652344,805.032715 
z"/>
<path fill="#EDB289" opacity="1.000000" stroke="none" 
	d="
M624.427673,786.800537 
	C624.150452,788.274475 623.857056,790.076355 622.900513,791.398132 
	C620.126587,795.231262 617.205933,798.976685 614.083435,802.528503 
	C613.043579,803.711304 611.218750,804.203979 609.370422,805.002136 
	C614.028259,798.964417 619.068420,792.936768 624.427673,786.800537 
z"/>
<path fill="#EDB289" opacity="1.000000" stroke="none" 
	d="
M526.823364,868.220825 
	C527.039856,868.993286 526.974548,869.973938 526.625977,871.047241 
	C526.409058,870.236267 526.475281,869.332703 526.823364,868.220825 
z"/>
<path fill="#EDB289" opacity="1.000000" stroke="none" 
	d="
M516.799194,449.650696 
	C520.586304,450.043457 524.941223,449.922516 528.422180,451.607239 
	C540.936157,457.663788 553.167603,464.306274 565.476135,470.783630 
	C568.382812,472.313263 571.267944,473.924622 573.975708,475.772827 
	C575.214905,476.618652 576.000061,478.129730 576.959106,479.706879 
	C569.511780,475.781799 562.314331,471.041901 554.631042,467.299164 
	C542.640564,461.458313 530.319458,456.296539 518.146118,450.829895 
	C517.711121,450.634521 517.357605,450.257690 516.799194,449.650696 
z"/>
<path fill="#EDB289" opacity="1.000000" stroke="none" 
	d="
M578.873657,481.516602 
	C580.272217,481.670197 582.016602,481.807495 583.206116,482.691498 
	C587.767944,486.081604 592.198059,489.655823 596.558289,493.303711 
	C597.329956,493.949371 597.532898,495.274902 597.986938,496.658264 
	C595.229980,495.050140 592.391418,493.185760 589.761780,491.062469 
	C586.081116,488.090515 582.556213,484.925629 578.873657,481.516602 
z"/>
<path fill="#EDB289" opacity="1.000000" stroke="none" 
	d="
M597.981750,497.332336 
	C601.666809,500.422028 605.364258,503.818329 609.057373,507.574554 
	C608.613708,507.914551 608.174316,507.894623 607.407288,507.841736 
	C606.988159,507.520355 606.819519,507.288025 606.377441,507.017883 
	C603.703674,504.347992 601.226135,501.772003 598.470459,499.021606 
	C598.126282,498.444458 598.060242,498.041656 597.981750,497.332336 
z"/>
<path fill="#EDB289" opacity="1.000000" stroke="none" 
	d="
M577.007690,480.310181 
	C577.217468,480.214996 577.503540,480.370422 577.894470,480.753326 
	C577.694153,480.840790 577.389099,480.700806 577.007690,480.310181 
z"/>
<path fill="#F79454" opacity="1.000000" stroke="none" 
	d="
M844.234619,1019.331299 
	C844.234558,1019.331299 844.147034,1019.682068 844.047668,1019.833252 
	C842.595215,1020.574402 841.027527,1020.899475 839.922424,1021.795410 
	C829.390991,1030.332642 818.059937,1029.510132 807.068054,1023.628967 
	C803.526978,1021.734314 800.566589,1017.193970 799.139160,1013.218201 
	C795.648010,1003.494385 796.133118,993.205200 801.704773,984.582458 
	C805.127075,979.285950 811.026184,974.856812 818.293274,975.065308 
	C823.646606,975.218994 829.535156,975.055237 834.190308,977.196167 
	C842.551331,981.041321 847.302795,988.416870 848.013000,997.888550 
	C848.273499,1001.362976 848.222168,1004.860901 848.557861,1008.674133 
	C848.776794,1009.421265 848.751099,1009.842285 848.333374,1010.660767 
	C846.705811,1013.815918 845.470215,1016.573608 844.234619,1019.331299 
M835.201782,998.046265 
	C835.118835,997.218323 835.068970,996.385437 834.947754,995.563171 
	C833.969421,988.925598 830.063110,985.163879 823.865723,984.872559 
	C816.227295,984.513672 811.879639,987.044739 810.751770,994.052673 
	C809.926880,999.177795 810.218567,1004.656799 811.023071,1009.820618 
	C811.815491,1014.907104 815.583923,1017.733215 820.889282,1018.047852 
	C827.772217,1018.455994 831.922974,1016.581665 833.730957,1011.046021 
	C834.984131,1007.209167 834.873596,1002.926941 835.201782,998.046265 
z"/>
<path fill="#EBAC7F" opacity="1.000000" stroke="none" 
	d="
M844.558105,1019.208740 
	C845.470215,1016.573608 846.705811,1013.815918 848.255859,1010.967529 
	C847.340759,1013.613281 846.111206,1016.349731 844.558105,1019.208740 
z"/>
<path fill="#FC9251" opacity="1.000000" stroke="none" 
	d="
M717.816528,1014.718689 
	C722.149963,1014.069824 725.753662,1015.590576 726.625183,1019.344604 
	C727.074463,1021.280029 724.907898,1025.267700 723.061829,1025.931152 
	C720.669189,1026.791016 716.976868,1025.993652 714.638672,1024.588013 
	C711.096008,1022.458374 712.501099,1018.301086 717.816528,1014.718689 
z"/>
<path fill="#EDB289" opacity="1.000000" stroke="none" 
	d="
M609.057312,508.291412 
	C610.131775,508.306610 611.615723,508.330475 612.206665,509.069000 
	C622.709412,522.194275 634.266174,534.589844 641.488525,550.044983 
	C647.785522,563.519958 654.251892,576.929016 660.040344,590.622131 
	C662.455322,596.335205 663.434021,602.679382 664.848999,608.786804 
	C669.657043,629.540161 669.728027,650.694214 668.711670,671.730896 
	C668.101685,684.356934 665.025635,696.868408 662.966980,709.416443 
	C662.485596,712.350586 661.828491,715.288940 660.884277,718.101807 
	C660.301392,719.838135 659.022461,721.340759 657.725586,723.035706 
	C657.941162,720.674683 658.487183,718.226257 659.328125,715.133057 
	C661.411926,705.658630 663.200928,696.828918 664.989868,687.999207 
	C665.045471,687.260437 665.101074,686.521729 665.395508,685.094421 
	C666.098877,670.346191 666.601440,656.287476 666.979492,642.225464 
	C667.026428,640.478333 666.411926,638.713379 666.103943,636.956726 
	C666.075012,635.256042 666.046143,633.555420 665.952393,631.434204 
	C663.137390,618.080322 660.912842,605.003845 657.446716,592.265320 
	C655.250244,584.193176 651.204651,576.624146 647.691345,568.643250 
	C647.063965,567.167419 646.733521,565.877808 646.205566,563.959351 
	C645.375793,562.205750 644.743591,561.081055 644.111389,559.956299 
	C643.939392,559.405090 643.767334,558.853821 643.307373,557.768921 
	C639.701172,551.800293 636.382874,546.365356 633.064575,540.930420 
	C633.064575,540.930420 633.039734,541.011169 633.019775,540.624939 
	C628.099365,533.467346 623.319458,526.603699 618.238892,519.970337 
	C616.218140,517.332031 613.531677,515.203552 611.148254,512.843018 
	C611.148254,512.843018 611.180542,512.784790 611.197021,512.753296 
	C610.492737,511.358704 609.771973,509.995697 609.057312,508.291412 
z"/>
<path fill="#FCF9F2" opacity="1.000000" stroke="none" 
	d="
M665.807861,637.198364 
	C666.411926,638.713379 667.026428,640.478333 666.979492,642.225464 
	C666.601440,656.287476 666.098877,670.346191 665.387939,684.723877 
	C658.540833,680.134338 656.798157,673.400452 660.830017,667.090088 
	C661.895630,668.328247 662.882996,669.475403 664.505981,671.361145 
	C664.505981,662.522400 664.505981,654.584473 664.505981,646.646484 
	C664.249268,646.595032 663.992554,646.543579 663.735779,646.492188 
	C663.098511,648.252930 662.461304,650.013672 661.824036,651.774475 
	C661.137756,651.714905 660.451477,651.655396 659.765198,651.595886 
	C659.765198,648.383301 659.765198,645.170654 659.765198,641.958008 
	C661.243042,642.078552 662.720947,642.199097 664.543701,642.347778 
	C664.837463,640.858398 665.174561,639.149170 665.807861,637.198364 
z"/>
<path fill="#FCF9F2" opacity="1.000000" stroke="none" 
	d="
M664.624023,688.063049 
	C663.200928,696.828918 661.411926,705.658630 659.367310,714.754333 
	C658.551453,714.736389 657.472107,714.347351 657.505371,714.183472 
	C659.147827,706.068909 660.857117,697.967285 662.665833,689.888062 
	C662.816345,689.215881 663.709900,688.710022 664.624023,688.063049 
z"/>
<path fill="#FCF9F2" opacity="1.000000" stroke="none" 
	d="
M611.136719,513.246338 
	C613.531677,515.203552 616.218140,517.332031 618.238892,519.970337 
	C623.319458,526.603699 628.099365,533.467346 633.042358,540.588135 
	C630.875549,538.857239 628.677307,536.764954 626.453735,534.700073 
	C624.404724,532.797302 622.206909,531.035522 620.322632,528.983887 
	C619.546265,528.138428 619.479370,526.670715 618.978699,525.534912 
	C618.405762,524.235168 617.823364,521.971863 617.048462,521.885254 
	C611.815125,521.300110 611.988403,517.162598 611.136719,513.246338 
z"/>
<path fill="#FCF9F2" opacity="1.000000" stroke="none" 
	d="
M633.045776,541.333008 
	C636.382874,546.365356 639.701172,551.800293 643.074951,557.589111 
	C638.351624,556.459595 633.766907,555.057068 634.833679,548.252869 
	C635.151611,546.224731 633.679138,543.915833 633.045776,541.333008 
z"/>
<path fill="#FFFFFF" opacity="1.000000" stroke="none" 
	d="
M649.622681,584.005859 
	C650.002441,584.000000 650.003479,584.496460 650.004150,584.744568 
	C650.214539,586.099915 650.424133,587.207092 650.932312,589.891296 
	C648.692444,588.505554 647.444824,587.733643 646.197205,586.961792 
	C647.212463,585.978394 648.227722,584.995056 649.622681,584.005859 
z"/>
<path fill="#FCF9F2" opacity="1.000000" stroke="none" 
	d="
M644.118042,577.272156 
	C644.749695,578.245789 645.040100,579.312866 645.330566,580.380005 
	C644.627686,580.388733 643.924866,580.397400 643.222046,580.406128 
	C643.406982,579.392639 643.591980,578.379150 644.118042,577.272156 
z"/>
<path fill="#FCF9F2" opacity="1.000000" stroke="none" 
	d="
M643.847412,560.140015 
	C644.743591,561.081055 645.375793,562.205750 646.027222,563.662231 
	C645.225403,562.770508 644.404358,561.547119 643.847412,560.140015 
z"/>
<path fill="#FF914E" opacity="1.000000" stroke="none" 
	d="
M321.506256,738.922729 
	C320.663849,733.635681 318.514008,730.529846 312.760132,729.146240 
	C306.996063,727.760193 301.724731,724.403320 296.139008,722.154846 
	C294.213959,721.380005 291.992554,721.341370 289.498718,720.937622 
	C288.592255,717.406372 288.093597,713.903931 287.557526,710.138733 
	C284.690308,711.698914 282.536530,713.854004 280.707916,713.606506 
	C278.621918,713.324097 276.814636,710.914978 274.908875,709.401550 
	C274.130920,708.783691 273.472931,707.612976 272.657043,707.509583 
	C267.143158,706.811157 264.797028,703.660889 265.480652,697.996887 
	C259.513031,701.907959 255.361969,699.872864 252.505936,692.757507 
	C251.991653,691.476318 249.260742,690.277588 247.654388,690.397278 
	C243.294601,690.722290 240.074646,689.381714 237.347549,686.015076 
	C233.019058,680.671509 228.513184,675.469666 223.997070,670.282166 
	C223.429718,669.630493 222.384247,669.205383 221.495544,669.052979 
	C214.385864,667.833435 213.031601,666.282837 213.554108,659.465088 
	C211.850510,659.149231 210.121826,658.828735 207.904770,658.417664 
	C208.696472,655.324829 209.532623,652.516785 210.099777,649.655457 
	C210.397903,648.151428 210.353836,646.538086 210.163528,645.011597 
	C210.122559,644.682922 208.235687,644.168579 208.134995,644.328674 
	C207.302856,645.651306 206.587524,647.074829 206.051361,648.546021 
	C205.729889,649.428040 205.792496,650.450012 205.612350,652.020508 
	C198.364532,648.427856 199.075760,641.262573 198.121536,635.767029 
	C197.141861,630.124939 198.771423,624.029724 199.947510,618.023804 
	C201.089188,617.276245 201.542450,616.637756 201.995728,615.999268 
	C201.995728,615.999268 202.491577,615.990356 203.155029,615.994141 
	C211.649033,617.074829 212.454147,616.160400 209.988541,608.990234 
	C209.990891,608.242065 209.993240,607.493896 210.356689,606.378662 
	C211.815170,607.343018 212.912552,608.674377 214.009933,610.005737 
	C214.101654,614.750366 214.101654,614.750366 218.959717,617.788818 
	C219.579712,616.997070 220.219772,616.179626 220.948761,615.248657 
	C221.440567,615.699829 221.877136,615.955078 222.109024,616.335205 
	C224.927490,620.954773 227.912033,625.492188 230.374222,630.296509 
	C230.991241,631.500427 229.904465,633.577515 229.529968,635.643616 
	C236.761353,640.724304 247.548737,642.858887 247.551788,655.665405 
	C250.115402,654.025391 251.461075,653.164490 253.165024,652.074402 
	C253.694763,653.696777 254.169235,655.149963 254.616592,656.520020 
	C258.020691,657.043335 261.242004,657.538574 263.652710,657.909180 
	C265.227722,662.792358 266.533661,666.841248 268.223969,672.081787 
	C269.328552,670.502625 270.281891,669.139709 271.528229,667.357910 
	C280.533325,666.568481 281.551971,667.024719 281.479187,670.873413 
	C287.046021,673.368286 292.126831,675.645386 297.207611,677.922485 
	C296.995880,678.529236 296.784119,679.135925 296.572388,679.742676 
	C294.345367,679.196960 292.118317,678.651306 289.749603,678.070862 
	C291.140686,683.596069 295.093811,685.142883 299.602081,682.562744 
	C301.075592,681.719482 303.574554,681.066223 304.846497,681.745483 
	C310.902649,684.979736 316.742035,688.632568 322.558136,692.295898 
	C324.273163,693.376099 325.659637,694.977844 327.143372,696.293274 
	C330.020996,696.526733 333.073395,696.774353 336.528687,697.047974 
	C339.004089,699.351807 341.076599,701.629639 343.662445,704.471741 
	C348.799194,703.690186 353.549866,705.377197 356.595367,711.372009 
	C357.206696,712.575317 360.340332,712.812500 362.345001,712.934082 
	C368.591736,713.312805 370.215240,714.497437 371.524017,720.155151 
	C375.169769,720.743591 378.839844,720.972046 382.283905,721.975830 
	C387.642670,723.537598 387.893646,724.597595 385.438538,730.196411 
	C385.684570,730.391541 386.072784,730.799927 386.163330,730.742126 
	C392.515778,726.685791 397.594330,728.571167 402.293976,733.789307 
	C403.315491,734.923523 405.476868,735.031128 407.257507,735.662720 
	C408.959564,741.162598 411.832245,743.057373 418.795044,743.649902 
	C420.078979,743.759155 421.253693,745.151855 422.392883,745.896057 
	C418.994720,747.859436 416.621948,749.230408 413.477264,751.047302 
	C424.607208,751.406372 425.472748,751.283569 425.806030,748.442749 
	C430.108856,750.459229 434.132660,752.335571 438.140900,754.244507 
	C438.405212,754.370361 438.780640,754.837219 438.719025,754.990845 
	C435.748230,762.397522 445.204681,761.665894 446.257263,766.445435 
	C446.308533,766.678406 447.131653,766.741394 447.939514,766.989075 
	C448.572723,765.778931 449.201599,764.577148 449.874695,763.290833 
	C450.443939,763.533386 450.960052,763.625061 451.298950,763.920105 
	C452.677917,765.120789 453.820648,766.724731 455.382294,767.558044 
	C459.546783,769.780396 463.601318,771.583130 457.550842,776.439331 
	C456.674469,777.142700 456.996765,780.126770 457.678711,781.644714 
	C458.008240,782.378235 461.233093,782.845398 461.785675,782.223572 
	C462.849762,781.026001 462.945618,778.968079 463.783630,776.102051 
	C472.397797,776.851440 474.443970,786.532837 480.540771,792.204895 
	C480.722687,794.037170 478.341644,798.220947 484.400085,797.407410 
	C485.771790,797.223267 487.423767,800.103088 489.143341,801.286865 
	C491.314972,802.781738 491.647827,804.180786 490.288391,806.557861 
	C489.831665,807.356506 490.731689,809.774841 491.697815,810.411255 
	C500.198090,816.010925 502.095642,825.478943 505.769073,833.896240 
	C506.063293,834.570374 506.050354,836.013733 505.658783,836.255615 
	C500.697876,839.320068 502.175110,844.475647 501.660217,848.821472 
	C500.895874,855.273071 500.733765,861.796021 500.311127,868.353516 
	C502.530457,868.483826 503.583160,868.545654 504.404510,868.593872 
	C502.994659,873.019775 501.487030,877.175842 500.367096,881.433838 
	C499.284546,885.549866 497.078033,888.296509 493.213531,890.300049 
	C491.665283,891.102722 489.910858,895.124756 490.419434,895.674500 
	C493.156891,898.633057 491.073212,900.680603 489.617889,902.666138 
	C484.626434,909.475891 478.655182,915.705505 474.576172,923.005005 
	C470.917755,929.551880 465.541138,934.321716 460.995667,939.927185 
	C458.670441,942.794556 455.971100,945.358582 453.512634,947.983032 
	C447.464783,943.939331 447.463898,943.003540 450.927765,937.373962 
	C452.762390,934.392334 453.533234,930.777039 455.108093,927.608765 
	C456.640503,924.525940 458.586182,921.648560 460.769409,917.976257 
	C461.769409,912.992676 463.590332,906.904602 464.092804,900.709534 
	C464.835510,891.553162 464.939301,882.314026 464.728455,873.123535 
	C464.611633,868.031250 462.507141,863.001831 464.881744,857.872681 
	C465.382660,856.790710 464.954620,855.278625 464.954620,853.710327 
	C463.435699,853.710327 462.206757,853.710327 460.594574,853.710327 
	C460.456604,848.788574 454.564240,845.542480 456.722900,839.782593 
	C456.880493,839.362061 456.285217,838.192322 455.867340,838.092407 
	C448.276093,836.276611 448.330017,827.338562 442.498657,823.766052 
	C441.002075,822.849243 440.579865,820.178772 439.181244,817.330750 
	C431.495331,817.930176 433.112762,811.071228 432.860168,804.796997 
	C430.738892,805.957458 429.247650,806.773254 427.776886,807.577820 
	C427.483185,807.441345 426.940826,807.312622 426.937042,807.169617 
	C426.819183,802.731384 425.201202,800.980225 420.187866,800.445801 
	C417.265747,800.134399 412.463165,797.149414 413.739624,791.493591 
	C413.939392,790.608459 411.511627,788.030273 410.793732,788.210266 
	C404.418457,789.807373 401.909454,783.430847 397.157745,781.823730 
	C396.812317,781.706909 397.112488,779.681335 397.112488,778.191162 
	C396.127930,778.004456 394.437653,777.127319 393.689392,777.635986 
	C389.954895,780.174500 386.687256,776.578674 386.321716,775.205017 
	C385.303436,771.379211 381.987213,772.175903 380.009491,770.598450 
	C378.450806,769.355225 376.665100,768.100769 375.729187,766.431396 
	C373.462738,762.388855 370.512604,760.217773 365.675415,760.482666 
	C364.326904,760.556580 362.923553,759.038147 361.494781,758.356140 
	C357.877930,756.629517 354.115906,755.170471 350.631012,753.214111 
	C347.120911,751.243652 343.863342,749.123047 345.696503,743.817322 
	C346.577454,741.267700 345.424927,738.481140 341.132538,739.517334 
	C341.810608,741.937439 342.420654,744.114807 343.030731,746.292236 
	C342.662048,746.588318 342.293396,746.884399 341.924744,747.180481 
	C339.167389,745.481018 336.410034,743.781494 334.140656,742.382751 
	C329.822632,741.174683 325.861694,740.066528 321.506256,738.922729 
M472.091431,792.619934 
	C469.263214,797.520203 473.501160,797.537903 476.270294,799.235474 
	C477.462067,795.171997 477.049713,792.645630 472.091431,792.619934 
M257.005859,663.455322 
	C257.363953,664.881470 257.722046,666.307556 258.396881,668.995178 
	C261.760651,664.381592 261.584961,661.427246 257.771271,657.778748 
	C257.433655,659.931030 257.219208,661.298035 257.005859,663.455322 
M324.042847,698.736938 
	C323.795410,697.590454 323.547974,696.444031 323.300537,695.297546 
	C322.615906,695.547668 321.931244,695.797791 321.246582,696.047852 
	C321.935181,697.002808 322.623749,697.957764 324.042847,698.736938 
M468.318176,916.169006 
	C469.467010,915.830750 470.615814,915.492432 471.764618,915.154114 
	C471.589233,914.565002 471.413818,913.975891 471.238403,913.386780 
	C470.207794,914.080322 469.177185,914.773865 468.318176,916.169006 
M235.474869,674.450867 
	C234.143356,674.379822 232.811844,674.308838 231.480347,674.237793 
	C231.557922,674.801147 231.635513,675.364502 231.713104,675.927795 
	C232.818054,675.586426 233.923019,675.245056 235.474869,674.450867 
M256.729675,692.903870 
	C256.231537,692.958679 255.733414,693.013489 255.235291,693.068298 
	C255.349884,693.514343 255.464462,693.960449 255.579041,694.406494 
	C256.004364,694.104614 256.429657,693.802734 256.729675,692.903870 
M462.606201,926.798889 
	C462.213470,926.794678 461.820740,926.790466 461.428040,926.786255 
	C461.426514,926.949219 461.414062,927.253906 461.424988,927.254761 
	C461.815796,927.284851 462.208801,927.286560 462.606201,926.798889 
z"/>
<path fill="#FCF9F2" opacity="1.000000" stroke="none" 
	d="
M384.923004,988.907959 
	C385.362701,988.277832 385.802429,987.647705 386.846497,986.970093 
	C388.023834,986.414246 388.596802,985.905945 389.169800,985.397644 
	C389.575500,985.305847 389.981201,985.214050 390.856750,985.320435 
	C391.887085,985.322815 392.447571,985.127014 393.008057,984.931274 
	C394.680023,985.013916 396.351959,985.096497 398.524933,985.565552 
	C399.712189,986.272644 400.398407,986.593323 401.084656,986.913940 
	C401.084656,986.913940 401.000000,987.000000 401.041595,987.308594 
	C403.142456,990.334106 402.651337,992.399780 400.004639,994.641174 
	C396.751923,997.395752 393.932800,1000.662354 390.926270,1003.707642 
	C391.252472,1004.049622 391.578674,1004.391602 391.904877,1004.733582 
	C395.956299,1001.795288 400.007721,998.856934 404.466309,995.623291 
	C404.977783,1000.180237 405.358032,1003.567871 405.738251,1006.955444 
	C405.726654,1007.373352 405.715057,1007.791199 405.323059,1008.635376 
	C401.892578,1015.516907 396.596100,1017.799194 389.765625,1017.231995 
	C389.391571,1017.155579 389.017487,1017.079102 388.318237,1016.584717 
	C386.972046,1015.466309 385.951019,1014.765869 384.929993,1014.065430 
	C384.930023,1014.065369 385.000000,1014.000000 384.970703,1013.651733 
	C383.863525,1010.530518 382.785706,1007.757507 381.707855,1004.984558 
	C381.706909,1002.953125 381.705994,1000.921631 382.037567,998.248413 
	C383.221069,994.707153 384.072052,991.807556 384.923004,988.907959 
z"/>
<path fill="#F8D4B7" opacity="1.000000" stroke="none" 
	d="
M406.068909,1006.709839 
	C405.358032,1003.567871 404.977783,1000.180237 404.466309,995.623291 
	C400.007721,998.856934 395.956299,1001.795288 391.904877,1004.733582 
	C391.578674,1004.391602 391.252472,1004.049622 390.926270,1003.707642 
	C393.932800,1000.662354 396.751923,997.395752 400.004639,994.641174 
	C402.651337,992.399780 403.142456,990.334106 401.084503,987.265930 
	C406.622314,988.827881 404.677734,994.525452 406.558105,998.282715 
	C407.638977,1000.442566 406.525299,1003.700562 406.068909,1006.709839 
z"/>
<path fill="#F8D4B7" opacity="1.000000" stroke="none" 
	d="
M389.923279,1017.575684 
	C396.596100,1017.799194 401.892578,1015.516907 405.231506,1008.936401 
	C404.359070,1016.647522 397.544281,1020.667786 389.923279,1017.575684 
z"/>
<path fill="#F8D4B7" opacity="1.000000" stroke="none" 
	d="
M381.489746,1005.266724 
	C382.785706,1007.757507 383.863525,1010.530518 384.936920,1013.685303 
	C383.712158,1011.227661 382.491882,1008.388306 381.489746,1005.266724 
z"/>
<path fill="#F8D4B7" opacity="1.000000" stroke="none" 
	d="
M384.582245,988.993408 
	C384.072052,991.807556 383.221069,994.707153 382.045227,997.813110 
	C380.185669,994.369629 381.673767,991.572021 384.582245,988.993408 
z"/>
<path fill="#F8D4B7" opacity="1.000000" stroke="none" 
	d="
M384.958130,1014.405884 
	C385.951019,1014.765869 386.972046,1015.466309 388.046753,1016.492798 
	C387.062378,1016.127991 386.024323,1015.437134 384.958130,1014.405884 
z"/>
<path fill="#F8D4B7" opacity="1.000000" stroke="none" 
	d="
M401.025940,986.590698 
	C400.398407,986.593323 399.712189,986.272644 398.930023,985.668457 
	C399.545135,985.679199 400.256195,985.973389 401.025940,986.590698 
z"/>
<path fill="#F8D4B7" opacity="1.000000" stroke="none" 
	d="
M388.854553,985.312317 
	C388.596802,985.905945 388.023834,986.414246 387.134949,986.876282 
	C387.392487,986.295654 387.965881,985.761353 388.854553,985.312317 
z"/>
<path fill="#F8D4B7" opacity="1.000000" stroke="none" 
	d="
M392.844849,984.730042 
	C392.447571,985.127014 391.887085,985.322815 391.169128,985.308044 
	C391.568298,984.907959 392.124969,984.718384 392.844849,984.730042 
z"/>
<path fill="#FD9250" opacity="1.000000" stroke="none" 
	d="
M214.322189,609.848633 
	C212.912552,608.674377 211.815170,607.343018 210.357590,606.004639 
	C209.082199,602.881958 207.650009,603.916443 206.269165,605.592041 
	C205.329193,606.732727 204.546967,608.003235 203.694183,609.215698 
	C203.076859,608.879089 202.459518,608.542419 201.842194,608.205750 
	C204.538315,602.724304 207.234436,597.242859 209.982956,591.399170 
	C211.938629,589.932922 213.841904,588.828857 216.373611,587.360229 
	C221.573395,593.137207 214.603287,600.921509 219.086548,606.806702 
	C217.343674,607.935974 215.989059,608.813721 214.322189,609.848633 
z"/>
<path fill="#FD9250" opacity="1.000000" stroke="none" 
	d="
M209.603073,609.003540 
	C212.454147,616.160400 211.649033,617.074829 203.402969,615.991577 
	C205.064163,613.662537 207.140884,611.339722 209.603073,609.003540 
z"/>
<path fill="#FD9250" opacity="1.000000" stroke="none" 
	d="
M201.675262,616.039307 
	C201.542450,616.637756 201.089188,617.276245 200.314453,617.953735 
	C200.446915,617.354919 200.900864,616.717102 201.675262,616.039307 
z"/>
<path fill="#FBF5EA" opacity="1.000000" stroke="none" 
	d="
M683.261414,1013.958618 
	C680.387634,1019.023804 676.123352,1020.551514 671.293457,1018.889221 
	C669.250000,1018.185913 666.850037,1015.614441 666.537354,1013.594116 
	C666.217773,1011.529846 667.666260,1007.948181 669.385864,1007.064636 
	C673.265930,1005.070801 677.756897,1004.171936 682.089600,1003.252991 
	C682.749756,1003.112915 684.640137,1005.231812 684.657959,1006.327148 
	C684.697388,1008.752075 683.907898,1011.190552 683.261414,1013.958618 
z"/>
<path fill="#FBF5EA" opacity="1.000000" stroke="none" 
	d="
M581.673767,1015.579590 
	C578.698486,1019.889587 574.481567,1019.784180 570.687683,1018.808777 
	C568.819397,1018.328430 566.414429,1015.646667 566.274719,1013.807434 
	C566.096313,1011.458618 567.313232,1007.650757 569.073853,1006.775757 
	C573.371033,1004.640259 578.327698,1003.831665 583.018860,1002.488770 
	C583.538330,1002.883850 584.057800,1003.278992 584.577332,1003.674072 
	C583.678528,1007.530823 582.779785,1011.387512 581.673767,1015.579590 
z"/>
<path fill="#FCF9F2" opacity="1.000000" stroke="none" 
	d="
M320.411346,987.226074 
	C330.559937,981.492432 339.607239,986.264465 339.964752,997.305664 
	C340.097961,1001.419128 340.042755,1005.657471 339.197754,1009.653687 
	C338.147217,1014.621826 334.724823,1017.623962 329.387054,1018.024475 
	C323.708618,1018.450500 319.080872,1016.360962 317.291229,1011.323425 
	C314.356842,1003.063782 313.780029,994.780762 320.411346,987.226074 
z"/>
<path fill="#FCF9F2" opacity="1.000000" stroke="none" 
	d="
M835.287109,998.445007 
	C834.873596,1002.926941 834.984131,1007.209167 833.730957,1011.046021 
	C831.922974,1016.581665 827.772217,1018.455994 820.889282,1018.047852 
	C815.583923,1017.733215 811.815491,1014.907104 811.023071,1009.820618 
	C810.218567,1004.656799 809.926880,999.177795 810.751770,994.052673 
	C811.879639,987.044739 816.227295,984.513672 823.865723,984.872559 
	C830.063110,985.163879 833.969421,988.925598 834.947754,995.563171 
	C835.068970,996.385437 835.118835,997.218323 835.287109,998.445007 
z"/>
<path fill="#F59556" opacity="1.000000" stroke="none" 
	d="
M472.418579,792.435547 
	C477.049713,792.645630 477.462067,795.171997 476.270294,799.235474 
	C473.501160,797.537903 469.263214,797.520203 472.418579,792.435547 
z"/>
<path fill="#F59556" opacity="1.000000" stroke="none" 
	d="
M257.005310,663.060181 
	C257.219208,661.298035 257.433655,659.931030 257.771271,657.778748 
	C261.584961,661.427246 261.760651,664.381592 258.396881,668.995178 
	C257.722046,666.307556 257.363953,664.881470 257.005310,663.060181 
z"/>
<path fill="#F59556" opacity="1.000000" stroke="none" 
	d="
M323.677612,698.824829 
	C322.623749,697.957764 321.935181,697.002808 321.246582,696.047852 
	C321.931244,695.797791 322.615906,695.547668 323.300537,695.297546 
	C323.547974,696.444031 323.795410,697.590454 323.677612,698.824829 
z"/>
<path fill="#F59556" opacity="1.000000" stroke="none" 
	d="
M468.232361,915.818237 
	C469.177185,914.773865 470.207794,914.080322 471.238403,913.386780 
	C471.413818,913.975891 471.589233,914.565002 471.764618,915.154114 
	C470.615814,915.492432 469.467010,915.830750 468.232361,915.818237 
z"/>
<path fill="#F59556" opacity="1.000000" stroke="none" 
	d="
M235.251419,674.677246 
	C233.923019,675.245056 232.818054,675.586426 231.713104,675.927795 
	C231.635513,675.364502 231.557922,674.801147 231.480347,674.237793 
	C232.811844,674.308838 234.143356,674.379822 235.251419,674.677246 
z"/>
<path fill="#F59556" opacity="1.000000" stroke="none" 
	d="
M256.792297,693.202393 
	C256.429657,693.802734 256.004364,694.104614 255.579041,694.406494 
	C255.464462,693.960449 255.349884,693.514343 255.235291,693.068298 
	C255.733414,693.013489 256.231537,692.958679 256.792297,693.202393 
z"/>
<path fill="#F59556" opacity="1.000000" stroke="none" 
	d="
M462.603821,927.045593 
	C462.208801,927.286560 461.815796,927.284851 461.424988,927.254761 
	C461.414062,927.253906 461.426514,926.949219 461.428040,926.786255 
	C461.820740,926.790466 462.213470,926.794678 462.603821,927.045593 
z"/>

</g>
</svg>

    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>
}
